import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import styles from './styles.module.css';

export type WithdrawalTableProps = {
  officeName: string;
  isManager: boolean;
  isCorporate: boolean;
};

const Box = styled.div`
margin 8px 0;
`;

export const WithdrawalTable = ({
  officeName,
  isManager,
  isCorporate,
}: WithdrawalTableProps): JSX.Element => {
  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell>利用終了</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell scope="row">
            事業者名「{officeName}」のマネーフォワード Pay for
            Businessの利用を終了します
            <Box>
              <Link href="/settings/office/withdraw">
                <Button
                  color="red"
                  variant="outline"
                  size="small"
                  disabled={!isManager}
                >
                  利用終了手続き
                </Button>
              </Link>
            </Box>
            {!isManager && (
              <span className={styles.red}>
                利用終了は{isCorporate ? '取引担当者' : '事業主'}のみが行えます
              </span>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
