import { yup } from '@/lib/yup';
import { CardTerminatedReasonEnum, TerminateCardInput } from '@/graphql';

export type FormValues = Pick<
  TerminateCardInput,
  'terminatedReason' | 'otherTerminatedReason'
>;

export const schema: yup.ObjectSchema<FormValues> = yup.object({
  terminatedReason: yup
    .mixed<CardTerminatedReasonEnum>()
    .oneOf(Object.values(CardTerminatedReasonEnum), '選択してください')
    .required('選択してください'),
  otherTerminatedReason: yup.string().when('terminatedReason', {
    is: CardTerminatedReasonEnum.Other,
    then: (schema) =>
      schema.max(100, '100文字以内で入力してください').required(),
    otherwise: (schema) => schema.nullable().default(null),
  }),
});
