import { Table } from '@/components/new/Table';
import { DeleteUserButton } from './Button';

export type UserTerminateTableProps = {
  officeMemberId: string;
  disabled: boolean;
  isDeleteMyself: boolean;
};

export const UserTerminateTable = ({
  officeMemberId,
  isDeleteMyself,
  disabled,
}: UserTerminateTableProps): JSX.Element => {
  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>削除</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={2}>
            <DeleteUserButton
              {...{ officeMemberId, isDeleteMyself, disabled }}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
