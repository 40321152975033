import { produce } from 'immer';
import { yup } from '@/lib/yup';
import { XardCardRequestInput } from '@/graphql';

type DepartmentName = Pick<XardCardRequestInput, 'departmentName'>;
export const departmentNameSchema: yup.ObjectSchema<DepartmentName> = yup
  .object({
    departmentName: yup.string().when('$isCorporate', {
      is: true,
      then: (schema) => schema.max(50).trim().optional(),
      otherwise: (schema) => schema.strip(),
    }),
  })
  .transform((value: DepartmentName) =>
    produce(value, (draft) => {
      if ([null, undefined, ''].includes(draft.departmentName)) {
        delete draft.departmentName;
      }
    })
  );
