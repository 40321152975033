import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { WebsiteData } from './schema';

export const WebsiteInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<WebsiteData>();

  return (
    <>
      <TextField aria-label="website" {...register('website')} />
      {errors.website && <FormError>{errors.website.message}</FormError>}
    </>
  );
};
