import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { NotificationSettingInputsData } from '../schema';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const TextFieldAmountWrapper = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: center;
`;

const AmountField = styled.div`
  display: inline-block;
  width: 124px;
`;

type Props = {
  index: number;
};

export const ThresholdInput = ({ index }: Props): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext<NotificationSettingInputsData>();

  const transformInput = (value: number) => {
    return isNaN(value) ? '' : value.toLocaleString();
  };

  const transformOutput = (value: string) => {
    const stripped = value.replace(/,/g, '');
    const output = parseInt(stripped, 10);
    return isNaN(output) ? 0 : output;
  };

  return (
    <Wrapper>
      <TextFieldAmountWrapper>
        <Controller
          control={control}
          name={`notificationSettingInputs.${index}.threshold`}
          render={({ field }) => (
            <AmountField>
              <TextField
                aria-label={`threshold-${index}`}
                {...field}
                value={transformInput(field.value ?? 0)}
                onChange={(e) =>
                  field.onChange(transformOutput(e.target.value))
                }
              />
            </AmountField>
          )}
        />
        円
      </TextFieldAmountWrapper>
      {errors.notificationSettingInputs?.[index]?.threshold && (
        <FormError>
          {errors.notificationSettingInputs?.[index]?.threshold?.message}
        </FormError>
      )}
    </Wrapper>
  );
};
