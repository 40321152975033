import applicationCompleted from '@images/applicationCompleted.svg';
import styled from 'styled-components';
import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { NewProgressBar } from '../shared/NewProgressBar';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  text-align: center;
`;

const StyledText = styled(Text)`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;

const ProgressWrapper = styled.div`
  margin: 32px 0;
`;

const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px;
  place-content: center;
`;

const LinkButton = styled.span({
  display: 'grid',
});

export const PostPayRegistrationComplete = (): JSX.Element => (
  <RegistrationLayout>
    <Head title="与信審査申込完了" />
    <ContentWrapper>
      <Image src={applicationCompleted} />
      <StyledText size="xxxxLarge">
        与信審査を受け付けました
        <br />
        審査結果はメールでご連絡します
      </StyledText>
      <Text size="middle">
        与信審査後、口座振替手続きが必要です。与信限度額が付与されるまで最短で1ヶ月程かかります。
        <br />
        <Link
          href="https://biz.moneyforward.com/support/biz-pay/guide/postpay"
          target="_blank"
        >
          詳しくはこちら
        </Link>
      </Text>
      <ProgressWrapper>
        <NewProgressBar step={3} />
      </ProgressWrapper>
      <LinkWrapper>
        <Link href="/">
          <LinkButton>
            <Button size="large">ホーム画面へ戻る</Button>
          </LinkButton>
        </Link>
      </LinkWrapper>
    </ContentWrapper>
  </RegistrationLayout>
);
