import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Loading } from '@/components/screen/loading';
import { setOauthStateCookie } from '@/lib/cookie';
import { getSignUpUrl } from '@/lib/mfid';
import { generateNonceString } from '@/lib/string';

export const Register = (): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    const randomState = generateNonceString(32);
    setOauthStateCookie(randomState);

    router.push(getSignUpUrl({ state: randomState }));
  }, []);

  return <Loading />;
};
