import logo from '@images/logo.svg';
import { useState, useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import { Head } from '@/components/head';
import { Image } from '@/components/ui/Image';
import { Footer } from './Footer';
import { LinkBlock } from './LinkBlock';

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 40px 16px;
`;

const Hero = styled.div`
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const LinkBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeading = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
  border-left: 4px solid #3b7de9;
  padding-left: 8px;
`;

const BlockWrapper = styled.div`
  margin-top: 32px;
`;

const TipsWrapper = styled.div`
  margin-top: 24px;
`;

const FooterWrapper = styled.div`
  margin-top: 40px;
`;

export const CardSupport = (): JSX.Element => {
  const [loginUrl, setLoginUrl] = useState('');

  useEffect(() => {
    if (isIOS) {
      setLoginUrl('https://apps.apple.com/jp/app/id1573194490');
    } else if (isAndroid) {
      setLoginUrl(
        'https://play.google.com/store/apps/details?id=com.moneyforward.bizpay'
      );
    } else {
      setLoginUrl('/login');
    }
  }, [isIOS, isAndroid]);

  return (
    <Wrapper>
      <Head title="ヘルプ" />
      <Hero>
        <Image src={logo} width={238} height={20} />
        <Heading>ヘルプ</Heading>
      </Hero>
      <BlockWrapper>
        <LinkBlocksWrapper>
          <LinkBlock
            label="お問い合わせはこちら"
            caption={`コンタクトセンターまでお問い合わせください\n（メーラーが起動します）`}
            href="mailto:biz-pay@moneyforward.com"
            target="_blank"
          />
          <LinkBlock
            label="管理者の方はこちら"
            caption={`残高・利用明細の確認、カード一時利用停止、\n利用限度額の設定にはログインが必要です`}
            showLockIcon
            href={loginUrl}
            target="_blank"
          />
          <LinkBlock
            label="カードを拾われた方はこちら"
            caption={`業務委託先であるインフキュリオンの\nカード拾得フォームへ移動します`}
            href="https://support2.infcurion.com/"
            target="_blank"
          />
        </LinkBlocksWrapper>
      </BlockWrapper>
      <TipsWrapper>
        <SectionHeading>こんなときは</SectionHeading>
        <LinkBlocksWrapper>
          <LinkBlock
            label="4桁の暗証番号がわからない"
            href="https://support.biz.moneyforward.com/biz-pay/faq/pin/f031.html"
            target="_blank"
          />
          <LinkBlock
            label="利用限度額を増やしたい"
            href="https://support.biz.moneyforward.com/biz-pay/faq/setting-faq/f047.html"
            target="_blank"
          />
          <LinkBlock
            label="カードを一時利用停止したい"
            href="https://support.biz.moneyforward.com/biz-pay/faq/setting-faq/f045.html"
            target="_blank"
          />
          <LinkBlock
            label="心当たりのない1円の引き落としがある"
            href="https://support.biz.moneyforward.com/biz-pay/faq/transaction/f061.html"
            target="_blank"
          />
          <LinkBlock
            label="カードの番号がわからない"
            href="https://biz.moneyforward.com/support/biz-pay/faq/transaction/f110.html"
            target="_blank"
          />
          <LinkBlock
            label="その他のよくある質問"
            href="https://support.biz.moneyforward.com/biz-pay/faq"
            target="_blank"
          />
        </LinkBlocksWrapper>
      </TipsWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Wrapper>
  );
};
