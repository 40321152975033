import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Checkbox } from '@/components/new/Checkbox';
import { Link } from '@/components/new/Link';
import { TenantTypeEnum } from '@/graphql';
import { FormData } from './types';
import { CurrentOfficeContext } from '@/contexts/CurrentOfficeProvider';

const Wrapper = styled.div`
  margin-top: 16px;
`;
const Description = styled.div``;
const DescriptionTitle = styled.div`
  font-weight: bold;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Item = styled.li`
  &::before {
    content: '・';
    display: inline;
  }
`;

const Confirmation = styled.div`
  margin-top: 16px;
`;

export const Inputs = (): JSX.Element => {
  const { register } = useFormContext<FormData>();
  const { name, tenantType } = useContext(CurrentOfficeContext);
  const isCorporate = tenantType === TenantTypeEnum.Corporate;

  return (
    <Wrapper>
      <Description>
        <DescriptionTitle>ご注意ください</DescriptionTitle>
        <List>
          <Item>
            この先の画面へ進むと、利用開始手続きが完了するまで他のユーザーによる操作ができなくなります。
          </Item>
          <Item>
            {isCorporate ? (
              <>
                申込を行う方は、{name && `${name}の`}
                取引担当者である必要があります。&ensp;
                <Link
                  href="https://support.biz.moneyforward.com/biz-pay/faq/word/f022.html"
                  target="_blank"
                >
                  取引担当者とは
                </Link>
              </>
            ) : (
              <>申込を行う方は、事業主である必要があります。</>
            )}
          </Item>
        </List>
      </Description>
      <Confirmation>
        <Checkbox
          aria-label="isAccountManager-yes"
          {...register('isAccountManager')}
        >
          確認した
        </Checkbox>
      </Confirmation>
    </Wrapper>
  );
};
