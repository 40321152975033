import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { PlusIcon } from './PlusIcon';

export const AddDestinationButton = (): JSX.Element => (
  <Link href="/settings/destinations/new">
    <Button color="blue" type="button">
      <PlusIcon />
      送付先を追加
    </Button>
  </Link>
);

type EditDestinationButtonProps = {
  id: string;
};
export const EditDestinationButton = ({
  id,
}: EditDestinationButtonProps): JSX.Element => (
  <Link href={`/settings/destinations/${id}/edit`}>
    <Button color="blue" type="button" variant="outline">
      編集
    </Button>
  </Link>
);

type DeleteDestinationButtonProps = {
  id: string;
  onDelete: (id: string) => void;
};
export const DeleteDestinationButton = ({
  id,
  onDelete,
}: DeleteDestinationButtonProps): JSX.Element => {
  const onClick = () => {
    onDelete(id);
  };

  return (
    <Button
      aria-label={`delete-destination-${id}`}
      color="red"
      type="button"
      onClick={onClick}
      variant="outline"
    >
      削除
    </Button>
  );
};
