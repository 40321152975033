import { yupResolver } from '@hookform/resolvers/yup';
import { produce } from 'immer';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { NotificationDetailFragment } from '@/graphql';
import { useUpdateNotifications } from '../../hooks/useUpdateNotifications';
import { NotificationsTable } from '../NotificationsTable';
import {
  NotificationSettingInputsData,
  notificationSettingInputsSchema,
} from '../schema';

type FormProps = {
  items: NotificationDetailFragment[];
  theadText: string;
  showSlackColumn: boolean;
};

const Box = styled.div`
  margin-top: 8px;
`;

export const Form: React.FC<FormProps> = ({
  items,
  theadText,
  showSlackColumn,
}): JSX.Element => {
  const { showSuccessNotification } = useGlobalNotification();
  const [update, { loading }] = useUpdateNotifications({
    onCompleted: (data) => {
      showSuccessNotification('通知設定を保存しました');
      // delete __typename
      const strippedNotificationSettings =
        produce(
          data.updateNotificationSettings?.notificationSettings,
          (draft) => {
            draft?.forEach((notificationSetting) => {
              delete notificationSetting.__typename;
            });
          }
        ) ?? [];
      methods.reset({
        notificationSettingInputs: strippedNotificationSettings,
      });
    },
  });
  const notificationSettingInputs = items.map(
    (item) => item.notificationSetting
  );
  const methods = useForm<NotificationSettingInputsData>({
    defaultValues: {
      notificationSettingInputs,
    },
    resolver: yupResolver(notificationSettingInputsSchema),
  });

  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit = async (data: NotificationSettingInputsData) => {
    if (loading) return;

    resetMessageAndNotification();

    await update(data.notificationSettingInputs);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <NotificationsTable {...{ items, theadText, showSlackColumn }} />
        <Box>
          <Button
            color="blue"
            variant="outline"
            disabled={!methods.formState.isDirty || loading}
          >
            変更を保存
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
