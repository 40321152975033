import hidden from '@images/icons/hidden.svg';
import visible from '@images/icons/visible.svg';
import styled from 'styled-components';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Image } from '@/components/ui/Image';
import { useToggleCardPin } from '../../hooks';

const Layout = styled.div`
  display: flex;
  align-items: center;
`;
const ImageButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export type CardPinProps = {
  cardId: string;
  disabled?: boolean;
};
export const CardPin = ({ cardId, disabled }: CardPinProps): JSX.Element => {
  const [showPin, toggleShowPin, { data, loading, errors }] =
    useToggleCardPin(cardId);

  if (loading) return <CircularProgress size="small" />;

  // todo handle error
  if (errors) console.error(errors);

  return (
    <Layout>
      {data}
      {!disabled && (
        <ImageButton onClick={toggleShowPin}>
          <Image
            {...(showPin
              ? { src: visible, alt: 'visible' }
              : { src: hidden, alt: 'hidden' })}
            width={16}
            height={14}
          />
        </ImageButton>
      )}
    </Layout>
  );
};
