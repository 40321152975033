import { yup } from '@/lib/yup';
import { Prefecture } from '@/graphql';

export type CorporateSearchData = yup.Asserts<typeof corporateSearchSchema>;

export const corporateSearchSchema = yup.object({
  searchMethod: yup.string().oneOf(['prefix', 'partial']),
  corporateName: yup.string().trim().required(),
  prefectureCode: yup
    .mixed<Prefecture>()
    .oneOf(Object.values(Prefecture))
    .required(),
  cityName: yup.string(),
});
