import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';

export const useSearchQueries = (): ParsedUrlQuery => {
  const router = useRouter();
  return getSearchQueries(router.query);
};

const getSearchQueries = (query: ParsedUrlQuery): ParsedUrlQuery => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { before, last, after, first, tenantUserUid, ...searchQueries } = query;

  return searchQueries;
};
