import styled from 'styled-components';
import { Table } from '@/components/new/Table';
import { getPrefectureLabel } from '@/lib/prefecture';
import { transformQueryResult } from '@/lib/transform';
import { Prefecture } from '@/graphql';

const ContentWrapper = styled.div`
  line-height: 20px;
`;

type TableProps = {
  postalCode: string;
  prefectureCode: Prefecture;
  city: string;
  street: string;
  buildingName?: string | null;
  corporateName?: string;
  lastName: string;
  firstName: string;
};

export const PostPayDestinationTable = ({
  postalCode,
  prefectureCode,
  city,
  street,
  buildingName,
  corporateName,
  lastName,
  firstName,
}: TableProps): JSX.Element => {
  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>
            口座振替依頼書 送付先
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <ContentWrapper>
              <span>
                {postalCode && transformQueryResult.postalCode(postalCode)}
                &nbsp;
                {prefectureCode && getPrefectureLabel(prefectureCode)}
                {city}&nbsp;{street}&nbsp;{buildingName}
              </span>
              <br />
              {corporateName && (
                <>
                  <span>{corporateName}</span>
                  <br />
                </>
              )}
              {corporateName
                ? '※ 取引担当者宛に届きます'
                : `${lastName} ${firstName} 様`}
            </ContentWrapper>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
