import {
  CardState,
  WalletCardsQueryVariables,
  CardTypeCategory,
  CardControlFragment,
  CardInfoFragment,
  CardTransactionConnection,
  CardHolderFragment,
  CardNoteFragment,
} from '@/graphql';

export const CARD_STATE_STOPPED =
  `${CardState.Frozen},${CardState.Locked},${CardState.Suspended}` as const;

export const CARD_TYPE_CATEGORY_ALL =
  `${CardTypeCategory.Physical},${CardTypeCategory.Virtual}` as const;

export type FormValues = Pick<
  WalletCardsQueryVariables,
  'keyword' | 'lastFour' | 'serialNumber'
> & { cardTypes: CardTypeCategory | typeof CARD_TYPE_CATEGORY_ALL } & {
  states: (
    | CardState.Active
    | CardState.Terminated
    | CardState.Unactivated
    | typeof CARD_STATE_STOPPED
  )[];
};

export type CardRowDetails = {
  id: string;
  cardHolders: CardHolderFragment[];
  cardControl: CardControlFragment;
  cardNote: CardNoteFragment;
  cardTransactions: Omit<CardTransactionConnection, 'pageInfo' | 'totalCount'>;
} & CardInfoFragment;
