import { useRouter } from 'next/router';
import { Head } from '@/components/head';
import { Loading } from '@/components/screen/loading';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import {
  createThreeStepperContext,
  useCreateUseThreeStepper,
  Stepper,
} from '@/components/ui/Stepper';
import { useApiError } from '@/hooks/useApiError';
import { CardState, useWalletCardQuery } from '@/graphql';
import { Layout } from './Layout';
import { ProgressBar } from './ProgressBar';
import { Step } from './Step';

export const StepperContext = createThreeStepperContext();

export const useStepper = () => useCreateUseThreeStepper(StepperContext);

export const CardTerminate = (): JSX.Element | null => {
  const router = useRouter();
  const { id } = router.query;
  const { handleQueryError } = useApiError();
  const { data, loading } = useWalletCardQuery({
    variables: {
      cardId: id as string,
    },
    onError: handleQueryError,
    skip: typeof id !== 'string',
  });

  if (loading) return <Loading />;

  // Prevent user-initiated termination procedures for cards waiting to be activated
  if (data?.currentOffice.wallet?.card?.state === CardState.Unactivated) {
    router.push('/404');
    return null;
  }

  return (
    <Stepper Context={StepperContext}>
      <Head title="カード解約手続き" />
      <Layout id={data?.currentOffice?.wallet?.card?.id}>
        <GlobalMessage />
        <ProgressBar />
        <Step />
      </Layout>
    </Stepper>
  );
};
