import { Head } from '@/components/head';
import { Loading } from '@/components/screen/loading';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { useApiError } from '@/hooks/useApiError';
import { useRegistrationCorporateApplicantQuery } from '@/graphql';
import { Form } from './Form';

export const RegistrationCorporateApplicant = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const { data, loading } = useRegistrationCorporateApplicantQuery({
    onError: handleQueryError,
  });
  if (loading) return <Loading />;

  return (
    <RegistrationLayout>
      <Head title="法人情報の入力" />
      <RegistrationTitle title="法人情報の入力" center />
      <RegistrationNote center>法人情報を入力してください。</RegistrationNote>
      <Form data={data} />
    </RegistrationLayout>
  );
};
