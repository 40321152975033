import styled, { createGlobalStyle } from 'styled-components';
import { CircularProgress } from '@/components/new/CircularProgress';
import { BaseStyle } from '@/components/ui/BaseStyle';

const GlobalStyle = createGlobalStyle`
  ${BaseStyle}
  html {
    background-color: #EFF1F4;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // Set z-index so that it doesn't touch the dropdown
  z-index: var(--z-index-page-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading = (): JSX.Element => {
  return (
    <>
      <GlobalStyle />

      <Overlay>
        <CircularProgress size="large" />
      </Overlay>
    </>
  );
};
