import { yup } from '@/lib/yup';

export type RepresentativeMiddleNameEnData = yup.Asserts<
  typeof representativeMiddleNameEnSchema
>;

export const representativeMiddleNameEnSchema = yup.object({
  representativeMiddleNameEn: yup
    .string()
    .nameEn()
    .nullable()
    .optional()
    .max(128),
});
