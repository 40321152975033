import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Loading } from '@/components/screen';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { TransferPointForm } from '@/components/ui/TransferPointForm';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import {
  useWalletPointBalanceQuery,
  useTransferPointMutation,
  WalletPointBalanceDocument,
} from '@/graphql';
import { FormData, schema } from './types';

const ButtonWrapper = styled.div({
  textAlign: 'center',
  marginTop: '24px',
  '> button': {
    width: '160px',
  },
});

export const Form = (): JSX.Element => {
  const { handleQueryError, handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const { data, loading } = useWalletPointBalanceQuery({
    onError: handleQueryError,
  });
  const router = useRouter();
  const [transferPoint, { loading: transferring }] = useTransferPointMutation({
    refetchQueries: [{ query: WalletPointBalanceDocument }],
    onCompleted: () => {
      router
        .push('/wallet/points')
        .then(() => showSuccessNotification('振替完了しました'));
    },
    onError: handleMutationError,
  });
  const pointBalance = Number(data?.currentOffice.wallet?.point?.balance);
  const methods = useForm<FormData>({
    defaultValues: { amount: 0 },
    mode: 'onChange',
    // queryがloading中は仮の閾値を渡す
    resolver: yupResolver(schema),
    context: { pointBalance },
  });

  if (loading) return <Loading />;

  if (isNaN(pointBalance)) {
    // TODO: ポイントが非数だった時のエラー
    console.error('could not get point balance');
  }

  const onSubmit = ({ amount }: FormData) => {
    if (transferring) return;

    const confirmed = confirm(
      `${amount}ポイントをウォレット残高に振り替えます。よろしいですか？`
    );
    if (!confirmed) return;
    transferPoint({ variables: { amount } });
  };

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TransferPointForm pointBalance={pointBalance} />
        <ButtonWrapper>
          <Button
            type="submit"
            size="large"
            disabled={!data || pointBalance < 100 || transferring}
          >
            振替
          </Button>
        </ButtonWrapper>
      </form>
    </FormProvider>
  );
};
