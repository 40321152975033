import { useController } from 'react-hook-form';
import { Switch } from '@/components/new/Switch';
import { yup } from '@/lib/yup';

interface RestrictForeignTransactionValue {
  restrictForeignTransaction: yup.InferType<
    typeof restrictForeignTransactionSchema
  >;
}
export const restrictForeignTransactionSchema = yup.bool().required();

export const RestrictForeignTransactionInput = (): JSX.Element => {
  const { field } = useController<RestrictForeignTransactionValue>({
    name: 'restrictForeignTransaction',
  });

  const { value, onChange, ref, ...rest } = field;

  return (
    <Switch
      ref={ref}
      aria-label="restrictForeignTransaction"
      onCheckedChange={onChange}
      checked={value}
      {...rest}
    />
  );
};
