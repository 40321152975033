import Image from 'next/image';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { TenantTypeEnum } from '@/graphql';
import cardRegistration from '@/assets/images/cardRegistration.svg';

const Heading = styled.h1`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  text-align: center;
  font-weight: 500;
`;

const Content = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;

const CardBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
`;

const Card = styled(Image)`
  display: block;
  flex-shrink: 0;
`;

const TextBox = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 8px 0;
`;

type Props = {
  tenantType?: TenantTypeEnum;
};

export const Introduction = ({ tenantType }: Props): JSX.Element => {
  return (
    <div>
      <Heading>
        マネーフォワード ビジネスカードは
        <br />
        与信限度額に加えてチャージも可能なクレジットカードです
      </Heading>
      <Content>
        <CardBox>
          <Card src={cardRegistration} alt="ビジネスカード" />
        </CardBox>
        <TextBox>
          <Text>
            口座データ連携による与信審査（※）と口座振替手続きを完了すると、与信限度額の範囲内でご利用いただけます。利用枠が足りない場合はチャージすることで上限なくお支払いが可能になります。
          </Text>
          <Text color="notes">
            ※与信審査申し込みには、無料で使える
            {tenantType === TenantTypeEnum.Corporate
              ? 'マネーフォワード クラウド会計または、会計Plusのいずれか'
              : 'マネーフォワード クラウド確定申告'}
            で銀行口座との連携をしていただく必要があります。
          </Text>
        </TextBox>
      </Content>
    </div>
  );
};
