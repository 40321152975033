import { Table } from '@/components/new/Table';
import { PointFormat } from '@/lib/i18n';
import { ScheduledPointFragment } from '@/graphql';

export const ScheduledPointsTableRow = ({
  amount,
  description,
}: ScheduledPointFragment): JSX.Element => {
  return (
    <Table.Row>
      <Table.Cell size="large">{description}</Table.Cell>
      <Table.Cell width="156px" justify="end" size="large">
        {PointFormat.jp.format(Number(amount)).replace(/-/, '- ')} P
      </Table.Cell>
    </Table.Row>
  );
};
