import { yup } from '@/lib/yup';
import { IndustryEnum } from '@/graphql';

export type IndustrySchemaData = yup.Asserts<typeof industrySchema>;

export const industrySchema = yup.object({
  industry: yup
    .mixed<IndustryEnum>()
    .oneOf(Object.values(IndustryEnum), '選択してください')
    .required('選択してください'),
  industryText: yup.string().when('industry', {
    is: IndustryEnum.IndustriesUnableToClassify,
    then: (schema) =>
      schema.max(128, '128文字以内で入力してください').required(),
    otherwise: (schema) => schema.nullable().default(null),
  }),
});
