import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useConnectSlackWorkspaceMutation } from '@/graphql';

const slackClientId = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID;
const slackClientSecret = process.env.NEXT_PUBLIC_SLACK_CLIENT_SECRET;
const slackRedirectUri =
  process.env.NEXT_PUBLIC_CLOUD_WALLET_FRONTEND_URL +
  '/settings/integrations/slack';

export type useConnectSlackWorkspaceResult = [
  {
    loading: boolean;
    errors: string | null;
  },
];

const fetchSlackOauthAccess = async (
  code: string
): Promise<{
  accessToken: string;
  slackTeamId: string;
}> => {
  const res = await fetch('https://slack.com/api/oauth.v2.access', {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: `client_id=${slackClientId}&client_secret=${slackClientSecret}&redirect_uri=${slackRedirectUri}&code=${code}`,
  });

  if (!res.ok) {
    const data: { error: string } = await res.json();
    throw new Error(data.error);
  }

  const data: {
    ok: string;
    error: string;
    access_token: string;
    team: { id: string };
  } = await res.json();

  // Slack API returns error with `ok: false`
  if (!data.ok) throw new Error(data.error);

  return { accessToken: data.access_token, slackTeamId: data.team.id };
};

export const useConnectSlackWorkspace = (
  code: string
): useConnectSlackWorkspaceResult => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);
  const handleError = (error: Error | ApolloError | unknown) => {
    let errorMessages = '';
    if (error instanceof ApolloError || error instanceof Error) {
      errorMessages = error.message;
    } else {
      errorMessages = JSON.stringify(error);
    }
    setErrors(errorMessages);
  };

  const [connectSlackWorkspace] = useConnectSlackWorkspaceMutation({
    onError: (error) => handleError(error),
  });

  const getConnectSlackWorkspace = async (code: string) => {
    setLoading(true);
    try {
      const res = await fetchSlackOauthAccess(code);

      await connectSlackWorkspace({
        variables: { ...res },
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConnectSlackWorkspace(code);
  }, [code]);

  return [{ loading, errors }];
};
