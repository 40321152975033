import { SalesAmountEnum } from '@/graphql';

export const SalesAmountSelectOptions = [
  { value: SalesAmountEnum.Lt100Thousand, label: '10万円未満' },
  {
    value: SalesAmountEnum.Gte100ThousandLt300Thousand,
    label: '10万円～30万円未満',
  },
  {
    value: SalesAmountEnum.Gte300ThousandLt1000Thousand,
    label: '30万円～100万円未満',
  },
  {
    value: SalesAmountEnum.Gte1000ThousandLt3000Thousand,
    label: '100万円～300万円未満',
  },
  {
    value: SalesAmountEnum.Gte3000ThousandLt5000Thousand,
    label: '300万円～500万円未満',
  },
  {
    value: SalesAmountEnum.Gte5000ThousandLt10000Thousand,
    label: '500万円～1000万円未満',
  },
  {
    value: SalesAmountEnum.Gte10000ThousandLt25000Thousand,
    label: '1000万円～2500万円未満',
  },
  {
    value: SalesAmountEnum.Gte25000ThousandLt50000Thousand,
    label: '2500万円～5000万円未満',
  },
  {
    value: SalesAmountEnum.Gte50000ThousandLt100000Thousand,
    label: '5000万円～1億円未満',
  },
  {
    value: SalesAmountEnum.Gte100000ThousandLt500000Thousand,
    label: '1億円～5億円未満',
  },
  {
    value: SalesAmountEnum.Gte500000ThousandLt1000000Thousand,
    label: '5億円～10億円未満',
  },
  { value: SalesAmountEnum.Gte1000000Thousand, label: '10億円以上' },
];

export const getSalesAmountDescription = (
  amount?: SalesAmountEnum | null
): string =>
  SalesAmountSelectOptions.find((option) => option.value === amount)?.label ??
  '';
