import { useApiError } from '@/hooks/useApiError';
import {
  ScheduledPointFragment,
  useWalletScheduledPointsQuery,
} from '@/graphql';
import { ScheduledPointsTableUi } from './ScheduledPointsTableUi';

export type ScheduledPointsTableRowProps = ScheduledPointFragment & {
  id: string;
};

export const ScheduledPointsTable = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const { data, loading, error } = useWalletScheduledPointsQuery({
    onError: handleQueryError,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ScheduledPointsTableUi
      loading={loading}
      error={error}
      scheduledPoints={
        data?.currentOffice?.wallet?.point?.scheduledPoints ?? []
      }
    />
  );
};
