import { yup } from '@/lib/yup';
import { PurposeEnum } from '@/graphql';

export type PurposeData = yup.Asserts<typeof purposeSchema>;

export const purposeSchema = yup.object({
  purpose: yup
    .mixed<PurposeEnum>()
    .oneOf(Object.values(PurposeEnum), '取引目的にチェックをしてください')
    .required('取引目的にチェックをしてください'),
});
