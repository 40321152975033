import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  CardState,
  RoleEnum,
  useUpdateCardControlMutation,
  WalletCardDocument,
} from '@/graphql';
import { FormData, schema } from './schema';

const Box = styled.div`
  margin-top: 8px;
`;

type FormProps = {
  cardId: string;
  defaultValues: FormData;
  cardState: CardState;
  role: RoleEnum | undefined;
};
export const Form: React.FC<FormProps> = ({
  cardId,
  defaultValues,
  cardState,
  children,
  role,
}): JSX.Element => {
  if (
    cardState === CardState.Terminated ||
    cardState === CardState.Unactivated ||
    role === RoleEnum.Employee
  )
    return <>{children}</>;

  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [updateCardControl, { loading }] = useUpdateCardControlMutation({
    refetchQueries: [{ query: WalletCardDocument, variables: { cardId } }],
    onCompleted: (data) => {
      showSuccessNotification('変更を保存しました');

      methods.reset({
        maximumLimitInMonth:
          data.updateCardControl?.cardControl.maximumLimitInMonth,
        maximumPaymentLimit:
          data.updateCardControl?.cardControl.maximumPaymentLimit,
        cardLock: data.updateCardControl?.cardControl.cardLock,
        restrictForeignTransaction:
          !data.updateCardControl?.cardControl.foreignPermission,
      });
    },
    onError: (error) => handleMutationError(error),
  });
  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit = async ({
    cardLock,
    maximumLimitInMonth,
    maximumPaymentLimit,
    restrictForeignTransaction,
  }: FormData) => {
    if (loading) return;

    resetMessageAndNotification();
    await updateCardControl({
      variables: {
        cardId,
        cardLock,
        maximumLimitInMonth,
        maximumPaymentLimit,
        foreignPermission: !restrictForeignTransaction,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        <Box>
          <Button
            variant="outline"
            size="small"
            disabled={!methods.formState.isDirty || loading}
          >
            変更を保存
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
