import styled from 'styled-components';
import { Table } from '@/components/new/Table';
import { getPrefectureLabel } from '@/lib/prefecture';
import { transformQueryResult } from '@/lib/transform';
import { CardColorEnum, CardTypeCategory } from '@/graphql';
import { FormValues } from '../../../schema';
import { ShippingMethodTable } from '../ShippingMethodTable';

const List = styled.ul`
  line-height: 1.6;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Box = styled.div`
  margin-bottom: 24px;
`;

export type TablesProps = {
  isCorporate: boolean;
  savedValues: FormValues;
};

export const Tables = ({
  isCorporate,
  savedValues,
}: TablesProps): JSX.Element => {
  const {
    cardType,
    color,
    name,
    corporateName,
    departmentName,
    tenantUsers,
    maximumLimitInMonth,
    maximumPaymentLimit,
    buildingName,
    city,
    destinationDepartmentName,
    destinationName,
    postalCode,
    prefectureCode,
    street,
    tradeName,
    pin,
    shippingMethod,
    isAutoActivated,
  } = savedValues;

  return (
    <>
      <Box>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.ColumnHeaderCell colSpan={2}>
                カード設定
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.RowHeaderCell width="176px" bold>
                形状
              </Table.RowHeaderCell>
              <Table.Cell>
                {cardType === CardTypeCategory.Virtual
                  ? 'バーチャルカード'
                  : 'リアルカード'}
              </Table.Cell>
            </Table.Row>
            {cardType === CardTypeCategory.Physical && (
              <Table.Row>
                <Table.RowHeaderCell width="176px" bold>
                  デザイン
                </Table.RowHeaderCell>
                <Table.Cell>
                  {color === CardColorEnum.Black ? 'ブラック' : 'ホワイト'}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.RowHeaderCell width="176px" bold>
                カード名義人
              </Table.RowHeaderCell>
              <Table.Cell>{name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeaderCell width="176px" bold>
                月ごとの上限金額
              </Table.RowHeaderCell>
              <Table.Cell>{maximumLimitInMonth.toLocaleString()}円</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeaderCell width="176px" bold>
                一回の上限金額
              </Table.RowHeaderCell>
              <Table.Cell>{maximumPaymentLimit.toLocaleString()}円</Table.Cell>
            </Table.Row>
            {isCorporate && (
              <Table.Row>
                <Table.RowHeaderCell width="176px" bold>
                  部署
                </Table.RowHeaderCell>
                <Table.Cell>{departmentName}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.RowHeaderCell width="176px" bold>
                カード利用者
              </Table.RowHeaderCell>
              <Table.Cell>
                <List>
                  {tenantUsers.map((tenantUser) => (
                    <li key={tenantUser.value}>{tenantUser.label}</li>
                  ))}
                </List>
              </Table.Cell>
            </Table.Row>
            {cardType === CardTypeCategory.Physical && (
              <Table.Row>
                <Table.RowHeaderCell width="176px" bold>
                  カード暗証番号（PIN）
                </Table.RowHeaderCell>
                <Table.Cell>{pin ? '••••' : '指定なし'}</Table.Cell>
              </Table.Row>
            )}
            {cardType === CardTypeCategory.Physical && (
              <Table.Row>
                <Table.RowHeaderCell width="176px" bold>
                  カードに印字する{isCorporate ? '法人名' : '屋号'}
                </Table.RowHeaderCell>
                <Table.Cell>{corporateName}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table.Root>
      </Box>

      {shippingMethod && typeof isAutoActivated === 'boolean' && (
        <Box>
          <ShippingMethodTable {...{ shippingMethod, isAutoActivated }} />
        </Box>
      )}

      {isCorporate && cardType === CardTypeCategory.Physical && (
        <Box>
          <Table.Root aria-label="cardDestination">
            <Table.Head>
              <Table.Row>
                <Table.ColumnHeaderCell colSpan={2}>
                  カード送付先
                </Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {postalCode && transformQueryResult.postalCode(postalCode)}
                  &nbsp;
                  {prefectureCode && getPrefectureLabel(prefectureCode)}
                  {city}&nbsp;{street}&nbsp;{buildingName}
                  <br />
                  {tradeName && (
                    <>
                      {tradeName}
                      <br />
                    </>
                  )}
                  {destinationDepartmentName && `${destinationDepartmentName} `}
                  {destinationName} 宛
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>
      )}
    </>
  );
};
