import styled from 'styled-components';

import { Table } from '@/components/new/Table';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { CardHolderFragment, CardState, RoleEnum } from '@/graphql';
import { CardTenantUserInput } from './Inputs';

export type TenantUserRowProps = {
  cardHolders: CardHolderFragment[];
  state: CardState;
  role: RoleEnum | undefined;
};

const List = styled.ul`
  line-height: 1.6;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const TenantUsersRow = ({
  cardHolders,
  state,
  role,
}: TenantUserRowProps): JSX.Element | null => {
  const isText =
    state === CardState.Terminated ||
    state === CardState.Unactivated ||
    role === RoleEnum.Employee;

  return (
    <Table.Row>
      <Table.RowHeaderCell>
        {isText ? (
          <>カード利用者</>
        ) : (
          <TextWithQuestionIcon
            label="カード利用者"
            content={
              <>
                カードを利用する方を設定してください
                <br />
                決済の際に本人認証が必要となる場合があります
                <br />※ クラウド経費の「利用者」とは同期されません
              </>
            }
          />
        )}
      </Table.RowHeaderCell>
      <Table.Cell width="440px" size={isText ? 'large' : 'medium'}>
        {state === CardState.Terminated ||
        state === CardState.Unactivated ||
        role === RoleEnum.Employee ? (
          cardHolders.length > 0 && (
            <List>
              {cardHolders.map(
                (cardHolder) =>
                  cardHolder.officeMember && (
                    <li key={cardHolder.id}>
                      {cardHolder.officeMember.displayName}
                    </li>
                  )
              )}
            </List>
          )
        ) : (
          <CardTenantUserInput />
        )}
      </Table.Cell>
    </Table.Row>
  );
};
