import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { CardTypeCategory } from '@/graphql';
import {
  CardTable,
  CardTypeTable,
  InitialSettingsTable,
  ShippingMethodTable,
  CardDestinationTable,
  CardDesignTable,
} from '..';
import { useStepper } from '../../../../New';
import { FormValues, schema } from '../../../schema';
import { CardOptionsTable } from '../CardOptionsTable';

const TableLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const ButtonLayout = styled.div({
  marginTop: '24px',
  textAlign: 'center',
  '> button': {
    width: '160px',
  },
});

type FormContext = {
  isCorporate: boolean;
  hasInsufficientBalance: boolean;
  isReachedMaxAmount: boolean;
  maxUserCount: number;
};

export type FormProps = {
  isLocked: boolean;
  savedValues: Partial<FormValues>;
  saveValues: (values: FormValues) => void;
} & FormContext;
export const Form = ({
  isLocked,
  savedValues,
  saveValues,
  isCorporate,
  hasInsufficientBalance,
  isReachedMaxAmount,
  maxUserCount,
}: FormProps): JSX.Element => {
  const { nextStep } = useStepper();

  const methods = useForm<FormValues, FormContext>({
    defaultValues: { ...savedValues, ...(isLocked && { cardType: undefined }) },
    context: {
      isCorporate,
      hasInsufficientBalance,
      isReachedMaxAmount,
      maxUserCount,
    },
    resolver: yupResolver(schema),
  });

  const isPhysical = methods.watch('cardType') === CardTypeCategory.Physical;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => {
          saveValues(data);
          nextStep();
        })}
      >
        <TableLayout>
          <CardTypeTable
            isLocked={isLocked}
            hasInsufficientBalance={hasInsufficientBalance}
          />
          {isPhysical && <CardDesignTable />}
          <CardTable />
          <InitialSettingsTable isCorporate={isCorporate} />
          {isPhysical && (
            <>
              <CardOptionsTable isCorporate={isCorporate} />
              <ShippingMethodTable />
              {isCorporate && <CardDestinationTable />}
            </>
          )}
        </TableLayout>
        <ButtonLayout>
          <Button size="large" disabled={isReachedMaxAmount || isLocked}>
            確認画面へ
          </Button>
        </ButtonLayout>
      </form>
    </FormProvider>
  );
};
