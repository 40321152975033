import styled from 'styled-components';
import { Link } from '@/components/new/Link';
import { Loading } from '@/components/screen/loading';
import { useApiError } from '@/hooks/useApiError';
import { PostPayRequestStatusEnum, usePostPaySettingQuery } from '@/graphql';
import { SettingPostPayTable, PostPayWidget } from '../Table';

const TailLink = styled.div`
  margin-top: 8px;
`;

const Box = styled.div`
  margin-bottom: 24px;
`;

export const SettingPostPayContent = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const { data, loading } = usePostPaySettingQuery({
    onError: handleQueryError,
  });

  if (loading) return <Loading />;

  const setting = data?.currentOffice.wallet?.postPaySetting;
  const postpayRequest = data?.currentOffice.postPayRequest;

  const isPostPayActive = !!setting;

  const status = postpayRequest?.status;
  const now = new Date();
  const applicationCreatedAt = postpayRequest?.createdAt ?? now.toISOString();
  const createdAtDate = new Date(Date.parse(applicationCreatedAt));
  // 申請後1時間経過したかどうか
  const isSpendOneHour =
    now >= new Date(createdAtDate.getTime() + 1000 * 60 * 60);
  const isShowGuide =
    status === PostPayRequestStatusEnum.Rejected && isSpendOneHour;

  return (
    <Box>
      <PostPayWidget
        setting={setting}
        postpayRequest={postpayRequest}
        isShowGuide={isShowGuide}
      />
      <SettingPostPayTable
        isShowGuide={isShowGuide}
        setting={setting}
        postpayRequest={postpayRequest}
      />
      {isPostPayActive && (
        <TailLink>
          <Link
            href="https://biz.moneyforward.com/support/biz-pay/guide/postpay/g078.html"
            target="_blank"
          >
            引落口座の変更手続き
          </Link>
        </TailLink>
      )}
    </Box>
  );
};
