export const toISOStringWithTimezone = (date: Date): string => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const min = date.getMinutes().toString().padStart(2, '0');
  const sec = date.getSeconds().toString().padStart(2, '0');
  const ms = date.getMilliseconds().toString().padStart(3, '0');
  // TODO: support other time zones
  // for now, only Asia/Tokyo time zone is supported
  return `${year}-${month}-${day}T${hour}:${min}:${sec}.${ms}+09:00`;
};

// for now, only iso-string is supported
export const getEndOfTheMonth = (date: Date): string => {
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  date.setHours(23, 59, 59, 999);

  return toISOStringWithTimezone(date);
};

export const getBeginningOfTheMonth = (date: Date): string => {
  date.setDate(1);
  date.setHours(0, 0, 0, 0);

  return toISOStringWithTimezone(date);
};
