import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useAutoKanaInput } from '@/hooks/useAutoKanaInput';
import { RepresentativeLastNameData } from './schema';

export const RepresentativeLastNameInput = ({
  updateValue,
}: {
  updateValue: (value: string) => void;
}): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RepresentativeLastNameData>();

  const { onCompositionUpdate, onCompositionEnd, onInput } = useAutoKanaInput({
    updateFormValue: updateValue,
  });

  return (
    <div>
      <TextField
        aria-label="representativeLastName"
        {...register('representativeLastName')}
        onCompositionUpdate={onCompositionUpdate}
        onCompositionEnd={onCompositionEnd}
        onInput={onInput}
        placeholder="例）山田"
      />
      {errors.representativeLastName && (
        <FormError>{errors.representativeLastName.message}</FormError>
      )}
    </div>
  );
};
