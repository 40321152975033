import { yup } from '@/lib/yup';
import { UserIndustryEnum } from '@/graphql';

export type UserIndustrySchemaData = yup.Asserts<typeof userIndustrySchema>;

export const userIndustrySchema = yup.object({
  userIndustry: yup
    .mixed<UserIndustryEnum>()
    .oneOf(Object.values(UserIndustryEnum), '選択してください')
    .required('選択してください'),
});
