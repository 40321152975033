import { yup } from '@/lib/yup';

export type CorporateNameEnData = yup.Asserts<typeof corporateNameEnSchema>;

export const corporateNameEnSchema = yup.object({
  nameEn: yup
    .string()
    .required('入力してください')
    .matches(/^[ -~]*$/, '半角英数字または記号で入力してください')
    .max(255),
});
