import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import {
  RegistrationAccountManagerInviteData,
  RegistrationAccountManagerInvite,
  registrationAccountManagerInviteSchema,
} from '@/components/ui/RegistrationAccountManager';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useInviteOfficeMemberMutation } from '@/graphql';
import { Buttons } from '../Buttons';

export const Form = (): JSX.Element => {
  const router = useRouter();
  const { showSuccessNotification } = useGlobalNotification();
  const { handleMutationError } = useApiError();
  const methods = useForm<RegistrationAccountManagerInviteData>({
    resolver: yupResolver(registrationAccountManagerInviteSchema),
  });
  const [sendInvite, { loading }] = useInviteOfficeMemberMutation({
    onCompleted: () => {
      router
        .push('/registration/corporate')
        .then(() => showSuccessNotification('招待メールを送信しました'));
    },
    onError: (error) => handleMutationError(error),
  });

  const onSubmit = ({
    tenantUser: { value: tenantUserUid },
  }: RegistrationAccountManagerInviteData) => {
    if (loading) return;

    sendInvite({
      variables: {
        tenantUserUid,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <RegistrationAlert />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RegistrationAccountManagerInvite />
        <Buttons loading={loading} />
      </form>
    </FormProvider>
  );
};
