import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { Link } from '@/components/new/Link';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { RoleEnum, useInviteOfficeMemberMutation } from '@/graphql';
import { InviteButton } from './Button';
import { UsersNewTable } from './Table';
import { schema, FormValues } from './schema';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const TableLinkLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
`;

export const Form = (): JSX.Element => {
  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const router = useRouter();
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: RoleEnum.Admin,
    },
  });
  const [inviteUser, { loading }] = useInviteOfficeMemberMutation({
    onCompleted(data) {
      router
        .push('/settings/users')
        .then(() =>
          showSuccessNotification(
            `${data.inviteOfficeMember?.officeMember.displayName}に招待メールを送信しました`
          )
        );
    },
    onError(error) {
      handleMutationError(error);
    },
  });

  // destructure role when other roles can be invited
  const onSubmit = ({ tenantUser: { value: tenantUserUid } }: FormValues) => {
    if (loading) return;

    inviteUser({ variables: { tenantUserUid } });
  };

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <TableLinkLayout>
          <UsersNewTable />
          <Link
            href="https://biz.moneyforward.com/support/biz-pay/faq/user-faq/f064.html"
            target="_blank"
          >
            各ロールの説明はこちら
          </Link>
        </TableLinkLayout>
        <InviteButton loading={loading} />
      </StyledForm>
    </FormProvider>
  );
};
