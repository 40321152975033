import { Head } from '@/components/head';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { Form } from './Form';

export const PostPayRegistrationApply = (): JSX.Element => {
  return (
    <RegistrationLayout>
      <Head title="与信審査" />
      <RegistrationTitle title="与信審査" center />
      <Form />
    </RegistrationLayout>
  );
};
