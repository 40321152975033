import { yup } from '@/lib/yup';

const createRadioSchema = () =>
  yup
    .mixed()
    .oneOf(['true'], '該当する場合ご利用いただけません')
    .required('必須項目です');

export const schema = yup.object({
  isRegisteredInJapan: createRadioSchema(),
  isNotFatca: createRadioSchema(),
  isNotPeps: createRadioSchema(),
  isNotAntisocialForce: createRadioSchema(),
  isNotRelatedToSanctionedCountries: createRadioSchema(),
});

export type FormData = yup.Asserts<typeof schema>;
