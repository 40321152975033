import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@/components/new/TextField';
import { FormError } from '../../FormError';
import { PostPayLimitData } from './schema';

const AmountField = styled.div`
  display: inline-block;
  width: 120px;
  margin-right: 8px;
`;

const transformInput = (value: number | undefined) => {
  return value?.toLocaleString();
};

const transformOutput = (value: string) => {
  const stripped = value.replace(/,/g, '');
  const output = parseInt(stripped, 10);
  return isNaN(output) ? '' : output;
};

export const PostPayLimitInput = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext<PostPayLimitData>();

  return (
    <>
      <Controller
        control={control}
        name="postPayLimit"
        render={({ field }) => (
          <AmountField>
            <TextField
              aria-label="postPayLimit"
              {...field}
              value={transformInput(field.value)}
              onChange={(e) => field.onChange(transformOutput(e.target.value))}
            />
          </AmountField>
        )}
      />
      万円
      {errors.postPayLimit && (
        <section data-testid="postPayLimit">
          <FormError>{errors.postPayLimit.message}</FormError>
        </section>
      )}
    </>
  );
};
