import { useContext } from 'react';
import { RouteHistoryContext } from '@/contexts/RouteHistoryProvider';

export const useBackHref = (): string => {
  const { routeHistory } = useContext(RouteHistoryContext);

  const lastSettingsUsersRoute = routeHistory
    .filter((route) => /^\/settings\/users(\?.+)?$/.test(route))
    .pop();

  return lastSettingsUsersRoute ?? '/settings/users';
};
