import { useController } from 'react-hook-form';
import { Switch } from '@/components/new/Switch';
import { yup } from '@/lib/yup';

interface CardLockValue {
  cardLock: yup.InferType<typeof cardLockSchema>;
}
export const cardLockSchema = yup.bool().required();

export const CardLockInput = (): JSX.Element => {
  const { field } = useController<CardLockValue>({
    name: 'cardLock',
  });

  const { value, onChange, ref, ...rest } = field;

  return (
    <Switch
      ref={ref}
      aria-label="cardLock"
      onCheckedChange={onChange}
      checked={value}
      {...rest}
    />
  );
};
