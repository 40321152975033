import { SelectProps } from '@moneyforward/cloud-react-ui';
import { Control, useController } from 'react-hook-form';
import { SelectBox } from '@/components/new/SelectBox';
import { RoleEnum } from '@/graphql';
import { FormValues, ROLE_ALL } from '../types';

const options: SelectProps['options'] = [
  {
    label: 'すべて',
    value: ROLE_ALL,
  },
  {
    label: '管理者',
    value: RoleEnum.Admin,
  },
  {
    label: '一般',
    value: RoleEnum.Employee,
  },
];

export type RoleSelectProps = {
  control: Control<FormValues>;
};
export const RoleSelect = ({ control }: RoleSelectProps): JSX.Element => {
  const { field } = useController({
    name: 'role',
    control,
  });

  return (
    <SelectBox
      {...field}
      aria-label="role"
      options={options}
      onValueChange={(value) => field.onChange(value)}
      value={options.find((element) => element.value === field.value)}
      selectedTestId="selected-role"
      ariaControlsId="role"
    />
  );
};
