import styled from 'styled-components';
import { FlashMessage } from '@/components/new/FlashMessage';
import { Link } from '@/components/new/Link';

const LinkText = styled.span`
  color: #ec4949;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: #ec4949;
    text-decoration: underline;
  }
`;
const Box = styled.div`
  margin-bottom: 18px;
`;

export const LimitErrorMessage = () => {
  return (
    <Box>
      <FlashMessage color="error">
        カードの発行数が上限に達したため、カードを発行することができません。
        <br />
        上限数枚を引き上げたい場合は、お手数ですが
        <Link href="/cards/raise-limit">
          <LinkText>上限枚数引き上げ申請</LinkText>
        </Link>
        からご連絡ください。
      </FlashMessage>
    </Box>
  );
};
