import { useRouter } from 'next/router';
import { isString } from '@/lib/query';
import { RoleEnum } from '@/graphql';

export const useDefaultOpen = (): boolean => {
  const router = useRouter();

  const hasDisplayName = isString(router.query.displayName);
  const hasRole =
    router.query.role === RoleEnum.Admin ||
    router.query.role === RoleEnum.Employee;

  return hasDisplayName || hasRole;
};
