import { useRouter } from 'next/router';
import { isDate, isString } from '@/lib/query';
import { DepositWithdrawal } from '@/graphql';

export const useDefaultOpen = (): boolean => {
  const router = useRouter();

  const hasDepositWithdrawalType =
    router.query.depositWithdrawalType === DepositWithdrawal.Both ||
    router.query.depositWithdrawalType === DepositWithdrawal.Deposit ||
    router.query.depositWithdrawalType === DepositWithdrawal.Withdrawal;
  const hasKeyword = isString(router.query.keyword);
  const hasTransactionTimeGte = isDate(router.query.transactionTimeGte);
  const hasTransactionTimeLte = isDate(router.query.transactionTimeLte);

  return (
    hasDepositWithdrawalType ||
    hasKeyword ||
    hasTransactionTimeGte ||
    hasTransactionTimeLte
  );
};
