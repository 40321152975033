import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { FlashMessage } from '@/components/new/FlashMessage';
import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px;
  place-content: center;
  margin-top: 40px;
`;

const LinkButton = styled.div({
  display: 'grid',
});

export const Step3 = (): JSX.Element => {
  return (
    <>
      <FlashMessage>
        <MessageWrapper>
          <Text color="inherit">カードの解約が完了しました。</Text>
          <Text color="inherit">
            ※ 解約済みのリアルカードは、ハサミ等で裁断し、破棄してください。
          </Text>
        </MessageWrapper>
      </FlashMessage>

      <ButtonWrapper>
        <Link href="/cards">
          <LinkButton>
            <Button variant="outline" size="large">
              カード一覧へ
            </Button>
          </LinkButton>
        </Link>
      </ButtonWrapper>
    </>
  );
};
