import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@/lib/yup';
import { useStepper } from '../../Terminate';
import { FormValues, schema } from '../schema';
import { Application } from './Application';
import { Buttons } from './Buttons';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type Step1Props = {
  savedValues: FormValues | null;
  saveValues: (values: FormValues) => void;
};

export const Step1 = ({ savedValues, saveValues }: Step1Props): JSX.Element => {
  const { nextStep } = useStepper();
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...savedValues,
    },
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      saveValues(data);
      nextStep();
    },
    [saveValues, nextStep]
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <Application />
        <Buttons />
      </StyledForm>
    </FormProvider>
  );
};
