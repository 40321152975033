import {
  addressSchema,
  industrySchema,
  purposeSchema,
  corporateNumberSchema,
  corporateNameSchema,
  corporateNameKanaSchema,
  phoneNumberSchema,
  websiteSchema,
  representativeLastNameSchema,
  representativeFirstNameSchema,
  representativeLastNameKanaSchema,
  representativeFirstNameKanaSchema,
  birthDateSchema,
  isListedSchema,
  representativeLastNameEnSchema,
  representativeFirstNameEnSchema,
  representativeMiddleNameEnSchema,
  corporateNameEnSchema,
  establishmentDateSchema,
} from '@/components/ui/inputs';
import { assetsAmountSchema } from '@/components/ui/inputs/AssetsAmount';
import { representativeAddressSchema } from '@/components/ui/inputs/RepresentativeAddress';
import { representativeNationalityIdSchema } from '@/components/ui/inputs/RepresentativeNationality/schema';
import { salesAmountSchema } from '@/components/ui/inputs/SalesAmount';
import { usagePurposesSchema } from '@/components/ui/inputs/UsagePurpose';
import { yup } from '@/lib/yup';

export type FormData = yup.Asserts<typeof schema>;

export const schema = yup
  .object({})
  .concat(corporateNumberSchema)
  .concat(corporateNameSchema)
  .concat(corporateNameKanaSchema)
  .concat(corporateNameEnSchema)
  .concat(addressSchema)
  .concat(establishmentDateSchema)
  .concat(phoneNumberSchema)
  .concat(websiteSchema)
  .concat(representativeLastNameSchema)
  .concat(representativeFirstNameSchema)
  .concat(representativeLastNameKanaSchema)
  .concat(representativeFirstNameKanaSchema)
  .concat(representativeLastNameEnSchema)
  .concat(representativeFirstNameEnSchema)
  .concat(representativeMiddleNameEnSchema)
  .concat(birthDateSchema)
  .concat(representativeAddressSchema)
  .concat(representativeNationalityIdSchema)
  .concat(salesAmountSchema)
  .concat(assetsAmountSchema)
  .concat(industrySchema)
  .concat(purposeSchema)
  .concat(usagePurposesSchema)
  .concat(isListedSchema)
  .concat(
    // UIには表示しない部分
    yup.object({
      itemId: yup.string().nullable(),
      purposeItemId: yup.string().nullable(),
    })
  );
