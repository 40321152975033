import slackIcon from '@images/slackIcon.svg';
import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useDisconnectSlackWorkspaceMutation } from '@/graphql';

const TableWrapper = styled.div`
  max-width: 800px;
`;

const Heading = styled.h4`
  font-size: 14px;
  margin: 0;
`;

const GuideLinkWrapper = styled.span`
  margin-left: 8px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextBox = styled.div`
  margin-top: 8px;
`;

const ButtonBox = styled.div`
  margin-top: 16px;
`;

export type ExternalTableProps = {
  slackOauthUrl: string;
  officeSlackLinked: boolean;
};

export const ExternalTable = ({
  slackOauthUrl,
  officeSlackLinked,
}: ExternalTableProps): JSX.Element => {
  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const [slackLinked, setSlackLinked] = useState(officeSlackLinked);

  const [disconnectSlackWorkspace, { loading }] =
    useDisconnectSlackWorkspaceMutation({
      onError: handleMutationError,
      onCompleted: () => {
        showSuccessNotification('Slack連携が解除されました');
        setSlackLinked(false);
      },
    });

  const onDisconnectButtonClick = async () => {
    await disconnectSlackWorkspace();
  };

  return (
    <TableWrapper>
      <Table.Root>
        <Table.Head>
          <Table.Row>
            <Table.ColumnHeaderCell colSpan={2}>
              外部連携
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={230}>
              <ImageWrapper>
                <Image src={slackIcon} alt="" width={120} height={100} />
              </ImageWrapper>
            </Table.Cell>
            <Table.Cell>
              <Heading>Slack通知</Heading>
              <TextBox>
                <Text>
                  連携を行うと、決済に関する通知を
                  <br />
                  リアルタイムに受け取ることができます
                  <GuideLinkWrapper>
                    <Link
                      href="https://biz.moneyforward.com/support/biz-pay/guide/setting-guide/g095.html"
                      target="_blank"
                    >
                      連携ガイド
                    </Link>
                  </GuideLinkWrapper>
                </Text>
              </TextBox>

              <ButtonBox>
                {loading && <CircularProgress size="medium" />}
                {!loading && slackLinked ? (
                  <ButtonWrapper>
                    <Button type="button" disabled>
                      連携済み
                    </Button>
                    <Button onClick={onDisconnectButtonClick} variant="ghost">
                      連携解除
                    </Button>
                  </ButtonWrapper>
                ) : (
                  <Link href={slackOauthUrl}>
                    <Button type="button" variant="outline">
                      Slackと連携する
                    </Button>
                  </Link>
                )}
              </ButtonBox>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </TableWrapper>
  );
};
