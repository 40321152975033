import { useRouter } from 'next/router';
import { isString } from '@/lib/query';
import { CardTypeCategory } from '@/graphql';
import { stripInvalidStates } from './helpers';

export const useDefaultOpen = (): boolean => {
  const router = useRouter();

  const hasKeyword = isString(router.query.keyword);
  const hasLastFour = isString(router.query.lastFour);
  const hasCardTypes =
    router.query.cardTypes === CardTypeCategory.Physical ||
    router.query.cardTypes === CardTypeCategory.Virtual;
  const hasStates = !!stripInvalidStates(router.query.states);
  const hasSerialNumber = isString(router.query.serialNumber);

  return (
    hasKeyword || hasLastFour || hasCardTypes || hasStates || hasSerialNumber
  );
};
