import { CardColorEnum } from '@/graphql';
import { FirstPhysicalCard } from './FirstPhysicalCard';
import { SecondBlackPhysicalCard } from './SecondBlackPhysicalCard';
import { SecondWhitePhysicalCard } from './SecondWhitePhysicalCard';

type Props = {
  pan: string;
  name: string;
  expiration: string;
  securityCode: string;
  isCreditCard: boolean;
  color: CardColorEnum;
};
export const PhysicalCard = ({
  pan,
  name,
  isCreditCard,
  color,
  expiration,
  securityCode,
}: Props): JSX.Element | null => {
  const commonProps = { pan, name };

  if (!isCreditCard) {
    return (
      <FirstPhysicalCard
        {...commonProps}
        expiration={expiration}
        securityCode={securityCode}
      />
    );
  } else if (color === CardColorEnum.Black) {
    return <SecondBlackPhysicalCard {...commonProps} />;
  } else if (color === CardColorEnum.White) {
    return <SecondWhitePhysicalCard {...commonProps} />;
  }

  return null;
};
