import styled from 'styled-components';
import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Pagination } from '@/components/new/Pagination';
import { PageLayout } from '@/components/screen';
import {
  paginationLayouts,
  PaginationLayoutType,
} from '@/components/screen/layouts/PaginationAndExportLayout';
import { useApiError } from '@/hooks/useApiError';
import { usePaginationVariables } from '@/hooks/usePaginationVariables';
import { useTenantType } from '@/hooks/useTenantType';
import { useCurrentOfficeMembersQuery } from '@/graphql';
import { PlusIcon } from '../../destinations/Index/PlusIcon';
import { UsersSearchPanel } from './components/SearchPanel';
import { UsersTable } from './components/Table';
import { useSearchVariables } from './hooks/useSearchVariables';
import { Users } from './types';

const PaginationWrapper = styled.div`
  margin-top: 8px;
`;

export const SettingsUsersIndex = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const paginationVariables = usePaginationVariables();
  const searchVariables = useSearchVariables();
  const {
    data: officeMembersData,
    loading: officeMembersLoading,
    error: officeMembersError,
  } = useCurrentOfficeMembersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...paginationVariables,
      ...searchVariables,
    },
    onError: handleQueryError,
  });
  const {
    isCorporate,
    loading: tenantTypeLoading,
    error: tenantTypeError,
  } = useTenantType();

  const hasNextPage =
    !!officeMembersData?.currentOffice.officeMembers.pageInfo.hasNextPage;
  const hasPreviousPage =
    !!officeMembersData?.currentOffice.officeMembers.pageInfo.hasPreviousPage;
  const startCursor =
    officeMembersData?.currentOffice.officeMembers.pageInfo.startCursor;
  const endCursor =
    officeMembersData?.currentOffice.officeMembers.pageInfo.endCursor;
  const users = (
    officeMembersData?.currentOffice.officeMembers.edges ?? []
  ).reduce<Users>(
    (results, edge) => (edge?.node ? [...results, edge.node] : results),
    []
  );
  const displayPagination = hasPreviousPage || hasNextPage;

  const paginationStatus: PaginationLayoutType = 'onlyPage';
  const PaginationLayout = paginationLayouts[paginationStatus];

  return (
    <PageLayout
      title="ユーザー"
      buttons={
        <Link href="/settings/users/new">
          <Button type="button" size="small" color="blue" variant="outline">
            <PlusIcon color="#3b7de9" />
            招待
          </Button>
        </Link>
      }
    >
      <Head title="ユーザー" />

      <UsersSearchPanel />

      {users.length > 0 && (
        <PaginationLayout>
          {displayPagination && (
            <Pagination
              endCursor={endCursor}
              startCursor={startCursor}
              hasPreviousPage={hasPreviousPage}
              hasNextPage={hasNextPage}
            />
          )}
        </PaginationLayout>
      )}

      <UsersTable
        loading={officeMembersLoading || tenantTypeLoading}
        error={officeMembersError || tenantTypeError}
        users={users}
        isCorporate={isCorporate}
      />
      {displayPagination && (
        <PaginationWrapper>
          <Pagination
            endCursor={endCursor}
            startCursor={startCursor}
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
          />
        </PaginationWrapper>
      )}
    </PageLayout>
  );
};
