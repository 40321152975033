import {
  useToggleCardSensitiveInformation,
  ToggleCardSensitiveInformationResult,
} from './sensitiveInformation';

const fetchCardPin = async (
  cardToken: string,
  codeVerifier: string
): Promise<string> => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_XARD_API_URL}/api/v1/pin`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        card_token: cardToken,
        code_verifier: codeVerifier,
      }),
    }
  );

  if (!res.ok) {
    const data: { error: string } = await res.json();
    return Promise.reject(new Error(data.error));
  }

  const data: { pin: string } = await res.json();
  return data.pin;
};

export const useToggleCardPin = (
  cardId: string
): ToggleCardSensitiveInformationResult =>
  useToggleCardSensitiveInformation(cardId, '••••', fetchCardPin);
