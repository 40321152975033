import { phoneNumberSchema } from '@/components/ui/inputs';
import { postPayAccountSchema } from '@/components/ui/inputs/PostPayAccount';
import { postPayLimitSchema } from '@/components/ui/inputs/PostPayLimit';
import { yup } from '@/lib/yup';

export type FormData = yup.Asserts<typeof schema>;

export const schema = yup
  .object({})
  .concat(postPayLimitSchema)
  .concat(postPayAccountSchema)
  .concat(phoneNumberSchema);
