import { yup } from '@/lib/yup';
import {
  CardColorEnum,
  CardTypeCategory,
  XardCardRequestInput,
} from '@/graphql';

export const cardDesignSchema: yup.ObjectSchema<
  Pick<XardCardRequestInput, 'color'>
> = yup.object({
  color: yup
    .mixed<CardColorEnum>()
    .oneOf(Object.values(CardColorEnum))
    .when('cardType', {
      is: CardTypeCategory.Physical,
      then: (schema) => schema.required(),
      // NOTE: Transform BLACK to set as defaultValue and omit before mutation
      otherwise: (schema) => schema.transform(() => CardColorEnum.Black),
    }),
});
