import { yup } from '@/lib/yup';

export type IsListedData = yup.Asserts<typeof isListedSchema>;

export const isListedSchema = yup.object({
  isListed: yup
    .mixed()
    .oneOf(['true', 'false'], '選択してください')
    .required('選択してください'),
});
