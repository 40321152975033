import { useRouter } from 'next/router';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { useApiError } from '@/hooks/useApiError';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { CardTerminatedReasonEnum, useTerminateCardMutation } from '@/graphql';
import { useStepper } from '../../Terminate';
import { useCard } from '../../hooks';
import { FormValues } from '../schema';

const Title = styled(Text)`
  font-weight: bold;
`;

const TextLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MessageWrapper = styled.div`
  color: #ec4949;
  margin-top: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div({
  marginTop: '16px',
  textAlign: 'center',
  '> button': {
    width: '160px',
  },
});

const StyledBox = styled.div({
  '> button': {
    width: '160px',
  },
  display: 'inline-block',
  marginRight: '16px',
});

type Step2Props = {
  savedValues: FormValues;
};

const renderTerminatedReason = (values: FormValues) => {
  switch (values.terminatedReason) {
    case CardTerminatedReasonEnum.CardHolderRetirement:
      return '持ち主の退職';
    case CardTerminatedReasonEnum.LossOrTheft:
      return '紛失・盗難';
    case CardTerminatedReasonEnum.Other:
      return values.otherTerminatedReason;
  }
};

export const Step2 = ({ savedValues }: Step2Props): JSX.Element => {
  const { id } = useRouter().query;
  const { previousStep, nextStep } = useStepper();
  const { data } = useCard();
  const { handleMutationError } = useApiError();

  const [terminateCard, { loading: terminateCardLoading }] =
    useTerminateCardMutation({
      onCompleted: () => {
        nextStep();
      },
      onError: (error) => handleMutationError(error),
    });

  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit = useCallback(() => {
    if (terminateCardLoading) return;

    resetMessageAndNotification();

    terminateCard({
      variables: {
        cardId: id as string,
        terminatedReason: savedValues.terminatedReason,
        // 「その他」を選択されている場合のみotherTerminatedReasonをセットする
        otherTerminatedReason:
          savedValues.terminatedReason === CardTerminatedReasonEnum.Other
            ? savedValues.otherTerminatedReason
            : null,
      },
    });
  }, [terminateCardLoading]);

  const card = data?.currentOffice?.wallet?.card;
  if (!card) return <></>;

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.Row>
            <Table.ColumnHeaderCell colSpan={2}>
              解約申込
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.RowHeaderCell width="168px" bold>
              <Title>カード情報</Title>
            </Table.RowHeaderCell>
            <Table.Cell>
              <TextLayout>
                <Text>{card.name}</Text>
                <Text>**** **** **** {card.lastFour}</Text>
                <Text>
                  有効期限 : {card.expirationMonth.toString().padStart(2, '0')}/
                  {card.expirationYear}
                </Text>
              </TextLayout>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeaderCell width="168px" bold>
              <Title>解約理由</Title>
            </Table.RowHeaderCell>
            <Table.Cell>
              <Text>{renderTerminatedReason(savedValues)}</Text>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Root>

      <MessageWrapper>
        <Text color="inherit">
          この操作は取り消せません。解約してよろしいですか？
        </Text>
        <Text color="inherit">
          リアルカードを解約する場合は、ハサミ等で裁断し、破棄してください。
        </Text>
      </MessageWrapper>

      <ButtonWrapper>
        <StyledBox>
          <Button variant="outline" size="large" onClick={previousStep}>
            戻る
          </Button>
        </StyledBox>
        <Button color="red" variant="outline" size="large" onClick={onSubmit}>
          解約
        </Button>
      </ButtonWrapper>
    </>
  );
};
