import { Table } from '@/components/new/Table';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { CardNoteFragment, CardState, RoleEnum } from '@/graphql';
import { CardMemoInput } from './Inputs';

export type MemoRowProps = Pick<CardNoteFragment, 'memo'> & {
  state: CardState;
  role: RoleEnum | undefined;
};

export const MemoRow = ({
  memo,
  state,
  role,
}: MemoRowProps): JSX.Element | null => {
  const isText =
    state === CardState.Terminated ||
    state === CardState.Unactivated ||
    role === RoleEnum.Employee;

  return (
    <Table.Row>
      <Table.RowHeaderCell>
        {isText ? (
          <>メモ</>
        ) : (
          <TextWithQuestionIcon
            label="メモ"
            content={
              <>
                利用用途、管理番号等から
                <br />
                キーワード検索が可能です
              </>
            }
          />
        )}
      </Table.RowHeaderCell>
      <Table.Cell width="440px" size={isText ? 'large' : 'medium'}>
        {isText ? memo : <CardMemoInput />}
      </Table.Cell>
    </Table.Row>
  );
};
