import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { PageLayout } from '@/components/screen/layouts/PageLayout';
import { Loading } from '@/components/screen/loading';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { useApiError } from '@/hooks/useApiError';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useRole } from '@/hooks/useRole';
import {
  CardState,
  RoleEnum,
  useHaltCardMutation,
  useWalletCardQuery,
} from '@/graphql';
import { useBackHref } from '../shared/hooks/useBackHref';
import { CardDoesNotExist } from './components/CardDoesNotExist';
import { CardStateMessage } from './components/CardStateMessage';
import { HaltButton } from './components/HaltButton';
import { cardStateMessage } from './messages';
import {
  CardAdministrationTable,
  CardControlTable,
  CardInfoTable,
} from './tables';

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Box = styled.div`
  max-width: 800px;
`;

export const CardsIdIndex = (): JSX.Element => {
  const { id } = useRouter().query;
  const router = useRouter();
  const { role, loading: roleLoading } = useRole();
  const { handleQueryError, handleMutationError } = useApiError();
  const { data, loading } = useWalletCardQuery({
    variables: {
      cardId: id as string,
    },
    skip: typeof id !== 'string',
    onError: handleQueryError,
  });
  const backHref = useBackHref();

  const [halt, { loading: halting }] = useHaltCardMutation({
    variables: { cardId: id as string },
    onError: handleMutationError,
  });
  const resetMessageAndNotification = useResetMessageAndNotification();

  if (loading) return <Loading />;

  const card = data?.currentOffice.wallet?.card;
  if (!card) return <CardDoesNotExist />;

  const {
    lastFour,
    serialNumber,
    name,
    expirationMonth,
    expirationYear,
    cardType,
    id: cardId,
    state,
    isCreditCard,
    color,
  } = card;
  const cardControl = { ...card.cardControl, cardId, state };

  const cardAdministration = {
    cardId,
    cardHolders: card.cardHolders,
    departmentName: card.cardNote.departmentName,
    memo: card.cardNote.memo,
    state,
  };

  const jumpToTerminate = () => {
    router.push(`/cards/${cardId}/terminate`);
  };

  return (
    <PageLayout
      title="カード詳細"
      backLinkText="カード"
      backHref={backHref}
      buttons={
        <>
          {!roleLoading &&
            role === RoleEnum.Admin &&
            state !== CardState.Terminated &&
            state !== CardState.Unactivated && (
              <Button
                type="button"
                size="small"
                color="red"
                variant="outline"
                onClick={jumpToTerminate}
              >
                解約手続き
              </Button>
            )}

          {role === RoleEnum.Employee &&
            state === CardState.Active &&
            !cardControl.cardLock && (
              <HaltButton
                loading={halting}
                halt={async () => {
                  resetMessageAndNotification();
                  await halt();
                }}
              />
            )}
        </>
      }
    >
      <Head title="カード詳細" />
      <Box>
        {cardStateMessage[card.state] && (
          <CardStateMessage>{cardStateMessage[card.state]}</CardStateMessage>
        )}
        {/* validation用 */}
        <GlobalMessage />
        <TablesWrapper>
          <CardInfoTable
            {...{
              lastFour,
              serialNumber,
              name,
              expirationMonth,
              expirationYear,
              cardType,
              cardId,
              state,
              isCreditCard,
              color,
              cardLock: cardControl.cardLock,
            }}
          />
          {role && (
            <>
              <CardControlTable {...cardControl} role={role} />
              <CardAdministrationTable {...cardAdministration} role={role} />
            </>
          )}
        </TablesWrapper>
      </Box>
    </PageLayout>
  );
};
