import { ApolloError } from '@apollo/client';
import { ErrorCode, useApiError } from '@/hooks/useApiError';
import { useLogger } from '@/hooks/useLogger';

export type UseHandleCardsErrorReturn = (error: ApolloError) => void;
export const useHandleCardsError = (): UseHandleCardsErrorReturn => {
  const { handleQueryError } = useApiError();
  const { logError } = useLogger();

  const handleCardsError = (error: ApolloError): void => {
    if (isInvalidLastFour(error) || isInvalidSerialCardNumber(error)) {
      logError(
        error.graphQLErrors[0]?.message,
        error.graphQLErrors[0]?.extensions?.code
      );
      return;
    }
    handleQueryError(error);
  };

  return handleCardsError;
};

export const isInvalidLastFour = (error: ApolloError): boolean =>
  error.graphQLErrors[0]?.message === 'Last fourは4文字で入力してください' &&
  error.graphQLErrors[0]?.extensions?.code === ErrorCode.RecordInvalid;

export const isInvalidSerialCardNumber = (error: ApolloError): boolean =>
  error.graphQLErrors[0]?.message ===
    'Serial numberは9文字以内で入力してください' &&
  error.graphQLErrors[0]?.extensions?.code === ErrorCode.RecordInvalid;
