import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Feedback } from '@/components/screen/verification/Feedback';
import { useApiError } from '@/hooks/useApiError';
import { useVerifyAccountManagerMutation } from '@/graphql';

export const Verification = (): JSX.Element => {
  const router = useRouter();
  const { token } = router.query;
  const { handleMutationError } = useApiError();

  const [verifyAccountManager, { loading, data, called }] =
    useVerifyAccountManagerMutation({
      onError: handleMutationError,
    });

  useEffect(() => {
    if (!router.isReady || loading) return;

    const verify = async () => {
      await verifyAccountManager({
        variables: { token: `${token}` },
      });
    };

    verify();
  }, []);

  return <Feedback res={data} loading={!called || loading} />;
};
