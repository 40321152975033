import { Table } from '@/components/new/Table';
import { CardControl, CardState, RoleEnum } from '@/graphql';
import { Form } from './Form';
import { CardLockRow } from './components/CardLockRow';
import { MaximumLimitInMonthRow } from './components/MaximumLimitInMonthRow';
import { MaximumPaymentLimitRow } from './components/MaximumPaymentLimitRow';
import { RestrictForeignTransactionRow } from './components/RestrictForeignTransactionRow';
import { FormData } from './schema';

export type CardControlTableProps = Pick<
  CardControl,
  | 'cardId'
  | 'cardLock'
  | 'maximumPaymentLimit'
  | 'maximumLimitInMonth'
  | 'foreignPermission'
> & {
  state: CardState;
  role: RoleEnum;
};

export const CardControlTable = ({
  cardId,
  maximumLimitInMonth,
  maximumPaymentLimit,
  cardLock,
  foreignPermission,
  state,
  role,
}: CardControlTableProps): JSX.Element => {
  const defaultValues: Omit<FormData, 'foreignPermission'> = {
    maximumLimitInMonth,
    maximumPaymentLimit,
    cardLock,
    restrictForeignTransaction: !foreignPermission,
  };

  return (
    <Form
      defaultValues={defaultValues}
      cardId={cardId}
      cardState={state}
      role={role}
    >
      <Table.Root>
        <Table.Head>
          <Table.Row>
            <Table.ColumnHeaderCell colSpan={2}>
              カードコントロール
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <MaximumLimitInMonthRow {...{ state, role, maximumLimitInMonth }} />
          <MaximumPaymentLimitRow {...{ state, role, maximumPaymentLimit }} />
          <CardLockRow {...{ state, role, cardLock }} />
          <RestrictForeignTransactionRow
            {...{ state, role, foreignPermission }}
          />
        </Table.Body>
      </Table.Root>
    </Form>
  );
};
