import registrationApplied from '@images/registrationApplied.svg';
import { Head } from '@/components/head';
import { ActivateTitle } from '@/components/ui/ActivateTitle';
import { Image } from '@/components/ui/Image';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationProgressBar } from '@/components/ui/RegistrationProgressBar';

export const RegistrationApplied = (): JSX.Element => {
  return (
    <RegistrationLayout>
      <Head title="申請完了" />
      <ActivateTitle
        image={<Image src={registrationApplied} width={360} height={160} />}
      >
        申請を受け付けました
        <br />
        審査結果は近日中にメールでご連絡いたします
      </ActivateTitle>
      <RegistrationNote>
        この度はマネーフォワード Pay for
        Businessにお申し込みいただきありがとうございます。
        審査が完了したら、カードをご登録いただいた住所宛（法人のお客様は法人宛）に郵送します。
      </RegistrationNote>
      <RegistrationProgressBar completedStepCount={0} />
    </RegistrationLayout>
  );
};
