import { Controller, useFormContext } from 'react-hook-form';
import { SelectBox } from '@/components/new/SelectBox';
import { PrefectureSelectOptions } from '@/lib/prefecture';

export const PrefectureInput = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="prefectureCode"
      render={({ field }) => (
        <SelectBox
          {...field}
          aria-label="prefectureCode"
          placeholder="都道府県"
          options={PrefectureSelectOptions}
          value={PrefectureSelectOptions.find(
            (option) => option.value === field.value
          )}
          onValueChange={(value) => field.onChange(value)}
          selectedTestId="selected-prefectureCode"
          ariaControlsId="prefectureCode"
        />
      )}
    />
  );
};
