import styled from 'styled-components';
import { Steps, Step, StepProps, StepTie } from '@/components/new/Steps';
import { useStepper } from './New';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const STEPS = ['カード情報入力', '入力内容確認', 'カード発行完了'];

export const ProgressBar = (): JSX.Element => {
  const { statuses } = useStepper();

  const renderStep = (step: number) => (
    <Step
      step={step + 1}
      stepStatus={getStepStatus(statuses, step)}
      title={STEPS[step]}
    />
  );
  const renderStepTie = (step: number) => (
    <StepTie completed={statuses[step] === 'completed'} />
  );

  return (
    <Wrapper>
      <Steps mode="number">
        {renderStep(0)}
        {renderStepTie(0)}
        {renderStep(1)}
        {renderStepTie(1)}
        {renderStep(2)}
      </Steps>
    </Wrapper>
  );
};

const getStepStatus = (
  statuses: StepProps['stepStatus'][],
  step: number
): StepProps['stepStatus'] => statuses[step] ?? 'waiting';
