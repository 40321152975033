import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { SelectBox } from '@/components/new/SelectBox';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { IndustrySelectOptions } from '@/lib/industry';
import { IndustryEnum } from '@/graphql';
import { IndustrySchemaData } from './schema';

const Box = styled.div`
  margin-top: 6px;
`;

export const IndustryInput = (): JSX.Element => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<IndustrySchemaData>();

  // REVIEW: Remove this filter after API migration is done
  const AvailableIndustrySelectOptions = IndustrySelectOptions.filter(
    ({ value }) =>
      ![
        IndustryEnum.AgricultureAndForestry,
        IndustryEnum.FinanceAndInsurance,
        IndustryEnum.Fisheries,
        IndustryEnum.GovernmentExceptElsewhereClassified,
        IndustryEnum.TransportAndPostalActivities,
        IndustryEnum.WholesaleAndRetailTrade,
      ].includes(value)
  );

  return (
    <>
      <Controller
        control={control}
        name="industry"
        render={({ field }) => (
          <SelectBox
            {...field}
            aria-label="industry"
            placeholder="選択してください"
            options={AvailableIndustrySelectOptions}
            value={IndustrySelectOptions.find(
              (option) => option.value === field.value
            )}
            onValueChange={(value) => field.onChange(value)}
            selectedTestId="selected-industry"
            ariaControlsId="industry"
          />
        )}
      />
      {errors.industry && <FormError>{errors.industry.message}</FormError>}

      {watch('industry') === IndustryEnum.IndustriesUnableToClassify && (
        <Box>
          <TextField
            {...register('industryText')}
            aria-label="industryText"
            placeholder="その他を選択した場合はこちらに事業内容を入力してください"
          />
          {errors.industryText && (
            <FormError>{errors.industryText.message}</FormError>
          )}
        </Box>
      )}
    </>
  );
};
