import { ApolloError } from '@apollo/client';
import cardIllustration from '@images/cardIllustration.svg';
import noSearchResults from '@images/noSearchResults.svg';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { DataTable } from '@/components/new/DataTable';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { formatSerialNumber } from '@/lib/card';
import { CurrencyFormat } from '@/lib/i18n';
import { CardState, CardTypeCategory, RoleEnum } from '@/graphql';
import { CardRowDetails } from '../types';
import { CardStatusText } from './StatusText';

const AmountWrapper = styled.div`
  text-align: right;
`;

const ButtonsWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinkButton = styled.span({
  display: 'grid',
  width: '72px',
});

// TODO: It would be better to replace it with a JS plugin later
const ClampWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const List = styled.ul`
  line-height: 1.6;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export type CardsTableProps = {
  isCorporate: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  cards: CardRowDetails[];
  role: RoleEnum | undefined;
};
export const CardsTable = ({
  isCorporate,
  loading,
  error,
  cards,
  role,
}: CardsTableProps): JSX.Element => {
  return (
    <DataTable
      loading={loading}
      error={error}
      items={cards}
      noItemsMessage={
        <Text color="notes">
          {role === RoleEnum.Admin
            ? '絞り込み条件に一致するカードがありません'
            : '利用できるカードがありません'}
        </Text>
      }
      noItemsIllustration={
        <Image
          src={role === RoleEnum.Admin ? noSearchResults : cardIllustration}
          alt="no results"
          width={250}
          height={200}
        />
      }
      thead={
        <Table.Row>
          <Table.ColumnHeaderCell>形状</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>名義人</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>カード情報</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            {isCorporate && '部署名/'}利用者
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell justify="end">
            上限金額
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>ステータス</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>メモ</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell justify="end">
            <TextWithQuestionIcon
              label="今月の利用額"
              content={
                <>
                  速報の利用明細が反映されています
                  <br />
                  確定後に金額が変わる場合があります
                </>
              }
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell />
        </Table.Row>
      }
      renderItem={(item) => (
        <Table.Row disabled={item.state === CardState.Terminated}>
          <Table.Cell width="88px">
            {item.cardType === CardTypeCategory.Physical
              ? 'リアル'
              : 'バーチャル'}
          </Table.Cell>
          <Table.Cell width="180px">{item.name}</Table.Cell>
          <Table.Cell width="180px">
            <Text color="inherit">**** **** **** {item.lastFour}</Text>
            <br />
            <Text color="inherit">
              有効期限：{item.expirationMonth.toString().padStart(2, '0')}/
              {item.expirationYear}
            </Text>

            {item.serialNumber && (
              <>
                <br />
                <Text color="inherit">
                  S/N {formatSerialNumber(item.serialNumber)}
                </Text>
              </>
            )}
          </Table.Cell>
          <Table.Cell>
            {item.cardNote.departmentName && (
              <>
                <Text color="inherit">{item.cardNote.departmentName}</Text>
                <br />
              </>
            )}
            <List>
              {item.cardHolders.map(
                (cardHolder) =>
                  cardHolder.officeMember && (
                    <li key={cardHolder.id}>
                      {cardHolder.officeMember.displayName}
                    </li>
                  )
              )}
            </List>
          </Table.Cell>
          <Table.Cell width="160px" justify="end">
            <AmountWrapper>
              <Text color="inherit">
                {CurrencyFormat.jp.format(item.cardControl.maximumLimitInMonth)}
                /月
              </Text>
              <br />
              <Text color="inherit">
                {CurrencyFormat.jp.format(item.cardControl.maximumPaymentLimit)}
                /回
              </Text>
            </AmountWrapper>
          </Table.Cell>
          <Table.Cell width="88px">
            <CardStatusText state={item.state} />
          </Table.Cell>
          <Table.Cell width="208px">
            <ClampWrapper>{item.cardNote.memo}</ClampWrapper>
          </Table.Cell>
          <Table.Cell width="124px" justify="end">
            <AmountWrapper>
              {CurrencyFormat.jp.format(
                Number(item.cardTransactions.totalAmount ?? 0)
              )}
            </AmountWrapper>
          </Table.Cell>
          <Table.Cell width="88px">
            <ButtonsWrapper>
              {item.state === CardState.Unactivated ? (
                <Link href="/activate">
                  <LinkButton>
                    <Button size="small" variant="outline">
                      有効化
                    </Button>
                  </LinkButton>
                </Link>
              ) : (
                <>
                  <Link href={`/cards/${item.id}`}>
                    <LinkButton>
                      <Button size="small" variant="outline">
                        詳細
                      </Button>
                    </LinkButton>
                  </Link>
                  <Link href={`/cards/transactions?cardId=${item.id}`}>
                    <LinkButton>
                      <Button size="small" variant="outline">
                        利用明細
                      </Button>
                    </LinkButton>
                  </Link>
                </>
              )}
            </ButtonsWrapper>
          </Table.Cell>
        </Table.Row>
      )}
    />
  );
};
