import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useIssueCardTokenMutation } from '@/graphql';

export type ToggleCardSensitiveInformationResult = [
  boolean,
  VoidFunction,
  {
    data: string;
    loading: boolean;
    errors: string | null;
  },
];
// base hook
export const useToggleCardSensitiveInformation = (
  cardId: string,
  maskedValue: string,
  fetchDetails: (cardToken: string, codeVerifier: string) => Promise<string>
): ToggleCardSensitiveInformationResult => {
  const [showInfo, setShowInfo] = useState(false);
  const [data, setData] = useState(maskedValue);
  const [cache, setCache] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);
  const [getCardToken] = useIssueCardTokenMutation({
    variables: {
      cardId,
    },
  });
  const handleError = (error: Error | ApolloError | unknown) => {
    let errorMessages = '';
    if (error instanceof ApolloError || error instanceof Error) {
      errorMessages = error.message;
    } else {
      errorMessages = JSON.stringify(error);
    }
    setErrors(errorMessages);
  };

  const getSensitiveCardDetails = async () => {
    setLoading(true);
    try {
      const { data } = await getCardToken();
      const cardToken = data?.issueCardToken?.cardToken;
      const codeVerifier = data?.issueCardToken?.codeVerifier;
      if (!cardToken || !codeVerifier) {
        throw new Error('could not get cardToken or codeVerififer');
      }
      const detail = await fetchDetails(cardToken, codeVerifier);

      setData(detail);
      setCache(detail);
    } catch (error) {
      handleError(error);
      setShowInfo(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showInfo) {
      setErrors(null);
      if (cache) {
        setData(cache);
      } else {
        getSensitiveCardDetails();
      }
    } else {
      setData(maskedValue);
    }
  }, [showInfo]);

  const toggleShowInfo = () => setShowInfo((prev) => !prev);

  return [showInfo, toggleShowInfo, { data, loading, errors }];
};
