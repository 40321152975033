import styled from 'styled-components';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { CardControl, CardState, RoleEnum } from '@/graphql';
import { RestrictForeignTransactionInput } from './inputs/RestrictForeignTransaction';

export type RestrictForeignTransactionRowProps = Pick<
  CardControl,
  'foreignPermission'
> & {
  state: CardState;
  role: RoleEnum | undefined;
};

const ErrorText = styled(Text)`
  color: #ec4949;
`;

const renderRestrictForeignTransaction = (
  state: CardState,
  role: RoleEnum,
  foreignPermission: boolean
): React.ReactNode => {
  switch (state) {
    case CardState.Unactivated:
      return '有効化待ち';
    case CardState.Terminated:
      return '解約済み';
    case CardState.Frozen:
      return <ErrorText>強制停止されました</ErrorText>;
    default: {
      if (role === RoleEnum.Admin) {
        return <RestrictForeignTransactionInput />;
      } else if (role === RoleEnum.Employee) {
        return !foreignPermission ? '制限あり' : 'オフ';
      }
    }
  }
};

export const RestrictForeignTransactionRow = ({
  role,
  foreignPermission,
  state,
}: RestrictForeignTransactionRowProps): JSX.Element => {
  return (
    <Table.Row>
      <Table.RowHeaderCell>
        {state === CardState.Frozen ||
        state === CardState.Terminated ||
        state === CardState.Unactivated ||
        role === RoleEnum.Employee ? (
          <>海外決済利用制限</>
        ) : (
          <TextWithQuestionIcon
            label="海外決済利用制限"
            content="海外決済（オンライン含む）の利用を制限します"
          />
        )}
      </Table.RowHeaderCell>
      <Table.Cell width="440px" size="large">
        {role &&
          renderRestrictForeignTransaction(state, role, foreignPermission)}
      </Table.Cell>
    </Table.Row>
  );
};
