import { yup } from '@/lib/yup';
import { SalesAmountEnum } from '@/graphql';

export type SalesAmountSchemaData = yup.Asserts<typeof salesAmountSchema>;

export const salesAmountSchema = yup.object({
  salesAmount: yup
    .mixed<SalesAmountEnum>()
    .oneOf(Object.values(SalesAmountEnum), '選択してください')
    .required('選択してください'),
});
