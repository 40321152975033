import styled from 'styled-components';
import { PageLayout } from '../../layouts/PageLayout';
import { StepperContext } from './Terminate';

const Box = styled.div({
  maxWidth: '800px',
});
type LayoutProps = {
  id?: string;
  children?: React.ReactNode;
};

const pageLayoutPropsValue = (statuses: string[], id?: string) => {
  switch (statuses.length) {
    case 1:
      return {
        title: 'カード解約手続き',
        backLinkText: '戻る',
        backHref: id ? `/cards/${id}` : '/cards',
      };
    case 2:
      return {
        title: 'カード解約手続き 入力内容確認',
      };
    default:
      return {
        title: 'カード解約完了',
      };
  }
};

export const Layout: React.FC<LayoutProps> = ({
  id,
  children,
}: LayoutProps) => (
  <StepperContext.Consumer>
    {({ statuses }) => (
      <PageLayout {...pageLayoutPropsValue(statuses, id)}>
        <Box>{children}</Box>
      </PageLayout>
    )}
  </StepperContext.Consumer>
);
