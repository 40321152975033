import { yup } from '@/lib/yup';
import { FinancialAssetsEnum } from '@/graphql';

export type FinancialAssetsSchemaData = yup.Asserts<
  typeof financialAssetsSchema
>;

export const financialAssetsSchema = yup.object({
  financialAssets: yup
    .mixed<FinancialAssetsEnum>()
    .oneOf(Object.values(FinancialAssetsEnum), '選択してください')
    .required('選択してください'),
});
