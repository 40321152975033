import { ChangeEventHandler, useState } from 'react';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

export const useAmountField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  field: ControllerRenderProps<TFieldValues, TName>
) => {
  const convertToHalfWidthNumber = (value: string) => {
    return value.replace(/[０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
  };

  const transformInput = (value: number): string => {
    if (isNaN(value) || value === 0) {
      return '';
    }
    return value.toLocaleString();
  };

  const transformOutput = (value: string): number => {
    const stripped = value.replace(/,/g, '');
    const halfWidth = convertToHalfWidthNumber(stripped);
    const output = Number(halfWidth);
    return isNaN(output) ? 0 : output;
  };

  const [blurKeyCount, setBlurKeyCount] = useState(0);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(transformOutput(e.target.value));

  // NOTE: To reflect the updated defaultValue into the target input, it re-render on onBlur by updating key.
  const onBlur = () => {
    setBlurKeyCount((c) => c + 1);
    field.onBlur();
  };

  const defaultValue = transformInput(field.value);
  return {
    key: blurKeyCount,
    defaultValue,
    onChange,
    onBlur,
  };
};
