import styled from 'styled-components';
import { CircularProgress } from '@/components/new/CircularProgress';

const NoDataLayout = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const Loading = (): JSX.Element => (
  <NoDataLayout>
    <CircularProgress size="xlarge" />
  </NoDataLayout>
);
