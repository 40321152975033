import { ChangeEventHandler, FocusEventHandler, Ref } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { Radio } from '@/components/new/Radio';
import { Table } from '@/components/new/Table';
import { ShippingMethodEnum } from '@/graphql';
import { FormValues } from '../../../schema';

const REGISTERED_ACTIVATED = `${ShippingMethodEnum.Registered},true`;
const REGISTERED_UNACTIVATED = `${ShippingMethodEnum.Registered},false`;
const REGULAR_UNACTIVATED = `${ShippingMethodEnum.Regular},false`;

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ShippingMethodTable = (): JSX.Element => {
  const {
    field: {
      onBlur: shippingMethodOnBlur,
      onChange: shippingMethodOnChange,
      value: shippingMethodValue,
      ref: shippingMethodRef,
    },
  } = useController<FormValues>({
    name: 'shippingMethod',
    defaultValue: ShippingMethodEnum.Registered,
  });
  const {
    field: {
      onBlur: isAutoActivatedOnBlur,
      onChange: isAutoActivatedOnChange,
      value: isAutoActivatedValue,
      ref: isAutoActivatedRef,
    },
  } = useController<FormValues>({
    name: 'isAutoActivated',
    defaultValue: true,
  });

  // input methods
  const onBlur: FocusEventHandler<HTMLInputElement> = () => {
    shippingMethodOnBlur();
    isAutoActivatedOnBlur();
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    switch (e.target.value) {
      case REGISTERED_ACTIVATED:
        shippingMethodOnChange(ShippingMethodEnum.Registered);
        isAutoActivatedOnChange(true);
        break;
      case REGISTERED_UNACTIVATED:
        shippingMethodOnChange(ShippingMethodEnum.Registered);
        isAutoActivatedOnChange(false);
        break;
      case REGULAR_UNACTIVATED:
        shippingMethodOnChange(ShippingMethodEnum.Regular);
        isAutoActivatedOnChange(false);
        break;
    }
  };
  const ref: Ref<HTMLInputElement> = (instance) => {
    shippingMethodRef(instance);
    isAutoActivatedRef(instance);
  };

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell>お届け方法</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell size="large">
            <RadioGroupWrapper>
              {/* NOTE: Using inline styles to unset position and margins because the default position: absolute
              causes layout issues where the radio button overlaps with other elements. */}
              <Radio
                style={{
                  position: 'unset',
                  marginLeft: 'unset',
                  marginRight: 'unset',
                }}
                value={REGISTERED_ACTIVATED}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={
                  `${shippingMethodValue},${isAutoActivatedValue}` ===
                  REGISTERED_ACTIVATED
                }
              >
                転送不要 簡易書留 / 有効化済カードでのお届け
              </Radio>
              <Radio
                style={{
                  position: 'unset',
                  marginLeft: 'unset',
                  marginRight: 'unset',
                }}
                value={REGISTERED_UNACTIVATED}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={
                  `${shippingMethodValue},${isAutoActivatedValue}` ===
                  REGISTERED_UNACTIVATED
                }
              >
                転送不要 簡易書留 / 未有効化カードでのお届け
              </Radio>
              <Radio
                style={{
                  position: 'unset',
                  marginLeft: 'unset',
                  marginRight: 'unset',
                }}
                value={REGULAR_UNACTIVATED}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                checked={
                  `${shippingMethodValue},${isAutoActivatedValue}` ===
                  REGULAR_UNACTIVATED
                }
              >
                普通郵便 / 未有効化カードでのお届け
              </Radio>
            </RadioGroupWrapper>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
