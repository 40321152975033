import { Controller, useFormContext } from 'react-hook-form';
import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { IncomeSelectOptions } from '@/lib/income';
import { IncomeSchemaData } from './schema';

export const IncomeInput = (): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IncomeSchemaData>();

  return (
    <>
      <Controller
        control={control}
        name="income"
        render={({ field }) => (
          <SelectBox
            {...field}
            aria-label="所得額"
            placeholder="選択してください"
            options={IncomeSelectOptions}
            value={IncomeSelectOptions.find(
              (option) => option.value === field.value
            )}
            onValueChange={(value) => field.onChange(value)}
            selectedTestId="selected-income"
            ariaControlsId="income"
          />
        )}
      />
      {errors.income && <FormError>{errors.income.message}</FormError>}
    </>
  );
};
