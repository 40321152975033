import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { RepresentativeFirstNameEnData } from './schema';

export const RepresentativeFirstNameEnInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RepresentativeFirstNameEnData>();

  return (
    <div>
      <TextField
        aria-label="代表者名（英語表記）"
        {...register('representativeFirstNameEn')}
        placeholder="例）Taro"
      />
      {errors.representativeFirstNameEn && (
        <FormError>{errors.representativeFirstNameEn.message}</FormError>
      )}
    </div>
  );
};
