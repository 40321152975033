import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import { Corporation, useSearchCorporationLazyQuery } from '@/graphql';
import { Button } from './Button';
import { Conditions } from './Conditions';
import { Result } from './Result';
import { CorporateSearchData, corporateSearchSchema } from './types';

export const Form = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const [search, { data, loading }] = useSearchCorporationLazyQuery({
    onError: handleQueryError,
  });
  const methods = useForm<CorporateSearchData>({
    mode: 'onChange',
    resolver: yupResolver(corporateSearchSchema),
  });

  const onSearch = useCallback(
    ({
      searchMethod,
      corporateName,
      prefectureCode,
      cityName,
    }: CorporateSearchData) => {
      search({
        variables:
          searchMethod === 'partial'
            ? {
                partialName: corporateName,
                prefectureCode,
                cityName,
              }
            : {
                prefixName: corporateName,
                prefectureCode,
                cityName,
              },
      });
    },
    []
  );

  const results = (data?.corporations?.nodes || []) as Corporation[];
  const resultTotalCount = data?.corporations?.totalCount || 0;

  return (
    <FormProvider {...methods}>
      <GlobalMessage />

      <form onSubmit={methods.handleSubmit(onSearch)}>
        <Conditions />
        <Button isLoading={loading} />
        {data && (
          <Result results={results} resultTotalCount={resultTotalCount} />
        )}
      </form>
    </FormProvider>
  );
};
