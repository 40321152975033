import { getBeginningOfTheMonth, getEndOfTheMonth } from '@/lib/date';
import { WalletCardsQueryVariables } from '@/graphql';

export const useTransactionTimeVariables = (): Pick<
  WalletCardsQueryVariables,
  'transactionTimeGte' | 'transactionTimeLte'
> => {
  const date = new Date(Date.now());
  const transactionTimeGte = getBeginningOfTheMonth(date);
  const transactionTimeLte = getEndOfTheMonth(date);

  return {
    transactionTimeGte,
    transactionTimeLte,
  };
};
