import styled from 'styled-components';
import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import { Form } from './Form';

const Box = styled.div`
  max-width: 800px;
`;

export const WalletPointsTransfer = (): JSX.Element => (
  <PageLayout
    title="ポイント振替"
    backHref="/wallet/points"
    backLinkText="ポイント"
  >
    <Head title="ポイント振替" />
    <Box>
      <Form />
    </Box>
  </PageLayout>
);
