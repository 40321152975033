import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { RepresentativeLastNameKanaData } from './schema';

export const RepresentativeLastNameKanaInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RepresentativeLastNameKanaData>();

  return (
    <div>
      <TextField
        aria-label="representativeLastNameKana"
        {...register('representativeLastNameKana')}
        placeholder="例）ヤマダ"
      />
      {errors.representativeLastNameKana && (
        <FormError>{errors.representativeLastNameKana.message}</FormError>
      )}
    </div>
  );
};
