import noDocuments from '@images/noDocuments.svg';
import { DataTable, DataTableProps } from '@/components/new/DataTable';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { CurrencyFormat, DateTimeFormat } from '@/lib/i18n';
import { PostPaidTransactionDetailsFragment } from '@/graphql';

export type PostPaidValueTransactionsTableProps = Pick<
  DataTableProps<PostPaidTransactionDetailsFragment>,
  'loading' | 'error' | 'items'
>;

export const PostPaidValueTransactionsTable = ({
  loading,
  error,
  items,
}: PostPaidValueTransactionsTableProps): JSX.Element => {
  return (
    <DataTable
      loading={loading}
      error={error}
      items={items}
      noItemsIllustration={
        <Image src={noDocuments} alt="" width={250} height={200} />
      }
      noItemsMessage={<Text color="notes">ご利用はありません</Text>}
      thead={
        <Table.Row>
          <Table.ColumnHeaderCell>取引日時</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>確定日時</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>取引内容</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell justify="end">
            確定金額
          </Table.ColumnHeaderCell>
        </Table.Row>
      }
      renderItem={(item) => {
        return (
          <Table.Row>
            <Table.Cell width="140px" size="large">
              {DateTimeFormat.jp.format(
                new Date(item.valueTransaction.transactionTime)
              )}
            </Table.Cell>
            <Table.Cell width="140px" size="large">
              {DateTimeFormat.jp.format(new Date(item.createdAt))}
            </Table.Cell>
            <Table.Cell size="large">
              {item.valueTransaction.description}
            </Table.Cell>
            <Table.Cell justify="end" width="200px" size="large">
              {CurrencyFormat.jp.format(Number(item.postPaidAmount))}
            </Table.Cell>
          </Table.Row>
        );
      }}
    />
  );
};
