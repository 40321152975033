import { Box } from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { useRegistrationTerms } from './hooks';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  justify-content: center;
  text-align: center;
  & button {
    width: 160px;
  }
`;

export const Buttons = (): JSX.Element => {
  const isAccountManagerValue = useWatch({ name: 'isAccountManager' });
  const { handleAgreeClick } = useRegistrationTerms({ isAgree: true });

  return (
    <Wrapper>
      <Box mb="16px">
        <Button
          size="large"
          disabled={!isAccountManagerValue}
          onClick={handleAgreeClick}
        >
          同意して進む
        </Button>
      </Box>
      <Link href="/offices">事業者選択へ戻る</Link>
    </Wrapper>
  );
};
