import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { PlusIcon } from '@/components/screen/settings/destinations/Index/PlusIcon';
import {
  useDeleteCorporateRegistration,
  UseDeleteCorporateRegistrationProps,
} from '../hooks/useDeleteCorporateRegistration';

const StyledButton = styled.button`
  color: #ec4949;
  font-size: 13px;
  border: none;
  background: none;
  padding: 0px;
`;
export type DeleteButtonProps = Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
>;
export const DeleteButton = ({ onClick }: DeleteButtonProps): JSX.Element => (
  <StyledButton onClick={onClick} type="button">
    削除
  </StyledButton>
);

const LoadingButtonLayout = styled.div`
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LoadingButton = (): JSX.Element => (
  <LoadingButtonLayout>
    <CircularProgress size="small" />
  </LoadingButtonLayout>
);

export type DeleteUploadedFileButtonProps = UseDeleteCorporateRegistrationProps;
export const DeleteUploadedFileButton = ({
  imageUuid,
}: DeleteUploadedFileButtonProps): JSX.Element => {
  const [deleteUpload, { loading }] = useDeleteCorporateRegistration({
    imageUuid,
  });

  if (loading) return <LoadingButton />;

  return <DeleteButton onClick={deleteUpload} />;
};

export type AppendFilesButtonProps = {
  handleAppend: (e: ChangeEvent<HTMLInputElement>) => void;
};
export const AppendFilesButton = ({
  handleAppend,
}: AppendFilesButtonProps): JSX.Element => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <Button
      variant="outline"
      size="medium"
      type="button"
      onClick={() => inputFileRef.current?.click()}
    >
      <PlusIcon color="#3b7de9" />
      ファイルを追加する
      <input
        type="file"
        hidden
        accept="image/png,image/jpeg,image/gif,application/pdf"
        multiple
        id="file-input"
        ref={inputFileRef}
        onChange={handleAppend}
      />
    </Button>
  );
};

const ButtonWrapper = styled.div({
  display: 'inline-block',
  '> button': {
    width: '160px',
  },
});

export type UploadButtonProps = {
  loading: boolean;
  disabled?: boolean;
};
export const UploadButton = ({
  loading,
  disabled = false,
}: UploadButtonProps): JSX.Element => (
  <ButtonWrapper>
    <Button type="submit" size="large" disabled={loading || disabled}>
      {loading ? <CircularProgress size="small" /> : 'アップロード'}
    </Button>
  </ButtonWrapper>
);
