import { useApiError } from '@/hooks/useApiError';
import {
  UpdateNotificationSettingsMutationVariables,
  useUpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationFn,
  UpdateNotificationSettingsMutationResult,
  UpdateNotificationSettingsMutationOptions,
} from '@/graphql';

export type UseUpdateNotifiationsReturn = [
  (
    notificationSettings: UpdateNotificationSettingsMutationVariables['notificationSettings']
  ) => ReturnType<UpdateNotificationSettingsMutationFn>,
  UpdateNotificationSettingsMutationResult,
];
export const useUpdateNotifications = (
  options: UpdateNotificationSettingsMutationOptions = {}
): UseUpdateNotifiationsReturn => {
  const { handleMutationError } = useApiError();
  const [update, mutationResult] = useUpdateNotificationSettingsMutation({
    onError: handleMutationError,
    ...options,
  });

  return [
    (notificationSettings) => update({ variables: { notificationSettings } }),
    mutationResult,
  ];
};
