import { Head } from '@/components/head';
import { Loading } from '@/components/screen/loading';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { useApiError } from '@/hooks/useApiError';
import { useIndividualIdentificationQuery } from '@/graphql';
import { Form } from './Form';

export const RegistrationIndividualApplicant = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const { data, loading } = useIndividualIdentificationQuery({
    onError: handleQueryError,
  });

  if (loading) return <Loading />;

  return (
    <RegistrationLayout>
      <Head title="本人情報の入力" />
      <RegistrationTitle title="本人情報の入力" center />
      <RegistrationNote center>
        本人確認書類と同じ情報を入力してください。
      </RegistrationNote>

      <Form data={data} />
    </RegistrationLayout>
  );
};
