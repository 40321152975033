import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { InformationMessage } from '@/components/ui/InformationMessage';
import {
  RoleEnum,
  useCardsInformationMessageRenderConditionQuery,
} from '@/graphql';

const Box = styled.div`
  margin-bottom: 8px;
`;

export const CardsInformationMessage = (): JSX.Element | null => {
  const { data, loading } = useCardsInformationMessageRenderConditionQuery({
    fetchPolicy: 'cache-and-network',
    onError: () => {
      // do nothing
    },
  });

  const showMessage =
    data?.currentOffice.wallet?.isCardCreationJobRunning &&
    data.currentOfficeMember.role === RoleEnum.Admin;

  if (loading || !showMessage) return null;

  return (
    <Box>
      <InformationMessage>
        <Text>
          追加カード反映中... しばらく時間を置いてから再読み込みしてください。
        </Text>
      </InformationMessage>
    </Box>
  );
};
