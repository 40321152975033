import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { NavisSsoLink } from '@/components/ui/NavisSsoLink';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import {
  MaximumLimitInMonthInput,
  MaximumPaymentLimitInput,
} from '@/components/ui/inputs';
import { TenantUsersInput } from '@/components/ui/inputs/TenantUsers';
import { addUserUrl } from '@/lib/navis';
import { FormValues } from '../../../schema';

const Note = styled(Text)`
  margin-left: 9px;
  font-weight: 400;
`;

const Box = styled.div`
  margin-bottom: 8px;
`;

const StyledText = styled.span`
  margin-right: 4px;
`;

export type InitialSettingsTableProps = {
  isCorporate: boolean;
};
export const InitialSettingsTable = ({
  isCorporate,
}: InitialSettingsTableProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();
  const link = new URL(addUserUrl);

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>
            初期設定
            <Note color="notes" size="small">
              ※あとから変更可能です
            </Note>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeaderCell width="200px">
            月ごとの上限金額
          </Table.RowHeaderCell>
          <Table.Cell>
            <MaximumLimitInMonthInput />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeaderCell width="200px">
            一回の上限金額
          </Table.RowHeaderCell>
          <Table.Cell>
            <MaximumPaymentLimitInput />
          </Table.Cell>
        </Table.Row>
        {isCorporate && (
          <Table.Row>
            <Table.RowHeaderCell width="200px">
              <StyledText>部署</StyledText>
              <StatusLabel color="gray">任意</StatusLabel>
            </Table.RowHeaderCell>
            <Table.Cell>
              <TextField
                aria-label="departmentName"
                {...register('departmentName')}
              />
              {errors.departmentName && (
                <FormError>{errors.departmentName.message}</FormError>
              )}
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.RowHeaderCell width="200px">
            <TextWithQuestionIcon
              label="カード利用者"
              content={
                <>
                  カードを利用する方を設定してください
                  <br />
                  決済の際に本人認証が必要となる場合があります
                </>
              }
            />
          </Table.RowHeaderCell>
          <Table.Cell>
            <Box>
              ユーザーが存在しない場合は
              <NavisSsoLink link={link} />
              から追加してください。
            </Box>
            <TenantUsersInput />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
