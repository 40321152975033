import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';

export const Layout: React.FC = ({ children }) => {
  return (
    <PageLayout title="入出金履歴">
      <Head title="入出金履歴" />
      {children}
    </PageLayout>
  );
};
