import { useContext } from 'react';
import { RouteHistoryContext } from '@/contexts/RouteHistoryProvider';

export const useBackHref = (): string => {
  const { routeHistory } = useContext(RouteHistoryContext);

  const lastCardsRoute = routeHistory
    .filter((route) => /^\/cards(\?.+)?$/.test(route))
    .pop();

  return lastCardsRoute ?? '/cards';
};
