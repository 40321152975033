import cardShipped from '@images/cardShipped.svg';
import { Head } from '@/components/head';
import { ActivateTitle } from '@/components/ui/ActivateTitle';
import { Image } from '@/components/ui/Image';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationProgressBar } from '@/components/ui/RegistrationProgressBar';

export const RegistrationShipped = (): JSX.Element => {
  return (
    <RegistrationLayout>
      <Head title="申請状況" />
      <ActivateTitle
        image={<Image src={cardShipped} width={360} height={160} />}
      >
        カードの発送を手配しました
        <br />
        お手元に届いたら有効化を行ってください
      </ActivateTitle>
      <RegistrationNote>
        申請内容の審査が完了し、ご登録いただいた住所宛（法人のお客様は法人宛）にカードを郵送いたします。お届けまでに1週間ほどかかる場合もございます。
        カードがお手元に届いたらカード台紙のQRコードを読み込むか、認証コードを入力して有効化を行ってください。
      </RegistrationNote>
      <RegistrationProgressBar completedStepCount={2} />
      <RegistrationButtons pattern="toActivate" />
    </RegistrationLayout>
  );
};
