import { Table } from '@/components/new/Table';
import { CorporateIdentification, UserIdentification } from '@/graphql';
import { AddressRow } from './AddressRow';

type Props = Pick<
  CorporateIdentification,
  | 'buildingName'
  | 'city'
  | 'name'
  | 'phoneNumber'
  | 'postalCode'
  | 'prefectureCode'
  | 'street'
> & {
  displayName?: string;
  accountManagerIdentification?: Pick<
    UserIdentification,
    'firstName' | 'lastName'
  > | null;
};

export const CorporateIdentificationTable = ({
  name,
  phoneNumber,
  displayName,
  accountManagerIdentification,
  ...addressRowProps
}: Props): JSX.Element => {
  // Note:
  // accountManager.userIdentification will be null or undefined if user is not a corporate account manager.
  // In addtion to that, account-manager's display name will not be returned as an empty string if user's office has activated an account.
  const accountManagerName = accountManagerIdentification
    ? `${accountManagerIdentification.lastName} ${accountManagerIdentification.firstName}`
    : displayName;

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>登録情報</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeaderCell>法人名</Table.RowHeaderCell>
          <Table.Cell width="440px">{name}</Table.Cell>
        </Table.Row>

        <AddressRow {...addressRowProps} />

        <Table.Row>
          <Table.RowHeaderCell>電話番号</Table.RowHeaderCell>
          <Table.Cell>{phoneNumber}</Table.Cell>
        </Table.Row>

        {!!accountManagerName && (
          <Table.Row>
            <Table.RowHeaderCell>取引担当者</Table.RowHeaderCell>
            <Table.Cell>{accountManagerName}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table.Root>
  );
};
