import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { RoleInput } from '@/components/ui/inputs';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  OfficeMember,
  RoleEnum,
  useUpdateOfficeMemberRoleMutation,
} from '@/graphql';
import { SaveButton } from './Button';
import { FormValues, schema } from './schema';

const LinkText = styled.span`
  font-weight: normal;
`;

export type UserRoleTableProps = Pick<OfficeMember, 'role' | 'isManager'> & {
  officeMemberId: string;
  isChangeMyself: boolean;
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const THeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserRoleTable = ({
  role,
  isManager,
  officeMemberId,
  isChangeMyself,
}: UserRoleTableProps): JSX.Element => {
  const router = useRouter();
  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      role,
    },
  });
  const [updateRole, { loading }] = useUpdateOfficeMemberRoleMutation({
    onCompleted(data) {
      showSuccessNotification('ロールを変更しました');

      methods.reset({
        role: data.updateOfficeMemberRole?.officeMember.role,
      });

      if (isChangeMyself) router.reload();
    },
    onError(error) {
      handleMutationError(error);
    },
  });
  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit = async ({ role: newRole }: FormValues) => {
    if (loading) return;

    const message =
      role === RoleEnum.Admin
        ? '「一般」に変更すると、紐づいているカードの情報しか閲覧できなくなりますがよろしいですか？'
        : '「管理者」に変更すると、マネーフォワード Pay for Businessのすべての情報を閲覧・管理できるようになりますがよろしいですか？';
    const confirmed = confirm(message);
    if (!confirmed) return;

    resetMessageAndNotification();

    await updateRole({ variables: { officeMemberId, role: newRole } });
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.ColumnHeaderCell colSpan={2}>
                <THeadWrapper>
                  ロールの変更
                  <Link
                    href="https://biz.moneyforward.com/support/biz-pay/faq/user-faq/f064.html"
                    target="_blank"
                  >
                    <LinkText>各ロールの説明はこちら</LinkText>
                  </Link>
                </THeadWrapper>
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.RowHeaderCell>ロール</Table.RowHeaderCell>
              <Table.Cell width="440px">
                <RoleInput>
                  <RoleInput.Radio role={RoleEnum.Admin} disabled={isManager} />
                  <RoleInput.Radio
                    role={RoleEnum.Employee}
                    disabled={isManager}
                  />
                </RoleInput>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
        {!isManager && (
          <SaveButton loading={loading} isDirty={methods.formState.isDirty} />
        )}
      </StyledForm>
    </FormProvider>
  );
};
