import styled from 'styled-components';
import { Step, StepProps, StepTie, Steps } from '@/components/new/Steps';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export type NewProgressBarProps = {
  step: number;
};

const getStepStatus = (
  step: number,
  currentStep: number
): StepProps['stepStatus'] => {
  if (step === currentStep) {
    return 'inProgress';
  } else if (step < currentStep) {
    return 'completed';
  } else {
    return 'waiting';
  }
};

export const NewProgressBar = ({ step }: NewProgressBarProps): JSX.Element => {
  return (
    <Wrapper>
      <Steps mode="number">
        <Step
          step={1}
          stepStatus={getStepStatus(1, step)}
          title="口座データ連携"
        />
        <StepTie completed={getStepStatus(2, step) !== 'waiting'} />
        <Step step={2} stepStatus={getStepStatus(2, step)} title="申請手続き" />
        <StepTie completed={getStepStatus(3, step) !== 'waiting'} />
        <Step
          step={3}
          stepStatus={getStepStatus(3, step)}
          title="口座振替手続き"
        />
        <StepTie completed={getStepStatus(4, step) !== 'waiting'} />
        <Step
          step={4}
          stepStatus={getStepStatus(4, step)}
          title="与信限度額付与"
        />
      </Steps>
    </Wrapper>
  );
};
