import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { EstablishmentDateData } from './schema';

export const EstablishmentDateInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EstablishmentDateData>();

  return (
    <div>
      <TextField
        aria-label="設立年月日"
        {...register('establishmentDate')}
        placeholder="例）1975/01/01"
      />
      {errors.establishmentDate && (
        <FormError>{errors.establishmentDate.message}</FormError>
      )}
    </div>
  );
};
