import { Controller, useFormContext } from 'react-hook-form';
import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { PlannedUsageAmountSelectOptions } from '@/lib/plannedUsageAmount';
import { PlannedUsageAmountSchemaData } from './schema';

export const PlannedUsageAmountInput = (): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PlannedUsageAmountSchemaData>();

  return (
    <>
      <Controller
        control={control}
        name="plannedUsageAmount"
        render={({ field }) => (
          <SelectBox
            {...field}
            aria-label="年間のご利用予定額"
            placeholder="選択してください"
            options={PlannedUsageAmountSelectOptions}
            value={PlannedUsageAmountSelectOptions.find(
              (option) => option.value === field.value
            )}
            onValueChange={(value) => field.onChange(value)}
            selectedTestId="selected-plannedUsageAmount"
            ariaControlsId="plannedUsageAmount"
          />
        )}
      />
      {errors.plannedUsageAmount && (
        <FormError>{errors.plannedUsageAmount.message}</FormError>
      )}
    </>
  );
};
