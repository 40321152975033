import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { getString } from '@/lib/query';
import { CardState, CardTypeCategory } from '@/graphql';
import {
  CARD_STATE_STOPPED,
  CARD_TYPE_CATEGORY_ALL,
  FormValues,
} from '../types';
import { stripInvalidStates } from './helpers';

export const useFormDefaultValues = (): FormValues => {
  const router = useRouter();

  const keyword = getString(router.query.keyword);
  const lastFour = getString(router.query.lastFour);
  const cardTypes = getCardTypes(router.query.cardTypes);
  const states = getStates(router.query.states);
  const serialNumber = getString(router.query.serialNumber);
  return {
    ...(keyword && { keyword }),
    ...(lastFour && { lastFour }),
    ...(cardTypes && { cardTypes }),
    ...(serialNumber && { serialNumber }),
    states,
  };
};

const getCardTypes = (
  query: ParsedUrlQuery[string]
): FormValues['cardTypes'] => {
  switch (query) {
    case CardTypeCategory.Physical:
    case CardTypeCategory.Virtual:
      return query;
    default:
      return CARD_TYPE_CATEGORY_ALL;
  }
};

const DEFAULT_STATES: (
  | CardState.Active
  | CardState.Unactivated
  | typeof CARD_STATE_STOPPED
)[] = [CardState.Active, CardState.Unactivated, CARD_STATE_STOPPED];

const getStates = (query: ParsedUrlQuery[string]): FormValues['states'] =>
  stripInvalidStates(query) ?? DEFAULT_STATES;
