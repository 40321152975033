import { CardState } from '@/graphql';

interface CardStateMessage
  extends Partial<Record<CardState, string | undefined>> {
  [CardState.Frozen]: string;
  [CardState.Terminated]: string;
}
export const cardStateMessage: CardStateMessage = {
  [CardState.Frozen]:
    '何らかの理由で強制停止されました。詳細はお問い合わせください。',
  [CardState.Terminated]: 'このカードは解約済みです',
};
