import { Table } from '@/components/new/Table';

export type CorporateRegistrationTableProps = {
  children: React.ReactNode;
};
export const CorporateRegistrationTable = ({
  children,
}: CorporateRegistrationTableProps): JSX.Element => (
  <Table.Root>
    <Table.Head>
      <Table.Row>
        <Table.ColumnHeaderCell colSpan={2}>
          登記事項証明書をアップロード
        </Table.ColumnHeaderCell>
      </Table.Row>
    </Table.Head>
    <Table.Body>{children}</Table.Body>
  </Table.Root>
);
