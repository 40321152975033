import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Radio } from '@/components/new/Radio';
import { Text } from '@/components/new/Text';
import { FormError } from '@/components/ui/FormError';
import { RoleEnum } from '@/graphql';
import { InviteRoleSchemaData, UpdateRoleSchemaData } from './schema';

const ROLE_CONTENT: {
  [key in RoleEnum]: {
    label: string;
    note: string;
  };
} = {
  [RoleEnum.Admin]: {
    label: '管理者',
    note: 'すべての情報を閲覧・管理することができます。',
  },
  [RoleEnum.Employee]: {
    label: '一般',
    note: '紐づいているカードの情報のみ閲覧することができます。',
  },
};

const RadioButtonLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'radio error'
    'note note';
  column-gap: 16px;
`;

const StyledFormError = styled(FormError)`
  grid-area: error;
  display: flex;
  align-items: center;
`;

const StyledNote = styled(Text).attrs({ color: 'notes' })`
  grid-area: note;
`;

export type RoleRadioProps = {
  role: RoleEnum;
  disabled?: boolean;
  errorMessage?: string;
};
export const RoleRadio = ({
  role,
  disabled,
  errorMessage,
}: RoleRadioProps): JSX.Element => {
  const { register } = useFormContext<
    InviteRoleSchemaData | UpdateRoleSchemaData
  >();

  return (
    <RadioButtonLayout>
      <Radio value={role} disabled={disabled} {...register('role')}>
        {ROLE_CONTENT[role].label}
      </Radio>
      {errorMessage && <StyledFormError>{errorMessage}</StyledFormError>}
      <StyledNote>{ROLE_CONTENT[role].note}</StyledNote>
    </RadioButtonLayout>
  );
};
