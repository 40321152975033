import { ApolloError } from '@apollo/client';
import noPoints from '@images/noPoints.svg';
import { DataTable } from '@/components/new/DataTable';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';

import { ScheduledPointFragment } from '@/graphql';
import { ScheduledPointsTableRow } from './ScheduledPointsTableRow';

export type ScheduledPointsTableUiProps = {
  loading: boolean;
  error: ApolloError | undefined;
  scheduledPoints: ScheduledPointFragment[];
};

export const ScheduledPointsTableUi = ({
  loading,
  error,
  scheduledPoints,
}: ScheduledPointsTableUiProps): JSX.Element => {
  const tableRows = scheduledPoints.map((point, index) => ({
    ...point,
    id: index.toString(),
  }));

  return (
    <DataTable
      loading={loading}
      error={error}
      items={tableRows}
      noItemsIllustration={
        <Image src={noPoints} alt="no points" width={250} height={200} />
      }
      noItemsMessage={<Text color="notes">付与予定のポイントはありません</Text>}
      thead={
        <Table.Row>
          <Table.ColumnHeaderCell>内容</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell justify="end">
            ポイント
          </Table.ColumnHeaderCell>
        </Table.Row>
      }
      renderItem={(item) => (
        <ScheduledPointsTableRow
          amount={item.amount}
          description={item.description}
        />
      )}
    />
  );
};
