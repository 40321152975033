import { styled, Tab, Tabs } from '@material-ui/core';
import { ReactNode, useState } from 'react';

const StyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: '#333333',
    fontWeight: 'bold',
  },
});

export type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ children, index, value }: TabPanelProps): JSX.Element => (
  <div role="tabpanel" hidden={value !== index}>
    {children}
  </div>
);

export type TabProps = {
  label: string;
  content?: ReactNode;
};
export type TabsProps = {
  tabs: TabProps[];
};

export const TabsLayout = ({ tabs }: TabsProps): JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: '16px' }}
        TabIndicatorProps={{ style: { background: '#3B7DE9' } }}
      >
        {tabs.map((tab, index) => (
          <StyledTab key={`tab-${index}`} label={tab.label} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel key={`tabpanel-${index}`} index={index} value={value}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};
