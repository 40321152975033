import styled from 'styled-components';
import { FlashMessage } from '@/components/new/FlashMessage';

const Box = styled.div`
  margin-bottom: 18px;
`;

export type CardStateMessageProps = {
  className?: string;
  children: React.ReactNode;
};
export const CardStateMessage = ({
  children,
}: CardStateMessageProps): JSX.Element => (
  <Box>
    <FlashMessage color="error">{children}</FlashMessage>
  </Box>
);
