import { FetchResult } from '@apollo/client';
import { useApiError } from '@/hooks/useApiError';
import {
  useCreateIdentificationVerificationMutation,
  CreateIdentificationVerificationMutation,
} from '@/graphql';
import { FormData } from './types';
import { useGoToStepsOrConfirmation } from '@/hooks';

export const useTermsForm = (): {
  onSubmit: (
    data: FormData
  ) => Promise<FetchResult<CreateIdentificationVerificationMutation>>;
} => {
  const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
  const { handleMutationError } = useApiError();
  const [createIdentificationVerification] =
    useCreateIdentificationVerificationMutation({
      onCompleted: () => goToStepsOrConfirmation(),
      onError: (error) => handleMutationError(error),
    });

  const onSubmit = (formData: FormData) =>
    createIdentificationVerification({
      variables: {
        isRegisteredInJapan: formData.isRegisteredInJapan === 'true',
        isNotFatca: formData.isNotFatca === 'true',
        isNotPeps: formData.isNotPeps === 'true',
        isNotAntisocialForce: formData.isNotAntisocialForce === 'true',
        isNotRelatedToSanctionedCountries:
          formData.isNotRelatedToSanctionedCountries === 'true',
      },
    });

  return { onSubmit };
};
