import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentProps, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import {
  PostPayLimitData,
  postPayLimitSchema,
} from '@/components/ui/inputs/PostPayLimit/schema';
import { useApiError } from '@/hooks/useApiError';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useUpdatePostPaySettingRequestAmountMutation } from '@/graphql';
import { ResultModal } from '../Modal';
import { UpdateButton } from './Button';
import { PostPayLimitEditTable } from './Table';

type ResultModalStateType = ComponentProps<typeof ResultModal>;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Box = styled.div`
  margin-bottom: 16px;
`;

type EditFormProps = {
  defaultValues?: DefaultValues<PostPayLimitData>;
  creditLine?: number;
};

export const PostPayLimitEditForm = ({
  defaultValues,
  creditLine,
}: EditFormProps): JSX.Element => {
  const [result, setResult] = useState<ResultModalStateType>({
    open: false,
    responseTitle: '',
    responseType: undefined,
  });
  const { handleMutationError } = useApiError();
  const [update, { loading }] = useUpdatePostPaySettingRequestAmountMutation({
    onError: handleMutationError,
    onCompleted: (data) => {
      const { responseType, responseTitle } = {
        ...data.updatePostPaySettingRequestAmount,
      };

      setResult({ open: true, responseTitle, responseType });
    },
  });
  const resetMessageAndNotification = useResetMessageAndNotification();

  const methods = useForm<PostPayLimitData>({
    defaultValues,
    resolver: yupResolver(postPayLimitSchema),
    context: { isEditMode: true },
  });

  const onSubmit = async (data: PostPayLimitData) => {
    resetMessageAndNotification();

    if (loading) return;

    await update({ variables: { requestAmount: data.postPayLimit * 10_000 } }); // 万円単位にする
  };

  return (
    <FormProvider {...methods}>
      <ResultModal
        open={result.open}
        responseTitle={result.responseTitle}
        responseType={result.responseType}
      />
      <Box>
        <Text>
          与信限度額の変更申請ができます。ご希望に沿えない場合もございますのでご了承ください。
        </Text>
      </Box>
      <GlobalMessage />
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <PostPayLimitEditTable creditLine={creditLine} />
        <UpdateButton loading={loading} />
      </StyledForm>
    </FormProvider>
  );
};
