import { yup } from '@/lib/yup';
import {
  InviteOfficeMemberInput,
  RoleEnum,
  UpdateOfficeMemberRoleInput,
} from '@/graphql';

const baseRoleSchema = yup.mixed<RoleEnum>();

export type InviteRoleSchemaData = Pick<InviteOfficeMemberInput, 'role'>;
export const inviteRoleSchema: yup.ObjectSchema<InviteRoleSchemaData> =
  yup.object({
    role: baseRoleSchema
      .oneOf([RoleEnum.Admin], 'ここからは招待できません')
      .default(RoleEnum.Admin),
  });

export type UpdateRoleSchemaData = Pick<UpdateOfficeMemberRoleInput, 'role'>;
export const updateRoleSchema: yup.ObjectSchema<UpdateRoleSchemaData> =
  yup.object({
    role: baseRoleSchema
      .oneOf(Object.values(RoleEnum), '選択してください')
      .required('選択してください'),
  });
