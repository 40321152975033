import { useRouter } from 'next/router';
import { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { RouteHistoryContext } from '@/contexts/RouteHistoryProvider';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  marginTop: '24px',
  '> button': {
    width: '160px',
  },
});

type ButtonsProps = {
  loading: boolean;
};

export const Buttons = ({ loading }: ButtonsProps): JSX.Element => {
  const { routeHistory } = useContext(RouteHistoryContext);
  const router = useRouter();

  // アクセスしたタイミングでrouteHistoryに現在のパスが追加される
  // ひとつ前の画面に戻る
  const lastRoute = routeHistory.slice(-2)[0];

  const handleClick = (): void => {
    // 現在のパスしかなかった場合はトップに遷移
    router.push(routeHistory.length > 1 ? lastRoute : '/');
  };

  return (
    <Wrapper>
      <Button
        variant="outline"
        size="large"
        type="button"
        onClick={handleClick}
      >
        キャンセル
      </Button>
      <Button type="submit" size="large" disabled={loading}>
        {loading ? <CircularProgress size="medium" /> : '招待メールを送る'}
      </Button>
    </Wrapper>
  );
};
