import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { getString } from '@/lib/query';
import { CurrentOfficeMembersQueryVariables, RoleEnum } from '@/graphql';

export const useSearchVariables = (): Pick<
  CurrentOfficeMembersQueryVariables,
  'displayName' | 'role'
> => {
  const router = useRouter();

  const displayName = getString(router.query.displayName);
  const role = getRoleEnum(router.query.role);

  return {
    ...(displayName && { displayName }),
    ...(role && { role }),
  };
};

const getRoleEnum = (query: ParsedUrlQuery[string]): RoleEnum | undefined => {
  switch (query) {
    case RoleEnum.Admin:
    case RoleEnum.Employee:
      return query;
    default:
      return undefined;
  }
};
