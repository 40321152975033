import styled from 'styled-components';
import { ClearTenantUser } from './ClearTenantUser';

const Layout = styled.div`
  display: flex;
  gap: 7px;
  font-size: 13px;
  font-weight: 400;
`;

export type TenantUserTitleProps = {
  displayName: string;
};

export const TenantUserTitle = ({
  displayName,
}: TenantUserTitleProps): JSX.Element => (
  <Layout>
    利用者：{displayName} で絞り込まれています
    <ClearTenantUser>絞込解除</ClearTenantUser>
  </Layout>
);
