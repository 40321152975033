import { UsagePurposeEnum } from '@/graphql';

export const UsagePurposeOptions: { value: UsagePurposeEnum; label: string }[] =
  [
    { value: UsagePurposeEnum.PurchasingGoods, label: '仕入れ' },
    {
      value: UsagePurposeEnum.ReimbursedExpenses,
      label: '立替経費（従業員利用）',
    },
    { value: UsagePurposeEnum.OtherBusinessExpenses, label: 'その他事業経費' },
  ];

export const getUsagePurposeLabel = (
  usagePurpose?: UsagePurposeEnum | null
): string => {
  const value = UsagePurposeOptions.find(
    (option) => option.value === usagePurpose
  );
  return value ? value.label : '';
};
