import { useRouter } from 'next/router';
import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import { SlackIntegrationModal } from './components/SlackIntegrationModal';
import { useConnectSlackWorkspace } from './hooks/connectSlackWorkspace';

const Content = (): JSX.Element => {
  const router = useRouter();
  const { code } = router.query;

  const [{ loading, errors }] = useConnectSlackWorkspace(code as string);

  return <SlackIntegrationModal loading={loading} errors={errors} />;
};

export const SettingsIntegrationsSlack = (): JSX.Element => {
  return (
    <PageLayout title="Slack連携">
      <Head title="Slack連携" />
      <Content />
    </PageLayout>
  );
};
