import { Loading } from '@/components/screen/loading';
import { LimitErrorMessage } from '@/components/ui/LimitErrorMessage';
import { useApiError } from '@/hooks/useApiError';
import { useTenantType } from '@/hooks/useTenantType';
import {
  CardColorEnum,
  CardTypeCategory,
  PostPaySettingStatusEnum,
  useWalletCardsNewQuery,
} from '@/graphql';
import { FormValues } from '../schema';
import { Form } from './components/Form';

const ISSUE_FEE = 990;

type Step1Props = {
  savedValues: FormValues | null;
  saveValues: (values: FormValues) => void;
};
export const Step1 = ({ savedValues, saveValues }: Step1Props): JSX.Element => {
  const { isCorporate, loading: tenantTypeLoading } = useTenantType();
  const { handleQueryError } = useApiError();
  const { data, loading: walletCardsNewQueryLoading } = useWalletCardsNewQuery({
    fetchPolicy: 'network-only',
    onError: handleQueryError,
  });

  if (tenantTypeLoading || walletCardsNewQueryLoading) return <Loading />;
  const corporateName = data?.currentOffice.cardName;
  const cardDestination =
    data?.currentOffice.wallet?.cardDestinationMasters?.find(
      (e) => e !== undefined
    );
  const isPostPayDisabled =
    data?.currentOffice.wallet?.postPaySetting?.status !==
    PostPaySettingStatusEnum.Activated;
  const isLocked = !!data?.currentOffice.wallet?.isLocked;

  const hasInsufficientBalance =
    isPostPayDisabled &&
    Number(data?.currentOffice.wallet?.value?.balance) < ISSUE_FEE;
  const defaultValues: Partial<FormValues> = savedValues ?? {
    corporateName,
    cardType: CardTypeCategory.Virtual,
    color: CardColorEnum.Black,
    maximumLimitInMonth: 2_000_000,
    maximumPaymentLimit: 1_000_000,
    ...(isCorporate &&
      cardDestination && {
        buildingName: cardDestination.buildingName,
        city: cardDestination.city,
        destinationDepartmentName: cardDestination.departmentName,
        destinationName: cardDestination.destinationName,
        postalCode: cardDestination.postalCode,
        prefectureCode: cardDestination.prefectureCode,
        street: cardDestination.street,
        tradeName: cardDestination.tradeName,
      }),
  };
  const isReachedMaxAmount =
    Number(data?.currentOffice.wallet?.maximumCreatableCardLimit) ===
    Number(data?.currentOffice.wallet?.cards.totalCount);

  return (
    <>
      {isReachedMaxAmount && <LimitErrorMessage />}
      <Form
        {...{
          isCorporate,
          hasInsufficientBalance,
          isReachedMaxAmount,
          maxUserCount: 5,
          savedValues: defaultValues,
          saveValues,
          isLocked,
        }}
      />
    </>
  );
};
