import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { RegistrationTable, RegistrationTableRow } from '@/components/ui';
import {
  InputGroup,
  LastNameInput,
  FirstNameInput,
  LastNameKanaInput,
  FirstNameKanaInput,
  BirthDateInput,
  AddressInputs,
  PhoneNumberInput,
  PurposeInput,
} from '@/components/ui/inputs';
import { FinancialAssetsInput } from '@/components/ui/inputs/FinancialAssets';
import { FirstNameEnInput } from '@/components/ui/inputs/FirstNameEn';
import { FirstNameKanaData } from '@/components/ui/inputs/FirstNameKana/schema';
import { IncomeInput } from '@/components/ui/inputs/Income';
import { LastNameEnInput } from '@/components/ui/inputs/LastNameEn';
import { LastNameKanaData } from '@/components/ui/inputs/LastNameKana/schema';
import { MiddleNameEnInput } from '@/components/ui/inputs/MiddleNameEn';
import { NationalityInput } from '@/components/ui/inputs/Nationality';
import { ResidencePeriodEndDateInput } from '@/components/ui/inputs/ResidencePeriodEndDate';
import { ResidentialStatusInput } from '@/components/ui/inputs/ResidentialStatus';
import { UsagePurposesInput } from '@/components/ui/inputs/UsagePurpose';
import { UserIndustryInput } from '@/components/ui/inputs/UserIndustry';

const MiddleNameWrapper = styled.div`
  margin-top: 8px;
`;

const InputWrapper = styled.div`
  width: 260px;
`;

const Box = styled.div`
  margin-bottom: 8px;
`;

export const FormDetails = ({ isJapanese }: { isJapanese: boolean }) => {
  const { setValue: setLastKana } = useFormContext<LastNameKanaData>();
  const { setValue: setFirstKana } = useFormContext<FirstNameKanaData>();

  const updateLastKana = (value: string) => {
    setLastKana('lastNameKana', value);
  };

  const updateFirstKana = (value: string) => {
    setFirstKana('firstNameKana', value);
  };

  return (
    <RegistrationTable header="本人情報">
      <RegistrationTableRow title="氏名">
        <InputGroup>
          <LastNameInput updateValue={updateLastKana} />
          <FirstNameInput updateValue={updateFirstKana} />
        </InputGroup>
      </RegistrationTableRow>
      <RegistrationTableRow title="氏名（フリガナ）">
        <InputGroup>
          <LastNameKanaInput />
          <FirstNameKanaInput />
        </InputGroup>
      </RegistrationTableRow>
      <RegistrationTableRow title="氏名（英語表記）">
        <InputGroup>
          <LastNameEnInput />
          <FirstNameEnInput />
        </InputGroup>
        <MiddleNameWrapper>
          <MiddleNameEnInput />
        </MiddleNameWrapper>
      </RegistrationTableRow>
      <RegistrationTableRow title="生年月日">
        <BirthDateInput />
      </RegistrationTableRow>
      <RegistrationTableRow title="住所">
        <Box>
          <Text size="small">
            本人確認書類に記載されている住所を入力してください。
          </Text>
        </Box>
        <AddressInputs />
      </RegistrationTableRow>
      <RegistrationTableRow title="電話番号">
        <Box>
          <Text size="small">
            カスタマーサポートからお電話をさせていただく場合がございます。
          </Text>
        </Box>
        <PhoneNumberInput />
      </RegistrationTableRow>
      <RegistrationTableRow title="国籍">
        <NationalityInput />
      </RegistrationTableRow>
      {!isJapanese && (
        <>
          <RegistrationTableRow title="在留資格">
            <InputWrapper>
              <ResidentialStatusInput />
            </InputWrapper>
          </RegistrationTableRow>
          <RegistrationTableRow title="在留期間（満了日）">
            <Box>
              <Text size="small">
                永住者や期限に定めのない場合は、在留カード等の「カード有効期限」を入力してください。
              </Text>
            </Box>
            <InputWrapper>
              <ResidencePeriodEndDateInput />
            </InputWrapper>
          </RegistrationTableRow>
        </>
      )}
      <RegistrationTableRow title="所得額">
        <Box>
          <Text size="small">
            世帯所得ではなく、ご自身の前年の所得を入力してください。
            <br />
            前年の所得がない場合は、今年の見込みを入力してください。
          </Text>
        </Box>
        <InputWrapper>
          <IncomeInput />
        </InputWrapper>
      </RegistrationTableRow>
      <RegistrationTableRow title="金融資産額">
        <Box>
          <Text size="small">
            現金やこれに準ずるもの（金・銀等）、預貯金、有価証券等の合計額を指し、土地・建物等の不動産、貯蓄型の生命保険や学資保険は含みません。
          </Text>
        </Box>
        <InputWrapper>
          <FinancialAssetsInput />
        </InputWrapper>
      </RegistrationTableRow>
      <RegistrationTableRow title="主な事業内容">
        <UserIndustryInput />
      </RegistrationTableRow>
      <RegistrationTableRow title="取引目的">
        <PurposeInput />
      </RegistrationTableRow>
      <RegistrationTableRow title="利用目的">
        <UsagePurposesInput />
      </RegistrationTableRow>
    </RegistrationTable>
  );
};
