import { Layout } from './Layout';
import { NotificationsContent } from './components/NotificationsContent/NotificationsContent';

export const SettingsNotificationsIndex = (): JSX.Element => {
  return (
    <Layout>
      <NotificationsContent />
    </Layout>
  );
};
