import { ApolloError } from '@apollo/client';
import { useApiError } from '@/hooks/useApiError';
import {
  TenantUserInfoFragment,
  useCurrentOfficeTenantUsersQuery,
} from '@/graphql';
import { useTenantUserUid } from './useTenantUserUid';

export const useTenantUser = (): {
  tenantUser: undefined | TenantUserInfoFragment;
  loading: boolean;
  error: undefined | ApolloError;
} => {
  const { handleQueryError } = useApiError();
  const tenantUserUid = useTenantUserUid();

  const skipTenantUsersQuery = tenantUserUid === undefined;
  const { data, loading, error } = useCurrentOfficeTenantUsersQuery({
    ...(tenantUserUid && { variables: { uids: [tenantUserUid] } }),
    skip: skipTenantUsersQuery,
    onError: handleQueryError,
  });

  const tenantUsers = data?.currentOffice.tenantUsers ?? [];
  const tenantUser = tenantUsers.length === 0 ? undefined : tenantUsers[0];

  // When a user is specified and not found, treat it as an error
  const notFoundError =
    skipTenantUsersQuery || loading || tenantUsers.length !== 0
      ? undefined
      : new ApolloError({});

  return {
    tenantUser,
    loading,
    error: error ?? notFoundError,
  };
};
