import styled from 'styled-components';
import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';
import {
  LoginDescription,
  LoginTitle,
  LoginAction,
  LoginLayout,
  LoginBlock,
} from '@/components/ui/Login';
import { getCreateOfficeUrl } from '@/lib/navis';
import { useLogout } from '@/hooks';

const Box = styled.div`
  padding: 16px 0;
  margin-bottom: 24px;
`;
export const RegisterOffice = (): JSX.Element => {
  const email = '';
  // state param is used for GA4
  const returnPath = '/registration/terms?state=registered';

  const { logout } = useLogout();

  return (
    <LoginLayout>
      <LoginBlock>
        <Head title="新規事業者の作成" />
        <LoginTitle>事業者がありません</LoginTitle>
        <LoginDescription>
          続けるには事業者の作成が必要です。
          <br />
          新しく事業者を作成しますか？
        </LoginDescription>
        <LoginAction>
          <Link href={getCreateOfficeUrl(email, returnPath)}>
            <Button size="large" color="blue" type="button">
              新規事業者を作成する
            </Button>
          </Link>
          <Box>
            <Button type="button" variant="ghost" onClick={logout}>
              ログアウト
            </Button>
          </Box>
        </LoginAction>
      </LoginBlock>
      <Text color="notes">
        ※ すでにマネーフォワード
        クラウドをご利用中のお客様で、この画面が表示された場合、正しいIDでログインしていない可能性があります。一度ログアウトをしたのち、正しいIDでログインをしてください。
      </Text>
    </LoginLayout>
  );
};
