import { RadioGroup } from '@material-ui/core';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Radio } from '@/components/new/Radio';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useUpdateProofOfPurchaseSettingMutation } from '@/graphql';
import { FormValues, schema } from './schema';

const StyledRadioGroup = styled(RadioGroup)`
  margin-bottom: 12px;
  > * + * {
    margin-left: 16px;
  }
`;

const Box = styled.div`
  margin-top: 8px;
`;

export type Props = {
  isSettingAvailable: boolean;
  isActive: boolean;
  isEdocActive: boolean;
};

export const ProofOfPurchaseSettingForm = ({
  isSettingAvailable,
  isActive,
  isEdocActive,
}: Props): JSX.Element => {
  const formActions = useForm<FormValues>({
    defaultValues: { isActive, isEdocActive },
    resolver: yupResolver(schema),
  });

  const isActiveWatcher = formActions.watch('isActive');

  const isAvailableEdoc = isSettingAvailable && isActiveWatcher;

  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const [updateProofOfPurchaseSetting, { loading }] =
    useUpdateProofOfPurchaseSettingMutation({
      onCompleted: (data) => {
        showSuccessNotification('変更を保存しました');
        const updatedIsActive =
          data.updateProofOfPurchaseSetting?.proofOfPurchaseSetting.isActive ??
          false;
        const updatedIsEdocActive =
          data.updateProofOfPurchaseSetting?.proofOfPurchaseSetting
            .isEdocActive ?? false;
        formActions.reset({
          isActive: updatedIsActive,
          isEdocActive: updatedIsEdocActive,
        });
      },
      onError: (error) => handleMutationError(error),
    });

  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit: SubmitHandler<FormValues> = async ({
    isActive,
    isEdocActive,
  }) => {
    if (loading) return;

    resetMessageAndNotification();
    await updateProofOfPurchaseSetting({
      variables: {
        isActive,
        isEdocActive,
      },
    });
  };

  return (
    <FormProvider {...formActions}>
      <form onSubmit={formActions.handleSubmit(onSubmit)}>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.ColumnHeaderCell colSpan={2}>
                証憑・メモ添付
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.RowHeaderCell>証憑・メモ添付機能</Table.RowHeaderCell>
              <Table.Cell width="440px">
                <Controller
                  control={formActions.control}
                  name="isActive"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <StyledRadioGroup row>
                      <Radio
                        disabled={!isSettingAvailable}
                        name="isActive"
                        aria-label="isActive-true"
                        checked={value === true}
                        onChange={() => onChange(true)}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        有効
                      </Radio>
                      <Radio
                        disabled={!isSettingAvailable}
                        name="isActive"
                        aria-label="isActive-false"
                        checked={value === false}
                        onChange={() => {
                          onChange(false);
                          formActions.setValue('isEdocActive', false, {
                            shouldDirty: true,
                          });
                        }}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        無効
                      </Radio>
                    </StyledRadioGroup>
                  )}
                />
                <Text>
                  証憑画像がマネーフォワード
                  クラウドBoxへ保存され、メモがマネーフォワード
                  クラウド会計・確定申告に連携されます。詳しくは
                  <Link
                    target="_blank"
                    href={
                      'https://biz.moneyforward.com/support/biz-pay/guide/memo'
                    }
                  >
                    サポートサイト
                  </Link>
                  をご確認ください。
                  <br />
                  ※クラウド経費をご利用の方は、クラウド経費から証憑を添付いただくことを推奨します。
                </Text>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.RowHeaderCell>電子帳簿保存法対応</Table.RowHeaderCell>
              <Table.Cell width="440px">
                <Controller
                  control={formActions.control}
                  name="isEdocActive"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <StyledRadioGroup row>
                      <Radio
                        disabled={!isAvailableEdoc}
                        name="isEdocActive"
                        aria-label="isEdocActive-true"
                        checked={value === true}
                        onChange={() => onChange(true)}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        有効
                      </Radio>
                      <Radio
                        disabled={!isAvailableEdoc}
                        aria-label="isEdocActive-false"
                        name="isEdocActive"
                        checked={value === false}
                        onChange={() => onChange(false)}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        無効
                      </Radio>
                    </StyledRadioGroup>
                  )}
                />
                <Text>
                  マネーフォワード
                  クラウドBox上で証憑に対してタイムスタンプが付与されます。
                </Text>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
        <Box>
          <Button
            variant="outline"
            type="submit"
            disabled={loading || !formActions.formState.isDirty}
          >
            変更を保存
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
