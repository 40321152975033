import { useRouter } from 'next/router';
import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  CurrentOfficeMembersDocument,
  useDeleteOfficeMemberMutation,
} from '@/graphql';
import { useLogout } from '@/hooks';

type DeleteUserButtonProps = {
  officeMemberId: string;
  isDeleteMyself: boolean;
  disabled: boolean;
};

export const DeleteUserButton = ({
  officeMemberId,
  disabled,
  isDeleteMyself,
}: DeleteUserButtonProps): JSX.Element => {
  const router = useRouter();
  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const { logout } = useLogout();
  const resetMessageAndNotification = useResetMessageAndNotification();
  const [deleteOfficeMember, { loading }] = useDeleteOfficeMemberMutation({
    variables: {
      officeMemberId,
    },
    refetchQueries: isDeleteMyself ? [] : [CurrentOfficeMembersDocument],
    onCompleted() {
      if (isDeleteMyself) {
        logout();
      } else {
        router
          .push('/settings/users')
          .then(() => showSuccessNotification('ユーザーを削除しました'));
      }
    },
    onError(error) {
      handleMutationError(error);
    },
  });

  const handleClick = () => {
    resetMessageAndNotification();

    if (loading || disabled) return;

    if (
      !confirm(
        'マネーフォワード Pay for Business のユーザーから削除します。\n削除すると、この事業者にログインできなくなりますがよろしいですか？\n※管理コンソールからは削除されません。'
      )
    )
      return;

    deleteOfficeMember();
  };

  return (
    <Button
      color="red"
      variant="outline"
      size="medium"
      type="button"
      onClick={handleClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <CircularProgress size="small" />
      ) : (
        'マネーフォワード Pay for Business のユーザーから削除'
      )}
    </Button>
  );
};
