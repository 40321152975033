import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { getString } from '@/lib/query';
import { RoleEnum } from '@/graphql';
import { FormValues, ROLE_ALL } from '../types';

export const useFormDefaultValues = (): FormValues => {
  const router = useRouter();

  const displayName = getString(router.query.displayName);
  const role = getRole(router.query.role);

  return {
    ...(displayName && { displayName }),
    role,
  };
};

const getRole = (query: ParsedUrlQuery[string]): FormValues['role'] => {
  switch (query) {
    case RoleEnum.Admin:
    case RoleEnum.Employee:
      return query;
    default:
      return ROLE_ALL;
  }
};
