import queryError from '@images/queryError.svg';
import React from 'react';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { Loading } from '@/components/screen/loading';
import { Image } from '@/components/ui/Image';
import { useApiError } from '@/hooks/useApiError';
import { TenantTypeEnum, usePostPayApplicationStatusQuery } from '@/graphql';
import { MergedContent } from './MergedContent';

const ErrorWrapper = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const QueryError = (): JSX.Element => (
  <ErrorWrapper>
    <Image src={queryError} alt="query error" width={250} height={200} />
    <Text color="notes">読み込みエラー</Text>
  </ErrorWrapper>
);

export const PostPayRegistrationIndex = (): JSX.Element => {
  const { handleQueryError } = useApiError();

  const { data, loading, error } = usePostPayApplicationStatusQuery({
    fetchPolicy: 'network-only',
    onError: handleQueryError,
  });

  if (error) {
    return <QueryError />;
  }

  if (!data || loading) {
    return <Loading />;
  }

  return (
    <MergedContent
      accountingOwner={data.currentOffice.accountingOwner}
      agreed={!!data.currentOffice?.accountingOwnerAgreement?.approvedAt}
      caSsoUrl={data.currentUser.caSsoUrl ?? ''}
      caPlusSsoUrl={data.currentUser.caPlusSsoUrl ?? ''}
      isCorporate={data.currentOffice.tenantType === TenantTypeEnum.Corporate}
      hasAccounts={
        (data.currentOffice.accountingOwner?.accounts?.length ?? 0) > 0
      }
      isOwnerActive={
        !!data.currentOffice.accountingOwner?.tenantUser.activatedAt
      }
    />
  );
};
