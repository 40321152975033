import { Head } from '@/components/head';
import {
  createThreeStepperContext,
  useCreateUseThreeStepper,
  Stepper,
} from '@/components/ui/Stepper';
import { Layout } from './Layout';
import { ProgressBar } from './ProgressBar';
import { Step } from './Step';

export const StepperContext = createThreeStepperContext();

export const useStepper = () => useCreateUseThreeStepper(StepperContext);

export const CardsNew = (): JSX.Element => {
  return (
    <Stepper Context={StepperContext}>
      <Layout>
        <Head title="カード追加発行" />
        <ProgressBar />
        <Step />
      </Layout>
    </Stepper>
  );
};
