import { useRouter } from 'next/router';
import { useState } from 'react';
import { DateTimeFormat } from '@/lib/i18n';
import { getDate } from '@/lib/query';
import { useApiError } from '@/hooks/useApiError';
import { useDownloadCSV } from '@/hooks/useDownloadCSV';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  ExportPostPaidTransactionMutationVariables,
  useExportPostPaidTransactionMutation,
} from '@/graphql';

type ExportPostPaidTransactionsFunction = (
  arg: ExportPostPaidTransactionMutationVariables
) => Promise<void>;

type UseExportPostPaidTransactionsReturn = [
  ExportPostPaidTransactionsFunction,
  { loading: boolean },
];

export const useExportPostPaidTransactions =
  (): UseExportPostPaidTransactionsReturn => {
    const { handleMutationError } = useApiError();
    const { showErrorNotification } = useGlobalNotification();
    const downloadCSV = useDownloadCSV();
    const [getPresignedUrlDetails] = useExportPostPaidTransactionMutation({
      onError: handleMutationError,
    });
    const [loading, setLoading] = useState(false);
    const resetMessageAndNotification = useResetMessageAndNotification();

    const router = useRouter();
    const date = getDate(router.query.targetMonth);
    const filename =
      date && `${DateTimeFormat.jpYearMonthJst.format(new Date(date))}利用履歴`;

    const exportTransactions = async (
      variables: ExportPostPaidTransactionMutationVariables
    ) => {
      if (loading) return;

      resetMessageAndNotification();
      setLoading(true);

      const { data } = await getPresignedUrlDetails({ variables });

      const presignedUrlDetails =
        data?.exportPostPaidTransaction?.presignedUrlResponse;

      if (!presignedUrlDetails) {
        showErrorNotification(
          '予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。'
        );

        setLoading(false);
        return;
      }

      await downloadCSV(presignedUrlDetails, filename);

      setLoading(false);
    };

    return [exportTransactions, { loading }];
  };
