import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { getDate, getString } from '@/lib/query';
import { DepositWithdrawal } from '@/graphql';
import { DepositWithdrawalType } from '../DepositWithdrawalTypeSelect';
import { FormValues } from '../SearchPanel';

export const useFormDefaultValues = (): Partial<FormValues> => {
  const router = useRouter();

  const depositWithdrawalType = getDepositWithdrawalType(
    router.query.depositWithdrawalType
  );
  const keyword = getString(router.query.keyword);
  const transactionTimeGte = getDate(router.query.transactionTimeGte);
  const transactionTimeLte = getDate(router.query.transactionTimeLte);

  return {
    depositWithdrawalType,
    ...(keyword && { keyword }),
    ...(transactionTimeGte && { transactionTimeGte }),
    ...(transactionTimeLte && { transactionTimeLte }),
  };
};

const getDepositWithdrawalType = (
  query: ParsedUrlQuery[string]
): DepositWithdrawalType['depositWithdrawalType'] => {
  switch (query) {
    case DepositWithdrawal.Deposit:
    case DepositWithdrawal.Withdrawal:
      return query;
    default:
      return DepositWithdrawal.Both;
  }
};
