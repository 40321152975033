import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useTenantType } from '@/hooks/useTenantType';
import {
  CardState,
  RoleEnum,
  useUpdateCardHoldersMutation,
  WalletCardDocument,
} from '@/graphql';
import { FormData, schema } from './schema';

const Box = styled.div`
  margin-top: 8px;
`;

type FormProps = {
  cardId: string;
  defaultValues: Partial<FormData>;
  cardState: CardState;
  role: RoleEnum | undefined;
};
export const Form: React.FC<FormProps> = ({
  cardId,
  defaultValues,
  cardState,
  children,
  role,
}): JSX.Element => {
  if (
    cardState === CardState.Unactivated ||
    cardState === CardState.Terminated ||
    role === RoleEnum.Employee
  )
    return <>{children}</>;

  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const { isCorporate } = useTenantType();
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
    context: { maxUserCount: 5 },
  });
  const [updateCardHolders, { loading }] = useUpdateCardHoldersMutation({
    refetchQueries: [{ query: WalletCardDocument, variables: { cardId } }],
    onCompleted: (data) => {
      showSuccessNotification('変更を保存しました');

      methods.reset({
        departmentName: data.updateCardHolders?.cardNote.departmentName,
        memo: data.updateCardHolders?.cardNote.memo,
        tenantUsers: (data.updateCardHolders?.cardHolders ?? []).map(
          (cardHolder) => ({
            label: cardHolder.officeMember?.displayName ?? '',
            value: cardHolder.tenantUserUid,
          })
        ),
      });
    },
    onError: (error) => handleMutationError(error),
  });
  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit = async ({ memo, departmentName, tenantUsers }: FormData) => {
    if (loading) return;

    resetMessageAndNotification();
    await updateCardHolders({
      variables: {
        cardId,
        tenantUserUids: tenantUsers.map((tenantUser) => tenantUser.value),
        memo,
        ...(isCorporate && { departmentName }),
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        <Box>
          <Button
            variant="outline"
            size="small"
            disabled={!methods.formState.isDirty || loading}
          >
            変更を保存
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
