import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';
import { useTenantType } from '@/hooks/useTenantType';

export const AccountManagerMessage = (): JSX.Element | null => {
  const { isCorporate, loading, error } = useTenantType();

  if (loading || error) return null;

  return (
    <div>
      {isCorporate ? (
        <>
          <div>
            <Text>
              このユーザーは「取引担当者」であるため編集できません。
              <Link
                href="https://support.biz.moneyforward.com/biz-pay/faq/word/f022.html"
                target="_blank"
              >
                取引担当者とは？
              </Link>
            </Text>
          </div>
          <div>
            <Text>
              取引担当者を変更する場合は、
              <Link href="/settings/office">事業者設定</Link>
              から取引担当者の変更手続きをお願いします。
              <br />
              取引担当者の変更は本人が行う必要があります。退職等で難しい場合はお問い合わせください。
            </Text>
          </div>
        </>
      ) : (
        <Text>
          このユーザーは事業主であるためロールの変更/ユーザーからの削除はできません。
        </Text>
      )}
    </div>
  );
};
