import { Block } from '@moneyforward/cloud-react-ui';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { DateTimeFormat } from '@/lib/i18n';
import { transformQueryResult } from '@/lib/transform';
import {
  ManagerUserIdentification,
  Maybe,
  Office,
  OwnerSubAccount,
  PostPaidBilling,
  TenantTypeEnum,
} from '@/graphql';

const Wrapper = styled(Block)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  align-items: flex-start;
  min-width: 640px;
  gap: 28px 16px;
  padding: 20px;
  overflow: hidden;
`;

const NameBlock = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 32px;
  font-weight: 700;
`;

const Amounts = styled.div`
  flex-wrap: wrap;
  min-height: 48px;
  padding: 8px 20px;
  border: 1px solid #bebebe;
`;

const AmountBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OriginalAmountBlock = styled(AmountBlock)`
  margin-top: 8px;
`;

const ValueDepositedAmountBlock = styled(AmountBlock)`
  margin-top: 4px;
`;

const AmountUnit = styled(Text)`
  margin-left: 4px;
`;

export type Props = {
  postPaidBilling: Pick<
    PostPaidBilling,
    'dueDate' | 'amount' | 'originalAmount' | 'valueDepositedAmount'
  >;
  account?: Maybe<
    Pick<OwnerSubAccount, 'serviceName' | 'type' | 'number' | 'name'>
  >;
  office: Pick<Office, 'tenantType' | 'name'>;
  managerUserIdentification?: Maybe<
    Pick<ManagerUserIdentification, 'firstName' | 'lastName'>
  >;
};

export const PostPayUsageDetailWidget = ({
  postPaidBilling,
  account,
  office,
  managerUserIdentification,
}: Props): JSX.Element => {
  const billingCompany = {
    name: '株式会社マネーフォワード',
    address:
      '〒108-0023 東京都港区芝浦3-1-21 msb Tamachi 田町ステーションタワーS 21F',
  };

  const isCorporate = office.tenantType === TenantTypeEnum.Corporate;
  const fullName = managerUserIdentification
    ? `${managerUserIdentification.lastName} ${managerUserIdentification.firstName}`
    : '';
  const name = isCorporate ? office.name : fullName;
  const displayDueDate = DateTimeFormat.jpYearMonthDayJst.format(
    new Date(postPaidBilling.dueDate)
  );
  const displayAmount = transformQueryResult.localeString(
    postPaidBilling.amount
  );
  const displayOriginalAmount = transformQueryResult.localeString(
    postPaidBilling.originalAmount
  );
  const displayValueDepositedAmount = transformQueryResult.localeString(
    postPaidBilling.valueDepositedAmount
  );
  return (
    <Wrapper border data-testid="postPayUsageDetailWidget">
      <NameBlock size="xlarge">{name} 様</NameBlock>
      <Amounts>
        <AmountBlock>
          <Text>引落確定額（{displayDueDate} 引落）</Text>
          <div>
            <Text size="xxxLarge">{displayAmount}</Text>
            <AmountUnit>円</AmountUnit>
          </div>
        </AmountBlock>
        <OriginalAmountBlock>
          <Text>利用額</Text>
          <div>
            <Text>{displayOriginalAmount}</Text>
            <AmountUnit>円</AmountUnit>
          </div>
        </OriginalAmountBlock>
        <ValueDepositedAmountBlock>
          <Text>ウォレット残高引落額</Text>
          <div>
            <Text>{displayValueDepositedAmount}</Text>
            <AmountUnit>円</AmountUnit>
          </div>
        </ValueDepositedAmountBlock>
      </Amounts>
      <div data-testid="postPayUsageDetailWidgetAccount">
        引落口座： <br />
        {account ? (
          <>
            {account.serviceName}
            <br />
            {account.type && `${account.type} `}
            {account.number && `${account.number} `}
            {account.name}
          </>
        ) : (
          '-'
        )}
      </div>
      <div>
        請求元： <br />
        {billingCompany.name}
        <br />
        {billingCompany.address}
      </div>
    </Wrapper>
  );
};
