import { useApolloClient } from '@apollo/client';
import { useController } from 'react-hook-form';
import {
  components,
  MultiValueProps,
  OptionTypeBase,
  StylesConfig,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  CurrentOfficeTenantUsersDocument,
  CurrentOfficeTenantUsersQuery,
  CurrentOfficeTenantUsersQueryVariables,
  useCurrentOfficeTenantUsersQuery,
} from '@/graphql';
import { FormError } from '../../FormError';

const stylesConfig: StylesConfig<OptionTypeBase, true> = {
  control: (styles) => ({
    ...styles,
    background: '#FFFFFF',
  }),
  menu: (styles) => ({
    ...styles,
    top: 'calc(100% - 3px)', // Make it look as if control and menu are connected
    margin: '0',
    borderRadius: '0 0 4px 4px', // Top corners should be flushed with control
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0', // Remove white space within menu list
  }),
  option: (styles) => ({
    ...styles,
    ':last-child': {
      borderRadius: '0 0 4px 4px', // Last option's bottom corners should be rounded to match menu
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#FFFFFF',
    border: `1px solid #0000001A`, // black 0.1 opacity
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#333333',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#7C8291',
    ':hover': {
      backgroundColor: '#EBF1FB',
    },
  }),
};

const MultiValue = (props: MultiValueProps<OptionTypeBase>) => {
  // Display custom tag field for newly selected users, and label for existing users
  return (
    <components.MultiValue {...props}>
      {props.data.tag ?? props.data.label}
    </components.MultiValue>
  );
};
export type SelectOption = {
  label: string;
  value: string;
};

export const TenantUsersInput = (): JSX.Element => {
  const { query } = useApolloClient();
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'tenantUsers' });
  const { data: users } = useCurrentOfficeTenantUsersQuery({
    variables: {
      page: 1,
      perPage: 50,
    },
  });

  const parseOptions = (users: CurrentOfficeTenantUsersQuery | undefined) => {
    if (!users?.currentOffice.tenantUsers) return [];
    return users.currentOffice.tenantUsers.map((user) => ({
      label: `${user.displayName} ${user.email}`,
      value: user.uid,
      tag: user.displayName,
    }));
  };

  const handleSearch = async (input: string) => {
    const { data } = await query<
      CurrentOfficeTenantUsersQuery,
      CurrentOfficeTenantUsersQueryVariables
    >({
      query: CurrentOfficeTenantUsersDocument,
      variables: {
        displayName: input,
      },
    });

    return (data.currentOffice.tenantUsers ?? []).map<{
      label: string;
      value: string;
    }>((tenantUser) => ({
      label: `${tenantUser.displayName} ${tenantUser.email}`,
      value: tenantUser.uid,
      tag: tenantUser.displayName,
    }));
  };

  return (
    <>
      <AsyncSelect
        {...field}
        aria-label="tenantUsers"
        instanceId="tenantUsersSelect"
        isMulti
        isClearable={true}
        placeholder="表示名でユーザーを検索"
        defaultOptions={parseOptions(users)}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? 'ユーザーが見つかりませんでした' : null
        }
        loadOptions={handleSearch}
        loadingMessage={({ inputValue }) =>
          inputValue ? '検索しています' : null
        }
        styles={stylesConfig}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          MultiValue,
        }}
      />
      {error && <FormError>{error.message}</FormError>}
    </>
  );
};
