import styled from 'styled-components';
import { Head } from '@/components/head';
import { Link } from '@/components/new/Link';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';

type LayoutProps = {
  children: React.ReactNode;
};

const Box = styled.div`
  margin-top: 24px;
`;

const LinkWrapper = styled.span`
  margin-right: 16px;
`;

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <RegistrationLayout>
      <Head title="ユーザー招待" />
      <RegistrationTitle title="ユーザー招待" center />
      <RegistrationNote center>
        新しいユーザーを管理者として招待します。
        <LinkWrapper>
          <Link
            href="https://biz.moneyforward.com/support/biz-pay/faq/user-faq/f064.html"
            target="_blank"
          >
            各ロールの説明はこちら
          </Link>
        </LinkWrapper>
      </RegistrationNote>
      <Box>{children}</Box>
    </RegistrationLayout>
  );
};
