import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { getSerialNumber, getString } from '@/lib/query';
import {
  CardState,
  CardTypeCategory,
  WalletCardsQueryVariables,
} from '@/graphql';
import { CARD_STATE_STOPPED } from '../types';
import { stripInvalidStates } from './helpers';

const DEFAULT_STATES = [
  CardState.Active,
  CardState.Frozen,
  CardState.Locked,
  CardState.Suspended,
  CardState.Unactivated,
];

export const useSearchVariables = (): Pick<
  WalletCardsQueryVariables,
  'keyword' | 'lastFour' | 'cardTypes' | 'states' | 'serialNumber'
> => {
  const router = useRouter();

  const keyword = getString(router.query.keyword);
  const lastFour = getString(router.query.lastFour);
  const cardTypes = getCardTypes(router.query.cardTypes);
  const states = getStates(router.query.states);
  const serialNumber = getSerialNumber(router.query.serialNumber);

  return {
    ...(keyword && { keyword }),
    ...(lastFour && { lastFour }),
    ...(serialNumber && { serialNumber }),
    cardTypes,
    states,
  };
};

const getCardTypes = (query: ParsedUrlQuery[string]): CardTypeCategory[] => {
  switch (query) {
    case CardTypeCategory.Physical:
    case CardTypeCategory.Virtual:
      return [query];
    default:
      return [CardTypeCategory.Physical, CardTypeCategory.Virtual];
  }
};

const getStates = (query: ParsedUrlQuery[string]): CardState[] =>
  stripInvalidStates(query)?.reduce<CardState[]>((results, state) => {
    switch (state) {
      case CardState.Active:
      case CardState.Terminated:
      case CardState.Unactivated:
        return [...results, state];
      case CARD_STATE_STOPPED:
        return [
          ...results,
          CardState.Frozen,
          CardState.Locked,
          CardState.Suspended,
        ];
      default:
        return results;
    }
  }, []) ?? DEFAULT_STATES;
