import cardShipped from '@images/cardShipped.svg';
import cardActivated from '@images/registrationCompleted.svg';
import Script from 'next/script';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { FlashMessage } from '@/components/new/FlashMessage';
import { Link } from '@/components/new/Link';
import { Image } from '@/components/ui/Image';
import { delightedScript } from '@/lib/delighted';
import { useLogger } from '@/hooks/useLogger';
import { CardTypeCategory, useDelightedQuery } from '@/graphql';
import { useStepper } from '../New';
import { FormValues } from './schema';

type Step3Props = {
  savedValues: FormValues;
  saveValues: (values: null) => void;
};

const LinkButton = styled.span({
  display: 'grid',
});

const ImageWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px;
  place-content: center;
  margin-top: 24px;
`;

const LinkWrapper = styled.div`
  text-align: center;
  margin: 16px 0;
`;

export const Step3 = ({ savedValues, saveValues }: Step3Props): JSX.Element => {
  const { cardType, name, tenantUsers } = savedValues;
  const { firstStep } = useStepper();
  const { logError } = useLogger();
  const { data, loading, error } = useDelightedQuery({
    onError: () =>
      logError('[NLS]: Delightedで使用するデータが取得できませんでした'),
  });
  const isVirtualCard = cardType === CardTypeCategory.Virtual;
  // Remove email address and list all tenant user names separated by comma
  const tenantUserNames = tenantUsers
    .map((tenantUser) => tenantUser.label.replace(/[^\s]*@.*$/, '').trim())
    .join(',');
  // When user continues to create a card, clear the data
  const handleClick = () => {
    firstStep();
    saveValues(null);
  };

  const officeId = data?.currentOffice.id;
  const officeMemberId = data?.currentOfficeMember.id;
  const userId = data?.currentUser.id;
  const tenantType = data?.currentOffice.tenantType;

  return (
    <>
      {!loading && !error && (
        <Script
          id="show-nls"
          dangerouslySetInnerHTML={{
            __html: delightedScript({
              officeId,
              officeMemberId,
              userId,
              tenantType,
            }),
          }}
        />
      )}

      <FlashMessage>
        {isVirtualCard
          ? `バーチャルカード ${name}（カード利用者 : ${tenantUserNames}）を発行しました。カード番号はカード詳細画面から確認できます。※反映に時間がかかる場合があります。`
          : `リアルカード ${name}（カード利用者 : ${tenantUserNames}）の発行手続きを承りました。おおよそ1週間でカードをお届けします。お届けまで今しばらくお待ちください。`}
      </FlashMessage>

      <ImageWrapper>
        <Image
          src={isVirtualCard ? cardActivated : cardShipped}
          alt={isVirtualCard ? 'card activated' : 'card shipped'}
          width={360}
          height={160}
        />
      </ImageWrapper>

      <ButtonWrapper>
        <Link href="/cards">
          <LinkButton>
            <Button variant="outline" size="large">
              カード一覧へ戻る
            </Button>
          </LinkButton>
        </Link>
      </ButtonWrapper>

      <LinkWrapper>
        <Link href="#" onClick={handleClick}>
          続けてカード発行する
        </Link>
      </LinkWrapper>
    </>
  );
};
