import { cardLimitsSchema } from '@/components/ui/inputs/Card';
import { tenantUsersSchema } from '@/components/ui/inputs/TenantUsers/schema';
import { yup } from '@/lib/yup';
import { CardDestinationAddressInput, XardCardRequestInput } from '@/graphql';
import {
  cardDesignSchema,
  cardDestinationSchema,
  cardTypeSchema,
  corporateNameSchema,
  departmentNameSchema,
  isAutoActivatedSchema,
  nameSchema,
  pinSchema,
  shippingMethodSchema,
} from './Step1/components';

export type FormValues = Omit<
  XardCardRequestInput,
  | 'cardCategory'
  | 'tenantUserUid'
  | 'tenantUserUids'
  | 'destinationAddress'
  | 'foreignPermission'
> & {
  tenantUsers: {
    label: string;
    value: string;
  }[];
} & Partial<CardDestinationAddressInput>;

export const schema: yup.ObjectSchema<FormValues> = cardTypeSchema
  .concat(cardDesignSchema)
  .concat(nameSchema)
  .concat(pinSchema)
  .concat(corporateNameSchema)
  .concat(cardLimitsSchema)
  .concat(departmentNameSchema)
  .concat(tenantUsersSchema)
  .concat(shippingMethodSchema)
  .concat(isAutoActivatedSchema)
  .concat(cardDestinationSchema);
