import { yup } from '@/lib/yup';
import {
  CardTypeCategory,
  ShippingMethodEnum,
  XardCardRequestInput,
} from '@/graphql';

export const isAutoActivatedSchema: yup.ObjectSchema<
  Pick<XardCardRequestInput, 'isAutoActivated'>
> = yup.object({
  isAutoActivated: yup
    .bool()
    .when('cardType', ([cardType], schema) =>
      cardType === CardTypeCategory.Physical
        ? schema.required()
        : schema.strip()
    ),
});

export const shippingMethodSchema: yup.ObjectSchema<
  Pick<XardCardRequestInput, 'shippingMethod'>
> = yup.object({
  shippingMethod: yup
    .mixed<ShippingMethodEnum>()
    .when('cardType', ([cardType], schema) =>
      cardType === CardTypeCategory.Physical
        ? schema.oneOf(Object.values(ShippingMethodEnum)).required()
        : schema.strip()
    ),
});
