import styled from 'styled-components';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { NavisSsoLink } from '@/components/ui/NavisSsoLink';
import { tenantUrl } from '@/lib/navis';
import { formatIdentificationCode } from '@/lib/string';
import { Office } from '@/graphql';

type Props = Pick<Office, 'name' | 'identificationCode'>;

const Box = styled.div`
  margin-bottom: 15px;
`;

export const OfficeTable = ({
  name,
  identificationCode,
}: Props): JSX.Element => {
  const link = new URL(tenantUrl);

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>事業者</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeaderCell>事業者番号</Table.RowHeaderCell>
          <Table.Cell width="440px">
            {formatIdentificationCode(identificationCode)}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeaderCell>事業者名</Table.RowHeaderCell>
          <Table.Cell>
            <Box>{name}</Box>

            <Text size="small">
              事業者名の変更は
              <NavisSsoLink link={link} />
              で行います
            </Text>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
