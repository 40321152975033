import { yup } from '@/lib/yup';
import { NotificationSettingInput } from '@/graphql';
import { thresholdSchema } from './inputs/schema';

export type NotificationSettingInputData = NotificationSettingInput;

const notificationSettingInputSchema: yup.ObjectSchema<NotificationSettingInputData> =
  yup
    .object({
      code: yup.string().required(),
      isMailActive: yup.boolean().nullable(),
      isPushActive: yup.boolean().nullable(),
      isSlackActive: yup.boolean().nullable(),
    })
    .concat(thresholdSchema);

export type NotificationSettingInputsData = yup.Asserts<
  typeof notificationSettingInputsSchema
>;

export const notificationSettingInputsSchema = yup.object({
  notificationSettingInputs: yup
    .array()
    .of(notificationSettingInputSchema)
    .required(),
});
