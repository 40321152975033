import { WalletTransactionsContent } from './Content';
import { Layout } from './Layout';

export const WalletTransactions = (): JSX.Element => {
  return (
    <Layout>
      <WalletTransactionsContent />
    </Layout>
  );
};
