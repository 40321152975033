import question from '@images/icons/question.svg';
import styled from 'styled-components';
import { Head } from '@/components/head';
import { Link } from '@/components/new/Link';
import { Tooltip } from '@/components/new/Tooltip';
import { Image } from '@/components/ui/Image';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { DetailsForm } from './DetailsForm';

const ImageWrapper = styled.div`
  margin: 0 4px;
`;

const LinkWrapper = styled.div`
  margin-top: 16px;
`;

const FormWrapper = styled.div`
  margin-top: 24px;
`;

export const RegistrationCorporateAccountManager = (): JSX.Element => {
  return (
    <RegistrationLayout>
      <Head title="取引担当者情報の入力" />
      <RegistrationTitle title="取引担当者情報の入力" center />
      <RegistrationNote center>
        取引担当者
        <Tooltip content="代表者または経理責任者等">
          <ImageWrapper>
            <Image src={question} width={12} height={12} />
          </ImageWrapper>
        </Tooltip>
        ご自身により入力してください。
        <Link
          href="https://support.biz.moneyforward.com/biz-pay/faq/word/f022.html"
          target="_blank"
        >
          取引担当者とは
        </Link>
        <br />
        またこの後、本人確認書類および容貌の撮影を行って頂く必要があります。
        <LinkWrapper>
          ※ 取引担当者ご本人ではない場合、
          <Link href="/registration/corporate/invite">こちら</Link>
          から招待してください。
        </LinkWrapper>
      </RegistrationNote>
      <FormWrapper>
        <DetailsForm />
      </FormWrapper>
    </RegistrationLayout>
  );
};
