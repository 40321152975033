import { useRouter } from 'next/router';
import { useWalletCardQuery } from '@/graphql';

export const useCard = () => {
  const { id } = useRouter().query;
  return useWalletCardQuery({
    variables: {
      cardId: id as string,
    },
    skip: typeof id !== 'string',
  });
};
