import {
  useFormContext,
  Controller,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
} from 'react-hook-form';
import styled from 'styled-components';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useAmountField } from '@/hooks/useAmountField';
import { CardLimitsData } from './schema';

const FieldWrapper = styled.div`
  display: inline-block;
  width: 40%;
  margin-right: 2%;
`;

const AmountField = <TF extends FieldValues, TN extends FieldPath<TF>>({
  field,
}: {
  field: ControllerRenderProps<TF, TN>;
}) => {
  const amountField = useAmountField(field);
  return (
    <FieldWrapper>
      <TextField
        ref={field.ref}
        name={field.name}
        {...amountField}
        aria-label="maximumLimitInMonth"
      />
    </FieldWrapper>
  );
};

export const MaximumLimitInMonthInput = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext<CardLimitsData>();

  return (
    <>
      <Controller
        control={control}
        name="maximumLimitInMonth"
        render={({ field }) => {
          return <AmountField field={field} />;
        }}
      />
      円
      {errors.maximumLimitInMonth && (
        <section data-testid="maximumLimitInMonthError">
          <FormError>{errors.maximumLimitInMonth.message}</FormError>
        </section>
      )}
    </>
  );
};
