import postPaySettingStatusRejected from '@images/postPaySettingStatusRejected.svg';
import postPaySettingStatusStep1 from '@images/postPaySettingStatusStep1.svg';
import postPaySettingStatusStep2 from '@images/postPaySettingStatusStep2.svg';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { transformQueryResult } from '@/lib/transform';
import { PostPayRequestStatusEnum } from '@/graphql';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  height: 200px;
  margin-bottom: 16px;
  padding: 24px;
  overflow: hidden;
  background: white;
  border-radius: 4px;
  box-sizing: border-box;
  border: solid 1px #d4d8dd;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 32px;
`;

const HeaderText = styled(Text)`
  font-weight: bold;
  margin-bottom: 2px;
`;

const ContentText = styled(Text)`
  line-height: 20px;
`;

export type WidgetProps = {
  status: PostPayRequestStatusEnum;
  startDate?: string | null;
  creditLine?: number | null;
};

export const PostPayApplicationStatusWidget = ({
  status,
  startDate,
  creditLine,
}: WidgetProps): JSX.Element => {
  return (
    <>
      {/* Hide this widget when status is pending or reviewing */}
      {status !== PostPayRequestStatusEnum.Pending &&
        status !== PostPayRequestStatusEnum.Reviewing && (
          <Wrapper data-testid="postPayApplicationStatus">
            {status === PostPayRequestStatusEnum.RequestedAccountTransfer && (
              <RequestedAccountTransfer />
            )}

            {status === PostPayRequestStatusEnum.Rejected && <Rejected />}

            {status === PostPayRequestStatusEnum.Activated && (
              <Activated startDate={startDate} creditLine={creditLine} />
            )}
          </Wrapper>
        )}
    </>
  );
};

const RequestedAccountTransfer = (): JSX.Element => {
  return (
    <>
      <Image
        src={postPaySettingStatusStep1}
        alt="与信審査 ステップ2"
        width={314}
        height={49}
      />
      <TextWrapper>
        <HeaderText>
          与信審査が完了しました、続いて口座振替手続を行います
        </HeaderText>
        <ContentText>
          マネーフォワードケッサイ株式会社より郵送される口座振替依頼書にご記入の上
          <br />
          返送してください。（毎月15日締切・翌月利用開始）
        </ContentText>
      </TextWrapper>
    </>
  );
};

const Rejected = (): JSX.Element => {
  return (
    <>
      <Image
        src={postPaySettingStatusRejected}
        alt="与信審査 審査結果"
        width={314}
        height={49}
      />
      <TextWrapper>
        <HeaderText>
          審査結果：今回はご希望に沿うことができませんでした
        </HeaderText>
        <ContentText>
          誠に恐れ入りますが、詳細についてはメールをご確認ください。
          <br />
          1時間後以降に再申込みが可能となります。
        </ContentText>
      </TextWrapper>
    </>
  );
};

const Activated = ({
  startDate,
  creditLine,
}: Pick<WidgetProps, 'startDate' | 'creditLine'>): JSX.Element => {
  return (
    <>
      <Image
        src={postPaySettingStatusStep2}
        alt="与信審査 ステップ3"
        width={314}
        height={49}
      />
      <TextWrapper>
        <HeaderText>口座振替手続が完了しました</HeaderText>
        <ContentText>
          <span>
            与信限度額付与予定日：
            {startDate
              ? new Date(startDate).toLocaleDateString('ja-JP', dateOptions)
              : '-'}
          </span>
          <br />
          <span>
            与信限度額：
            {creditLine
              ? transformQueryResult.tenThousandLocaleString(creditLine) +
                '万円'
              : '-'}
          </span>
        </ContentText>
      </TextWrapper>
    </>
  );
};

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};
