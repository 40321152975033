import styled from 'styled-components';
import { Table } from '@/components/new/Table';

export const FileLayout = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  min-height: 20px;
`;

const RowLabel = styled.span`
  font-weight: 700;
`;

export type FileRowProps = {
  children: React.ReactNode;
};
export const FileRow = ({ children }: FileRowProps): JSX.Element => (
  <Table.Row>
    <Table.Cell width="196px">
      <RowLabel>ファイルを選択</RowLabel>
    </Table.Cell>
    <Table.Cell>{children}</Table.Cell>
  </Table.Row>
);
