import styled from 'styled-components';
import { CircularProgress } from '@/components/new/CircularProgress';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  CardDestinationMastersDocument,
  useCardDestinationMastersQuery,
  useDeleteCardDestinationMasterMutation,
} from '@/graphql';
import { AddDestinationButton } from './Buttons';
import { Layout } from './Layout';
import { DestinationsTable } from './Table';
import styles from './styles.module.css';

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-index-loading-overlay);
  overflow: hidden;
`;

const ButtonArea = styled.div`
  display: flex;
  align-content: center;
`;

const Loading = () => (
  <div className={styles.loading}>
    <CircularProgress size="large" />
  </div>
);
export const SettingsDestinationsIndex = (): JSX.Element => {
  const { showSuccessNotification } = useGlobalNotification();
  const { handleQueryError, handleMutationError } = useApiError();

  const { data, loading, error } = useCardDestinationMastersQuery({
    onError: handleQueryError,
  });
  const destinations = data?.currentOffice.wallet?.cardDestinationMasters || [];

  const [deleteDestination, { loading: deleting }] =
    useDeleteCardDestinationMasterMutation({
      onCompleted(success) {
        if (success) {
          showSuccessNotification('カード送付先を削除しました');
        }
      },
      onError(error) {
        handleMutationError(error);
      },
      refetchQueries: [{ query: CardDestinationMastersDocument }],
    });

  const resetMessageAndNotification = useResetMessageAndNotification();

  const onDelete = (id: string) => {
    if (deleting) return;
    resetMessageAndNotification();
    if (!confirm('カード送付先を削除してよろしいですか？')) return;
    deleteDestination({ variables: { cardDestinationMasterId: id } });
  };

  return (
    <Layout>
      <ButtonArea>
        <AddDestinationButton />
      </ButtonArea>
      <GlobalMessage />
      <DestinationsTable
        loading={loading}
        error={error}
        destinations={destinations}
        onDelete={onDelete}
      />
      {deleting && (
        <Overlay>
          <Loading />
        </Overlay>
      )}
    </Layout>
  );
};
