import Image from 'next/image';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Radio } from '@/components/new/Radio';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { CardColorEnum } from '@/graphql';
import { FormValues } from '../../../schema';
import blackCard from '@/assets/images/realCardBlack.svg';
import whiteCard from '@/assets/images/realCardWhite.svg';

const RadioSection = styled.section`
  display: flex;
  gap: 24px;
`;

const RadioInner = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LabelText = styled(Text)`
  vertical-align: top;
`;

export const CardDesignTable = (): JSX.Element => {
  const { register } = useFormContext<FormValues>();
  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.Cell colSpan={2} bold>
            <Text>発行するカードのデザインを選択してください</Text>
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={2}>
            <RadioSection>
              <Radio {...register('color')} value={CardColorEnum.Black}>
                <RadioInner>
                  <LabelText>ブラック</LabelText>
                  <Image src={blackCard} alt="" />
                </RadioInner>
              </Radio>
              <Radio {...register('color')} value={CardColorEnum.White}>
                <RadioInner>
                  <LabelText>ホワイト</LabelText>
                  <Image src={whiteCard} alt="" />
                </RadioInner>
              </Radio>
            </RadioSection>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
