import { yup } from '@/lib/yup';
import { PlannedCardHolderCountEnum } from '@/graphql';

export type PlannedCardHolderCountSchemaData = yup.Asserts<
  typeof plannedCardHolderCountSchema
>;

export const plannedCardHolderCountSchema = yup.object({
  plannedCardHolderCount: yup
    .mixed<PlannedCardHolderCountEnum>()
    .oneOf(Object.values(PlannedCardHolderCountEnum), '選択してください')
    .required('選択してください'),
});
