import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { CardState } from '@/graphql';

export type CardStatusTextProps = {
  state: CardState;
};

const ErrorText = styled(Text)`
  color: #ec4949;
`;

export const CardStatusText = ({ state }: CardStatusTextProps): JSX.Element => {
  switch (state) {
    case CardState.Active:
      return <Text>利用中</Text>;
    case CardState.Frozen:
    case CardState.Locked:
    case CardState.Suspended:
      return <ErrorText>停止中</ErrorText>;
    case CardState.Terminated:
      return <Text color="notes">解約済</Text>;
    case CardState.Unactivated:
      return <Text>有効化待ち</Text>;
    default:
      throw new Error('unknown status');
  }
};
