import { useState } from 'react';
import { useApiError } from '@/hooks/useApiError';
import { useDownloadCSV } from '@/hooks/useDownloadCSV';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  CreateAsyncCsvExportPresignedUrlMutationVariables,
  useCreateAsyncCsvExportPresignedUrlMutation,
} from '@/graphql';

type ExportArgument = CreateAsyncCsvExportPresignedUrlMutationVariables & {
  fileName: string;
};

type ExportFunction = (arg: ExportArgument) => Promise<void>;
type UseAsyncExportReturn = [ExportFunction, { loading: boolean }];

export const useAsyncExport = (): UseAsyncExportReturn => {
  const { handleMutationError } = useApiError();
  const { showErrorNotification } = useGlobalNotification();
  const downloadCSV = useDownloadCSV();
  const [getPresignedUrlDetails, { loading: mutationLoading }] =
    useCreateAsyncCsvExportPresignedUrlMutation({
      onError: (error) => handleMutationError(error),
    });
  const [loading, setLoading] = useState(false);
  const resetMessageAndNotification = useResetMessageAndNotification();

  const exportAsyncronously = async (variables: ExportArgument) => {
    if (loading) return;

    resetMessageAndNotification();
    setLoading(true);

    const { data } = await getPresignedUrlDetails({ variables });

    const presignedUrlDetails =
      data?.createAsyncCsvExportPresignedUrl?.presignedUrlResponse;

    if (!presignedUrlDetails) {
      showErrorNotification(
        '予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。'
      );
      setLoading(false);
      return;
    }

    await downloadCSV(
      presignedUrlDetails,
      variables.fileName.replace(/\.csv$/, ''),
      true
    );

    setLoading(false);
  };

  return [exportAsyncronously, { loading: loading || mutationLoading }];
};
