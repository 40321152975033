import { addressSchema } from '@/components/ui/inputs/Address/schema';
import { yup } from '@/lib/yup';
import { CardDestinationMasterInput } from '@/graphql';

export const schema: yup.ObjectSchema<CardDestinationMasterInput> = yup
  .object({
    departmentName: yup.string().nullable().default(null).max(25),
    destinationName: yup.string().required().max(23),
    label: yup.string().required().max(30),
    tradeName: yup.string().nullable().default(null).max(25),
  })
  .concat(addressSchema);
