export type SignInQuery = {
  prompt?: 'select_account' | 'login';
  state: string;
  loginHint: string;
  maxAge?: number;
};

type SignUpQuery = {
  state: string;
};

export const getLoginUrl = (query: SignInQuery): string => {
  const { prompt, state, loginHint } = query;

  const searchParams = new URLSearchParams();
  const authorizeParams = {
    prompt: prompt || '',
    client_id: process.env.NEXT_PUBLIC_MFID_CLIENT_ID,
    redirect_uri: mfidCallbackUri,
    response_type: 'code',
    scope: 'openid email evora.all',
    state: state,
    login_hint: loginHint || '',
    max_age: query.maxAge === undefined ? undefined : `${query.maxAge}`,
  };
  // exclude keys which have falsy value
  // NOTE: The value of max_age is 0 as a string number, so it treated as true
  Object.entries(authorizeParams).forEach(([key, value]) => {
    value && searchParams.append(key, value);
  });

  return (
    `${process.env.NEXT_PUBLIC_MFID_URL}/oauth/authorize` +
    '?' +
    `${searchParams.toString()}`
  );
};

export const getSignUpUrl = (query: SignUpQuery): string => {
  const { state } = query;
  return (
    `${process.env.NEXT_PUBLIC_MFID_URL}/sign_up` +
    `?client_id=${process.env.NEXT_PUBLIC_MFID_CLIENT_ID}` +
    `&redirect_uri=${encodeURIComponent(mfidCallbackUri)}` +
    `&response_type=code` +
    `&scope=openid+email+evora.all` +
    `&state=${state}`
  );
};

export const mfidCallbackUri = `${process.env.NEXT_PUBLIC_CLOUD_WALLET_FRONTEND_URL}/auth/callback`;
