import { Link } from '@/components/new/Link';
import { Loading } from '@/components/screen';
import { useApiError } from '@/hooks/useApiError';
import {
  TenantTypeEnum,
  useCurrentOfficeAccountManagerNameQuery,
  useCurrentOfficeIdentificationQuery,
  useCurrentOfficeProofOfPurchaseSettingQuery,
  useCurrentOfficeQuery,
  useSettingsOfficeRenderConditionQuery,
} from '@/graphql';
import { CorporateIdentificationTable } from './CorporateIdentificationTable';
import { IndividualIdentificationTable } from './IndividualIdentificationTable';
import { Layout } from './Layout';
import { OfficeTable } from './OfficeTable';
import { ProofOfPurchaseSettingForm } from './components/ProofOfPurchaseSettingForm';
import { WithdrawalTable } from './components/WithdrawalTable/WithdrawalTable';
import styles from './styles.module.css';

const Tables = (): JSX.Element | null => {
  const { handleQueryError } = useApiError();
  const { data: renderConditionData, loading: renderConditionLoading } =
    useSettingsOfficeRenderConditionQuery({
      onError: handleQueryError,
    });
  const {
    data: officeIdentificationData,
    loading: officeIdentificationLoading,
  } = useCurrentOfficeIdentificationQuery({
    fetchPolicy: 'cache-and-network',
    onError: handleQueryError,
    skip:
      !renderConditionData ||
      (renderConditionData.currentOffice.tenantType ===
        TenantTypeEnum.Individual &&
        renderConditionData.currentOfficeMember.isManager === false),
  });
  const { data: officeNameAndCodeData, loading: officeNameAndCodeLoading } =
    useCurrentOfficeQuery({
      fetchPolicy: 'cache-and-network',
      onError: handleQueryError,
    });
  const { data: accountManagerNameData, loading: accountManagerNameLoading } =
    useCurrentOfficeAccountManagerNameQuery({
      fetchPolicy: 'cache-and-network',
      onError: handleQueryError,
      skip:
        !renderConditionData ||
        renderConditionData.currentOffice.tenantType ===
          TenantTypeEnum.Individual,
    });

  const { data: currentOfficeForPOP, loading: currentOfficeForPOPLoading } =
    useCurrentOfficeProofOfPurchaseSettingQuery({
      fetchPolicy: 'cache-and-network',
      onError: handleQueryError,
    });

  if (
    renderConditionLoading ||
    officeIdentificationLoading ||
    officeNameAndCodeLoading ||
    accountManagerNameLoading ||
    currentOfficeForPOPLoading
  )
    return <Loading />;

  const officeNameAndCode = officeNameAndCodeData?.currentOffice;
  const corporateIdentification =
    officeIdentificationData?.currentOffice?.identificationVerificationRequest
      ?.corporateIdentification;
  const userIdentification =
    officeIdentificationData?.currentOffice?.identificationVerificationRequest
      ?.individualIdentification?.userIdentification;
  const accountManagerOfficeMember =
    accountManagerNameData?.currentOffice?.accountManagerOfficeMember;
  const accountManagerIdentification =
    accountManagerNameData?.currentOffice?.identificationVerificationRequest
      ?.corporateIdentification?.accountManager?.userIdentification;
  const proofOfPurchaseAvailable =
    currentOfficeForPOP?.currentOffice.proofOfPurchaseAvailable ?? false;
  const proofOfPurchaseIsActive =
    currentOfficeForPOP?.currentOffice.wallet?.proofOfPurchaseSetting
      .isActive ?? false;
  const proofOfPurchaseIsEdocActive =
    currentOfficeForPOP?.currentOffice.wallet?.proofOfPurchaseSetting
      .isEdocActive ?? false;
  return (
    <>
      {officeNameAndCode && <OfficeTable {...officeNameAndCode} />}

      {corporateIdentification && (
        <div>
          <CorporateIdentificationTable
            {...corporateIdentification}
            {...accountManagerOfficeMember}
            accountManagerIdentification={accountManagerIdentification}
          />
          <div className={styles.editLinkWrapper}>
            登録情報の変更を希望される場合は、
            <Link
              href="https://biz.moneyforward.com/support/biz-pay/guide/user/g098.html"
              target="_blank"
            >
              サポートサイト
            </Link>
            をご確認の上お問い合わせフォームからご連絡ください。
          </div>
        </div>
      )}

      {userIdentification && (
        <div>
          <IndividualIdentificationTable {...userIdentification} />
          <div className={styles.editLinkWrapper}>
            登録情報の変更を希望される場合は、
            <Link
              href="https://biz.moneyforward.com/support/biz-pay/guide/user/g098.html"
              target="_blank"
            >
              サポートサイト
            </Link>
            をご確認の上お問い合わせフォームからご連絡ください。
          </div>
        </div>
      )}

      {currentOfficeForPOP && (
        <ProofOfPurchaseSettingForm
          isSettingAvailable={proofOfPurchaseAvailable}
          isActive={proofOfPurchaseIsActive}
          isEdocActive={proofOfPurchaseIsEdocActive}
        />
      )}

      {officeNameAndCode && renderConditionData?.currentOfficeMember && (
        <WithdrawalTable
          officeName={officeNameAndCode.name}
          isManager={renderConditionData?.currentOfficeMember.isManager}
          isCorporate={
            TenantTypeEnum.Corporate ===
            renderConditionData.currentOffice.tenantType
          }
        />
      )}
    </>
  );
};

export const SettingsOfficeIndex = (): JSX.Element => {
  return (
    <Layout>
      <Tables />
    </Layout>
  );
};
