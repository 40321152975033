import { yupResolver } from '@hookform/resolvers/yup';
import queryError from '@images/queryError.svg';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { Loading } from '@/components/screen/loading';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { Image } from '@/components/ui/Image';
import { useApiError } from '@/hooks/useApiError';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import {
  usePostPayApplicationInfoQuery,
  useCreatePostPayRequestMutation,
} from '@/graphql';
import { NewProgressBar } from '../shared/NewProgressBar';
import { ApplicationButton } from './Button';
import { PostPayApplicationTable } from './components/PostPayApplicationTable';
import { PostPayDestinationTable } from './components/PostPayDestinationTable';
import { FormData, schema } from './schema';

const ErrorWrapper = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
const Box = styled.div`
  margin: 32px 0;
`;
const ErrorView = (): JSX.Element => (
  <ErrorWrapper>
    <Image src={queryError} alt="query error" width={250} height={200} />
    <Text color="notes">読み込みエラー</Text>
  </ErrorWrapper>
);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const TextWrapper = styled.div({
  marginTop: '32px',
  textAlign: 'center',
});

export const Form = (): JSX.Element => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const router = useRouter();
  const { handleQueryError, handleMutationError } = useApiError();
  const { data, loading, error } = usePostPayApplicationInfoQuery({
    onError: handleQueryError,
  });

  const [
    postPayRequest,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useCreatePostPayRequestMutation({
    onCompleted: () => {
      router.push('/postpay/registration/complete');
    },
    onError: handleMutationError,
  });

  const isMutationCompleted = !!mutationData && !mutationError;

  const resetMessageAndNotification = useResetMessageAndNotification();

  const onSubmit = useCallback(
    (data: FormData) => {
      resetMessageAndNotification();

      if (mutationLoading) return;

      postPayRequest({
        variables: {
          phoneNumber: data.phoneNumber,
          requestAmount: data.postPayLimit * 10_000, // 万円単位
          subAccountId: data.subAccountId,
        },
      });
    },
    [mutationLoading]
  );

  if (loading) return <Loading />;

  if (error) return <ErrorView />;

  const accounts = data?.currentOffice?.accountingOwner?.accounts || [];
  const corporateIdentification =
    data?.currentOffice?.identificationVerificationRequest
      ?.corporateIdentification;
  const userIdentification =
    data?.currentOffice?.identificationVerificationRequest
      ?.individualIdentification?.userIdentification;

  return (
    <FormProvider {...methods}>
      <Box>
        <NewProgressBar step={2} />
        <TextWrapper>
          <Text>
            審査の結果、利用できない場合や希望金額に満たない場合もございます。
            <br />
            引落口座選択の際は十分な残高のある口座をご選択ください。
          </Text>
        </TextWrapper>
      </Box>
      <GlobalMessage />
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <PostPayApplicationTable accounts={accounts} />
        {corporateIdentification && (
          <PostPayDestinationTable
            postalCode={corporateIdentification.postalCode}
            prefectureCode={corporateIdentification.prefectureCode}
            city={corporateIdentification.city}
            street={corporateIdentification.street}
            buildingName={corporateIdentification.buildingName}
            corporateName={corporateIdentification.name}
            lastName={corporateIdentification.representativeLastName}
            firstName={corporateIdentification.representativeFirstName}
          />
        )}
        {userIdentification && (
          <PostPayDestinationTable
            postalCode={userIdentification.postalCode}
            prefectureCode={userIdentification.prefectureCode}
            city={userIdentification.city}
            street={userIdentification.street}
            buildingName={userIdentification.buildingName}
            corporateName={undefined}
            lastName={userIdentification.lastName}
            firstName={userIdentification.firstName}
          />
        )}
        <ApplicationButton loading={mutationLoading || isMutationCompleted} />
      </StyledForm>
    </FormProvider>
  );
};
