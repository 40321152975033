import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';

export type NumberOfCardLimitTextProps = {
  maximumCreatableCardLimit: number;
  totalCount: number;
};

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const Spacer = styled.span`
  margin: 0 4px;
`;

export const NumberOfCardLimitText = ({
  maximumCreatableCardLimit,
  totalCount,
}: NumberOfCardLimitTextProps): JSX.Element => {
  return (
    <Wrapper>
      <Text>
        発行枚数<Spacer>:</Spacer>
        <span>{totalCount.toLocaleString()}枚</span>
      </Text>
      <div>
        <TextWithQuestionIcon
          label="上限枚数"
          content={
            <>
              上限枚数の引き上げは「カード追加発行 &gt;
              <br />
              上限枚数の引き上げ」からご連絡ください
            </>
          }
        />
        <Text>
          <Spacer>:</Spacer>
          <span>{maximumCreatableCardLimit.toLocaleString()}枚</span>
        </Text>
      </div>
    </Wrapper>
  );
};
