import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';

type InviteButtonProps = {
  loading: boolean;
};
export const InviteButton = ({ loading }: InviteButtonProps): JSX.Element => (
  <Button size="large" disabled={loading}>
    {loading ? <CircularProgress size="medium" /> : '招待'}
  </Button>
);
