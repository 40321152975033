import { Props as RegistrationStepCardProps } from '@/components/ui/RegistrationStepCard/RegistrationStepCard';
import { FirstCardRequestStepState, OpeningStepStatus } from '@/graphql';
import { convertToStepCardStatus, getStepCardStatus } from './helper';

// NOTE: All steps except firstCardRequest are stored in individualStep, so they can be undefined.
type Args = {
  firstCardRequestStepState: FirstCardRequestStepState;
  individualIdentificationState?: OpeningStepStatus;
  appearanceAndDocumentState?: OpeningStepStatus;
};

const getIndividualConfirmationStatus = (
  steps: Args
): RegistrationStepCardProps['status'] => {
  return Object.values(steps).some(
    (status) => status !== OpeningStepStatus.Completed
  )
    ? 'disabled'
    : 'waiting';
};

export const getIndividualStepStatuses = ({
  firstCardRequestStepState,
  individualIdentificationState,
  appearanceAndDocumentState,
}: Args) => {
  const identificationStepStatus = convertToStepCardStatus(
    individualIdentificationState
  );
  const firstCard = getStepCardStatus(
    identificationStepStatus,
    firstCardRequestStepState
  );
  const appearanceAndDocument = getStepCardStatus(
    identificationStepStatus,
    appearanceAndDocumentState
  );
  const confirmation = getIndividualConfirmationStatus({
    individualIdentificationState,
    firstCardRequestStepState,
    appearanceAndDocumentState,
  });

  return {
    stepStatuses: {
      identification: identificationStepStatus,
      firstCard,
      appearanceAndDocument,
      confirmation,
    },
  };
};
