import { useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Text } from '@/components/new/Text';
import { InformationMessage } from '@/components/ui/InformationMessage';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useConnectSlackUserMutation } from '@/graphql';

const StyledButton = styled.button`
  color: #3b7de9;
  font-size: 13px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const Box = styled.div`
  margin-bottom: 16px;
`;

export type EnableSlackNotificationMessageProps = {
  officeMemberSlackLinked: boolean;
};

export const EnableSlackNotificationMessage = ({
  officeMemberSlackLinked,
}: EnableSlackNotificationMessageProps): JSX.Element => {
  const { handleMutationError } = useApiError();
  const { showSuccessNotification } = useGlobalNotification();
  const [slackLinked, setSlackLinked] = useState(officeMemberSlackLinked);

  const [connectSlackUser, { loading }] = useConnectSlackUserMutation({
    onError: handleMutationError,
    onCompleted: () => {
      showSuccessNotification('Slack通知が有効になりました');
      setSlackLinked(false);
    },
  });

  const onConnectButtonClick = async () => {
    await connectSlackUser();
  };

  if (slackLinked) return <></>;

  return (
    <Box>
      <InformationMessage showCloseButton={false}>
        {loading ? (
          <CircularProgress size="medium" />
        ) : (
          <>
            <Text>Slack通知が無効です。</Text>
            <StyledButton onClick={onConnectButtonClick}>
              有効にする
            </StyledButton>
          </>
        )}
      </InformationMessage>
    </Box>
  );
};
