import styled from 'styled-components';
import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';

const Box = styled.div`
  max-width: 900px;
`;
export const Layout: React.FC = ({ children }) => (
  <PageLayout title="通知設定">
    <Head title="通知設定" />
    <Box>{children}</Box>
  </PageLayout>
);
