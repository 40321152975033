import { Layout } from './Layout';
import { SettingPostPayContent } from './components/Content';

export const SettingsPostPayIndex = (): JSX.Element => {
  return (
    <Layout>
      <SettingPostPayContent />
    </Layout>
  );
};
