import { cardLimitsSchema } from '@/components/ui/inputs';
import { yup } from '@/lib/yup';
import {
  restrictForeignTransactionSchema,
  cardLockSchema,
} from './components/inputs';

export type FormData = yup.Asserts<typeof schema>;

export const schema = yup
  .object({
    cardLock: cardLockSchema,
    restrictForeignTransaction: restrictForeignTransactionSchema,
  })
  .concat(cardLimitsSchema);
