import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { SearchPanel } from '@/components/new/SearchPanel';
import { TextField } from '@/components/new/TextField';
import { Datepicker } from '@/components/ui/inputs';
import {
  DepositWithdrawal,
  WalletValueTransactionsQueryVariables,
} from '@/graphql';
import {
  DepositWithdrawalType,
  DepositWithdrawalTypeSelect,
} from './DepositWithdrawalTypeSelect';
import { useDefaultOpen } from './hooks/useDefaultOpen';
import { useFormDefaultValues } from './hooks/useFormDefaultValues';

const SearchPanelWrapper = styled.div`
  /*
   * 画面のHeader下部に隙間なく設置するために PageLayout>Content のPaddingを打ち消す。
   * 表示箇所は固定されているので、margin等はここで指定してしまう。
   */
  margin: -20px -20px 48px -20px;

  div > div:not([aria-hidden='true']) {
    overflow-y: visible;
    row-gap: 15px;
  }
`;

const HiddenLabel = styled.label`
  display: none;
`;

const DatePickersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const StyledSearchPanelColumn = styled.div`
  margin-right: 16px;
`;

export type FormValues = Pick<
  WalletValueTransactionsQueryVariables,
  'keyword' | 'transactionTimeLte' | 'transactionTimeGte'
> &
  DepositWithdrawalType;

export const ValueTransactionsSearchPanel = (): JSX.Element => {
  const defaultValues = useFormDefaultValues();
  const { control, reset, handleSubmit, register, watch } = useForm<FormValues>(
    {
      defaultValues,
    }
  );
  const router = useRouter();
  const defaultOpen = useDefaultOpen();

  const onSubmit = ({
    depositWithdrawalType,
    keyword,
    transactionTimeLte,
    transactionTimeGte,
  }: FormValues) =>
    router.push(
      {
        pathname: '/wallet/transactions',
        query: {
          ...((depositWithdrawalType === DepositWithdrawal.Deposit ||
            depositWithdrawalType === DepositWithdrawal.Withdrawal) && {
            depositWithdrawalType,
          }),
          ...(keyword && { keyword }),
          ...(transactionTimeGte && { transactionTimeGte }),
          ...(transactionTimeLte && { transactionTimeLte }),
        },
      },
      undefined,
      { shallow: true }
    );

  const handleReset = () => {
    reset({
      keyword: '',
      depositWithdrawalType: DepositWithdrawal.Both,
      transactionTimeGte: '',
      transactionTimeLte: '',
    });
    router.push(
      {
        pathname: '/wallet/transactions',
        query: {},
      },
      undefined,
      { shallow: true }
    );
  };

  const transactionTimeGte = watch('transactionTimeGte') ?? undefined;
  const transactionTimeLte = watch('transactionTimeLte') ?? undefined;

  return (
    <SearchPanelWrapper>
      <SearchPanel.Base defaultOpen={defaultOpen}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SearchPanel.Filters>
            <SearchPanel.Row>
              <StyledSearchPanelColumn>
                <label>
                  検索
                  <TextField {...register('keyword')} />
                </label>
              </StyledSearchPanelColumn>
              <StyledSearchPanelColumn>
                <DepositWithdrawalTypeSelect control={control} />
              </StyledSearchPanelColumn>
              <StyledSearchPanelColumn>
                期間
                <DatePickersWrapper>
                  <HiddenLabel htmlFor="transactionTimeGte">
                    transactionTimeGte
                  </HiddenLabel>
                  <Datepicker
                    id="transactionTimeGte"
                    placeholder="開始日"
                    hasValue={!!transactionTimeGte}
                    {...register('transactionTimeGte')}
                    max={transactionTimeLte}
                  />
                  〜
                  <HiddenLabel htmlFor="transactionTimeLte">
                    transactionTimeLte
                  </HiddenLabel>
                  <Datepicker
                    id="transactionTimeLte"
                    placeholder="終了日"
                    hasValue={!!transactionTimeLte}
                    {...register('transactionTimeLte')}
                    min={transactionTimeGte}
                  />
                </DatePickersWrapper>
              </StyledSearchPanelColumn>
            </SearchPanel.Row>
          </SearchPanel.Filters>

          <SearchPanel.Actions>
            <SearchPanel.ButtonGroup>
              <Button variant="outline" onClick={handleReset} type="button">
                クリア
              </Button>
              <Button>検索</Button>
            </SearchPanel.ButtonGroup>
          </SearchPanel.Actions>
        </form>
      </SearchPanel.Base>
    </SearchPanelWrapper>
  );
};
