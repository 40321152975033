import { useFormState } from 'react-hook-form';
import styled from 'styled-components';
import { Button as NewButton } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { CorporateSearchData } from './types';

const SearchButtonWrapper = styled.div({
  textAlign: 'center',
  '> button': {
    width: '160px',
  },
});

const Box = styled.div`
  margin: 24px 0 32px 0;
`;

type Props = {
  isLoading: boolean;
};
export const Button = ({ isLoading }: Props): JSX.Element => {
  const { isValid } = useFormState<CorporateSearchData>({
    name: ['corporateName', 'prefectureCode'],
  });

  return (
    <Box>
      <SearchButtonWrapper>
        <NewButton size="large" disabled={!isValid || isLoading}>
          {isLoading ? <CircularProgress size="small" /> : '検索'}
        </NewButton>
      </SearchButtonWrapper>
    </Box>
  );
};
