import { ApolloError } from '@apollo/client';
import { produce } from 'immer';
import { useApiError } from '@/hooks/useApiError';
import {
  CurrentRequestStatus,
  NotificationCategoryEnum,
  NotificationDetailFragment,
  NotificationsQueryVariables,
  RoleEnum,
  useNotificationsConditionQuery,
  useNotificationsQuery,
  useSlackLinkedQuery,
} from '@/graphql';

const getNotificationsQueryVariables = (
  walletIsOpened: boolean,
  isAdmin: boolean
): NotificationsQueryVariables => {
  // default
  const notificationCategories: NotificationCategoryEnum[] = [];

  if (!walletIsOpened) return { notificationCategories };

  notificationCategories.push(NotificationCategoryEnum.Card);
  if (isAdmin) notificationCategories.push(NotificationCategoryEnum.Wallet);

  return { notificationCategories };
};

export type UseNotificationsReturn = {
  loading: boolean;
  error: ApolloError | undefined;
  cardNotifications: NotificationDetailFragment[];
  walletNotifications: NotificationDetailFragment[];
  officeSlackLinked: boolean;
  officeMemberSlackLinked: boolean;
};
export const useNotifications = (): UseNotificationsReturn => {
  const { handleQueryError } = useApiError();
  const {
    data: notificationsConditionData,
    loading: notificationsConditionLoading,
    error: notificationsConditionError,
  } = useNotificationsConditionQuery({
    onError: handleQueryError,
  });
  const {
    data: notificationsData,
    loading: notificationsLoading,
    error: notificationsError,
  } = useNotificationsQuery({
    variables: getNotificationsQueryVariables(
      notificationsConditionData?.currentOffice.currentRequestStatus ===
        CurrentRequestStatus.ActivatedCard,
      notificationsConditionData?.currentOfficeMember.role === RoleEnum.Admin
    ),
    skip: notificationsConditionLoading,
    onError: handleQueryError,
  });
  const {
    data: slackLinkedData,
    loading: slackLinkedDataLoading,
    error: slackLinkedDataError,
  } = useSlackLinkedQuery({
    onError: handleQueryError,
  });

  const strippedNotifications = produce(notificationsData, (draft) => {
    draft?.currentOfficeMember.notifications.forEach((notification) => {
      delete notification.notificationSetting.__typename;
    });
  });

  const cardNotifications =
    strippedNotifications?.currentOfficeMember.notifications.filter(
      (notification) =>
        notification.notificationCategory === NotificationCategoryEnum.Card
    ) ?? [];

  const walletNotifications =
    strippedNotifications?.currentOfficeMember.notifications.filter(
      (notification) =>
        notification.notificationCategory === NotificationCategoryEnum.Wallet
    ) ?? [];

  const officeSlackLinked = !!slackLinkedData?.currentOffice.isSlackLinked;

  const officeMemberSlackLinked =
    !!slackLinkedData?.currentOfficeMember.isSlackLinked;

  return {
    loading:
      notificationsConditionLoading ||
      notificationsLoading ||
      slackLinkedDataLoading,
    error:
      notificationsConditionError || notificationsError || slackLinkedDataError,
    cardNotifications,
    walletNotifications,
    officeSlackLinked,
    officeMemberSlackLinked,
  };
};
