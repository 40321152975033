import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useForm, FormProvider, SubmitErrorHandler } from 'react-hook-form';
import { Loading } from '@/components/screen';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import {
  RegistrationAccountManagerDetailsData,
  registrationAccountManagerDetailsSchema,
  RegistrationAccountManagerDetails,
} from '@/components/ui/RegistrationAccountManager';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import { useApiError } from '@/hooks/useApiError';
import {
  useAccountManagerQuery,
  useUpdateAccountManagerMutation,
} from '@/graphql';
import { useGoToStepsOrConfirmation } from '@/hooks';

export const DetailsForm = (): JSX.Element => {
  const router = useRouter();
  const { handleMutationError, handleQueryError } = useApiError();
  const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
  const methods = useForm<RegistrationAccountManagerDetailsData>({
    resolver: yupResolver(registrationAccountManagerDetailsSchema),
    context: { confirmAge: true, isCorporate: true },
    defaultValues: { isCorporateRepresentative: 'true', nationalityId: 'JP' },
  });
  const { data, loading } = useAccountManagerQuery({
    fetchPolicy: 'cache-and-network',
    onError: handleQueryError,
    onCompleted: (data) => {
      const accountManager =
        data?.currentOffice.identificationVerificationRequest
          ?.corporateIdentification?.accountManager;

      // has saved values
      if (accountManager && accountManager.userIdentification)
        methods.reset({
          isCorporateRepresentative: accountManager.isRepresentative
            ? 'true'
            : 'false',
          position: accountManager.corporatePosition ?? undefined,
          firstName: accountManager.userIdentification.firstName,
          lastName: accountManager.userIdentification.lastName,
          firstNameKana: accountManager.userIdentification.firstNameKana,
          lastNameKana: accountManager.userIdentification.lastNameKana,
          firstNameEn:
            accountManager.userIdentification.firstNameEn ?? undefined,
          lastNameEn: accountManager.userIdentification.lastNameEn ?? undefined,
          middleNameEn:
            accountManager.userIdentification.middleNameEn ?? undefined,
          birthDate: accountManager.userIdentification.birthDate,
          postalCode: accountManager.userIdentification.postalCode,
          prefectureCode: accountManager.userIdentification.prefectureCode,
          city: accountManager.userIdentification.city,
          street: accountManager.userIdentification.street,
          buildingName: accountManager.userIdentification.buildingName,
          nationalityId:
            accountManager.userIdentification.nationality?.id ?? 'JP',
          residencePeriodEndDate:
            accountManager.userIdentification.residencePeriodEndDate ??
            undefined,
          residentialStatus:
            accountManager.userIdentification.residentialStatus ?? undefined,
        });

      methods.reset();
    },
  });
  const [saveInfo, { loading: mutationLoading }] =
    useUpdateAccountManagerMutation({
      onCompleted: () => goToStepsOrConfirmation(),
      onError: (error) => handleMutationError(error),
    });

  if (loading) return <Loading />;

  const accountManager =
    data?.currentOffice.identificationVerificationRequest
      ?.corporateIdentification?.accountManager;
  const isAccountManagerInputByOtherUser =
    accountManager && !accountManager.userIdentification;

  // 入力済ですが、取引担当者ではありません
  if (isAccountManagerInputByOtherUser) {
    router.push('/registration/corporate/account-manager/delete');
    return <Loading />;
  }

  const accountManagerItemId = accountManager?.itemId;
  const userIdentificationItemId = accountManager?.userIdentification?.itemId;

  const onSubmit = ({
    isCorporateRepresentative,
    position,
    ...userIdentification
  }: RegistrationAccountManagerDetailsData) => {
    if (mutationLoading) return;

    saveInfo({
      variables: {
        ...userIdentification,
        isRepresentative: isCorporateRepresentative === 'true',
        corporatePosition: position,
        ...(accountManagerItemId &&
          userIdentificationItemId && {
            accountManagerItemId,
            userIdentificationItemId,
          }),
      },
    });
  };

  const onError: SubmitErrorHandler<
    RegistrationAccountManagerDetailsData
  > = () => {
    window.scrollTo(0, 0);
  };

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <RegistrationAlert />
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <RegistrationAccountManagerDetails />
        <RegistrationButtons />
      </form>
    </FormProvider>
  );
};
