import { ApolloError, useApolloClient } from '@apollo/client';
import { produce } from 'immer';
import { useState } from 'react';
import { useApiError } from '@/hooks/useApiError';
import { useHandleUnknownError } from '@/hooks/useHandleUnknownError';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useUploadFile } from '@/hooks/useUploadFile';
import {
  CorporateRegistrationImagesDocument,
  CorporateRegistrationImagesQuery,
  CorporateRegistrationImagesQueryVariables,
  ImageModelNameEnum,
  useCreateCorporateRegistrationImagePutPresignedUrlsMutation,
  useUpdateImagesUploadStatusMutation,
} from '@/graphql';

export type UploadCorporateRegistrationFunction = (
  files: File[],
  start: number
) => Promise<void>;
export type UseUploadCorporateRegistrationReturn = [
  UploadCorporateRegistrationFunction,
  { loading: boolean },
];
export const useUploadCorporateRegistration =
  (): UseUploadCorporateRegistrationReturn => {
    const [loading, setLoading] = useState(false);
    const [createPresignedUrls] =
      useCreateCorporateRegistrationImagePutPresignedUrlsMutation();
    const [updateUploadStatus] = useUpdateImagesUploadStatusMutation();
    const uploadFile = useUploadFile();
    const { handleMutationError } = useApiError();
    const handleUnknownError = useHandleUnknownError();
    const resetMessageAndNotification = useResetMessageAndNotification();
    const client = useApolloClient();

    const uploadCorporateRegistration: UploadCorporateRegistrationFunction =
      async (files, start) => {
        if (loading) return;

        try {
          setLoading(true);
          resetMessageAndNotification();

          const { data: presignedUrlsData } = await createPresignedUrls({
            variables: {
              inputs: files.map((file, index) => ({
                fileName: file.name,
                position: start + index,
              })),
            },
          });

          const presignedUrls =
            presignedUrlsData?.createCorporateRegistrationImagePutPresignedUrls
              ?.corporateRegistrationImagePutPresignedUrls;

          if (!presignedUrls || files.length !== presignedUrls.length) {
            const error = new Error('invalid presigned url response');
            error.name = 'InvalidPresignedUrlResponse';

            throw error;
          }

          const filesHash = files.reduce<{ [key: string]: File }>(
            (result, file) => ({
              ...result,
              [file.name]: file,
            }),
            {}
          );

          const uploads = presignedUrls.map((presignedUrl) =>
            uploadFile(
              presignedUrl.presignedUrlResponse,
              filesHash[presignedUrl.fileName]
            )
          );

          await Promise.all(uploads);

          const { data: updateUploadStatusData } = await updateUploadStatus({
            variables: {
              imageModelName: ImageModelNameEnum.CorporateRegistration,
            },
          });

          if (!updateUploadStatusData?.updateImagesUploadStatus?.success) {
            const error = new Error('unsuccessful upload status update');
            error.name = 'UpdateUploadStatusError';

            throw error;
          }

          // update cache
          client.cache.updateQuery<
            CorporateRegistrationImagesQuery,
            CorporateRegistrationImagesQueryVariables
          >({ query: CorporateRegistrationImagesDocument }, (previousResult) =>
            produce(previousResult, (draft) => {
              const corporateIdentification =
                draft?.currentOffice.identificationVerificationRequest
                  ?.corporateIdentification;
              const previousImages =
                corporateIdentification?.corporateRegistration
                  ?.corporateRegistrationImages ?? [];

              if (!corporateIdentification) {
                const error = new Error(
                  'unsuccessful CorporateRegistrationImages update query error'
                );
                error.name = 'UpdateQueryError';

                throw error;
              }
              corporateIdentification.corporateRegistration = {
                corporateRegistrationImages: [
                  ...previousImages,
                  ...presignedUrls.map(({ uuid, fileName, position }) => ({
                    uuid,
                    fileName,
                    position,
                  })),
                ],
              };
            })
          );
        } catch (error) {
          error instanceof ApolloError
            ? handleMutationError(error)
            : handleUnknownError(error);
        } finally {
          setLoading(false);
        }
      };

    return [uploadCorporateRegistration, { loading }];
  };
