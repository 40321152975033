import { WalletExportsContent } from './Content';
import { Layout } from './Layout';

export const WalletExports = (): JSX.Element => {
  return (
    <Layout>
      <WalletExportsContent />
    </Layout>
  );
};
