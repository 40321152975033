import { produce } from 'immer';
import { yup } from '@/lib/yup';
import {
  CardDestinationAddressInput,
  CardTypeCategory,
  Prefecture,
} from '@/graphql';

export type CardDestinationData = yup.Asserts<typeof cardDestinationSchema>;

export const cardDestinationSchema = yup
  .object({
    buildingName: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.max(50).optional(),
      otherwise: (schema) => schema.strip(),
    }),
    city: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.required().max(20),
      otherwise: (schema) => schema.strip(),
    }),
    destinationDepartmentName: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.max(25).optional(),
      otherwise: (schema) => schema.strip(),
    }),
    destinationName: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.required().max(23),
      otherwise: (schema) => schema.strip(),
    }),
    postalCode: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.postalCode().required(),
      otherwise: (schema) => schema.strip(),
    }),
    prefectureCode: yup.mixed<Prefecture>().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) =>
        schema
          .oneOf(Object.values(Prefecture), '選択してください')
          .required('選択してください'),
      otherwise: (schema) => schema.strip(),
    }),
    street: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.required().max(25),
      otherwise: (schema) => schema.strip(),
    }),
    tradeName: yup.string().when(['$isCorporate', 'cardType'], {
      is: (isCorporate: boolean, cardType: CardTypeCategory) =>
        isCorporate && cardType === CardTypeCategory.Physical,
      then: (schema) => schema.max(25).optional(),
      otherwise: (schema) => schema.strip(),
    }),
  })
  .transform((value: CardDestinationAddressInput) =>
    produce(value, (draft) => {
      const optionalKeys: Extract<
        keyof CardDestinationAddressInput,
        'buildingName' | 'destinationDepartmentName' | 'tradeName'
      >[] = ['buildingName', 'destinationDepartmentName', 'tradeName'];

      optionalKeys.forEach((key) => {
        if ([null, undefined, ''].includes(draft[key])) {
          delete draft[key];
        }
      });
    })
  );
