import { useRouter } from 'next/router';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { Checkbox } from '@/components/new/Checkbox';
import { Link } from '@/components/new/Link';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { RegistrationStepLayout } from '@/components/ui/RegistrationStepLayout';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { useApiError } from '@/hooks/useApiError';
import {
  AccountingProductEnum,
  useApproveAccountingOwnerAgreementMutation,
} from '@/graphql';
import { NewProgressBar } from '../shared/NewProgressBar';
import { OwnerActiveError } from './OwnerActiveError';
import { StepIcon } from './StepIcon';
import { Storylane } from './Storylane';

export type Props = {
  accountingOwner?: {
    product: AccountingProductEnum;
    tenantUser: {
      displayName: string;
      email: string;
    };
  } | null;
  agreed: boolean;
  caSsoUrl: string;
  caPlusSsoUrl: string;
  isCorporate: boolean;
  hasAccounts: boolean;
  isOwnerActive: boolean;
};

export type StepCardStatus = 'waiting' | 'completed' | 'doing';

const Box = styled.div({
  marginTop: '8px',
});

const LinkBox = styled.div({
  marginTop: '16px',
});

const DescriptionBox = styled.div({
  marginTop: '8px',
  marginLeft: '42px',
});

const StepCardsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const NoteText = styled.span({
  textAlign: 'center',
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '13px',
  color: '#333333',
});

const Wrapper = styled.section({
  padding: '24px',
  backgroundColor: '#ffffff',
  borderRadius: '4px',
});

const TitleWrapper = styled.section({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const Title = styled.span({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'inherit',
});

const SectionBox = styled.div({
  display: 'flex',
  gap: '16px',
});

const Spliter = styled.div<{ isAccountingStepCompleted: boolean }>(
  ({ isAccountingStepCompleted }) => ({
    width: '2px',
    margin: '8px 13px',
    height: '120px',
    backgroundColor: isAccountingStepCompleted ? '#3B7DE9' : '#D4D8DD',
  })
);

const AggreDescription = styled.span({
  color: 'inherit',
});

const DescriptionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '32px',
  gap: '16px',
});

const ButtonBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '32px',
  gap: '8px',
});

const Comment = styled.div({
  textAlign: 'center',
  color: '#666666',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 400,
  letterSpacing: '0.4px',
});

const StyledButton = styled('div')({
  '> button': {
    width: '160px',
  },
  marginTop: '16px',
});

const getAggregationStatus = ({
  isAggregationStepCompleted,
  isAccountingStepCompleted,
}: {
  isAggregationStepCompleted: boolean;
  isAccountingStepCompleted: boolean;
}): StepCardStatus => {
  if (isAggregationStepCompleted) return 'completed';
  if (isAccountingStepCompleted) return 'doing';
  return 'waiting';
};

export const MergedContent = ({
  accountingOwner,
  agreed,
  caSsoUrl,
  caPlusSsoUrl,
  isCorporate,
  hasAccounts,
  isOwnerActive,
}: Props): JSX.Element => {
  const router = useRouter();
  const [confirmed, setConfirmed] = useState(false);

  const isAccountingStepCompleted = !!accountingOwner;
  const isAggregationStepCompleted = hasAccounts;

  const aggregationStatus: StepCardStatus = getAggregationStatus({
    isAggregationStepCompleted,
    isAccountingStepCompleted,
  });

  const { handleMutationError } = useApiError();
  const [approveAgreement, { loading }] =
    useApproveAccountingOwnerAgreementMutation({
      variables: { approve: true },
      onError: handleMutationError,
      onCompleted() {
        router.push('/postpay/registration/apply');
      },
    });

  const approve = () => {
    if (agreed) router.push('/postpay/registration/apply');
    else approveAgreement();
  };

  return (
    <RegistrationStepLayout>
      <Head title="与信審査" />
      <RegistrationTitle title="与信審査" center />
      <Box>
        <NewProgressBar step={1} />
      </Box>
      <NoteText>
        与信審査後、口座振替手続きが必要です。与信限度額が付与されるまで最短で1ヶ月程かかります。
        <br />
        <Link
          href="https://biz.moneyforward.com/support/biz-pay/guide/postpay/g073.html"
          target="_blank"
        >
          このページのガイドはこちら
        </Link>
      </NoteText>
      <GlobalMessage />
      {accountingOwner && !isOwnerActive && (
        <OwnerActiveError
          isCorporate={isCorporate}
          displayName={accountingOwner?.tenantUser.displayName}
          email={accountingOwner?.tenantUser.email}
        />
      )}
      <StepCardsWrapper>
        <Wrapper data-testid="wrapper">
          {/* 利用開始 */}
          <TitleWrapper>
            <Box>
              <StepCardIcon
                status={isAccountingStepCompleted ? 'completed' : 'doing'}
                link={caSsoUrl}
              />
            </Box>

            <Title>
              マネーフォワード クラウド
              {isCorporate ? '会計・会計Plus' : '確定申告'}
              の利用開始
            </Title>
          </TitleWrapper>

          <SectionBox>
            <Spliter isAccountingStepCompleted={isAccountingStepCompleted} />

            <Box>
              マネーフォワード クラウド
              {isCorporate ? '会計・会計Plus' : '確定申告'}
              の利用開始、および口座のデータ連携が必要です。
              <br />
              ※マネーフォワード クラウド
              {isCorporate ? '会計・会計Plus' : '確定申告'}
              の利用状況の取得には<strong>最大24時間</strong>かかります。
              {!isAccountingStepCompleted && (
                <LinkBox>
                  <Link href={caSsoUrl} target="_blank">
                    利用を開始する（無料）
                  </Link>
                </LinkBox>
              )}
            </Box>
          </SectionBox>

          {/* データ連携 */}
          <TitleWrapper>
            <Box>
              <StepCardIcon
                status={aggregationStatus}
                link={
                  accountingOwner?.product ===
                  AccountingProductEnum.AccountingPlus
                    ? caPlusSsoUrl
                    : caSsoUrl
                }
                isDataConnectionStep={true}
                isOwnerActive={isOwnerActive}
              />
            </Box>

            <Title>
              マネーフォワード クラウド
              {isCorporate ? '会計・会計Plus' : '確定申告'}
              で口座をデータ連携
            </Title>
          </TitleWrapper>

          <DescriptionBox>
            <AggreDescription>
              引落口座は必ず連携してください。口座が複数ある場合はすべて連携してください。
              <br />
              連携する際「自動取得対象の開始日」は
              <strong>「取得可能なデータをすべて取り込む」</strong>
              を選択してください。
              <br />
              一定期間の残高や入出金履歴が取得できないと与信審査を行えません。
            </AggreDescription>
            <LinkBox>
              <Link
                href={
                  accountingOwner?.product ===
                  AccountingProductEnum.AccountingPlus
                    ? caPlusSsoUrl
                    : caSsoUrl
                }
                target="_blank"
              >
                口座をデータ連携する
              </Link>
            </LinkBox>
          </DescriptionBox>
        </Wrapper>
      </StepCardsWrapper>
      <DescriptionWrapper>
        <Box>
          <Link
            href="https://biz.moneyforward.com/biz-pay/agreement/"
            target="_blank"
          >
            利用規約
          </Link>
          をご確認の上[同意する]ボタンを押下してください。
        </Box>

        <Box>
          <strong>再度ご確認ください</strong>
        </Box>

        <Box>
          ・当社が、与信審査及び与信後の管理に関連する目的に必要な範囲内において、「マネーフォワード
          クラウド」および「マネーフォワードME」に連携されたお客さまの口座情報、利用状況等その他の情報を参照すること
        </Box>

        <Box>
          ・当社が、
          <Link
            href="https://corp.moneyforward.com/aboutus/outline/"
            target="_blank"
          >
            当社のグループ会社
          </Link>
          から、利用限度枠の決定及び変更その他与信審査及び与信後の管理に関連する目的に必要な範囲内において、当社のグループ会社がサービス提供に関連して取得した与信枠機能利用希望者に関する情報（個人情報を除く）の提供を受け、当該情報を参照すること
        </Box>
      </DescriptionWrapper>
      <ButtonBox>
        <Checkbox checked={confirmed} onChange={() => setConfirmed(!confirmed)}>
          確認しました
        </Checkbox>
        {confirmed && !isAggregationStepCompleted && (
          <Comment>
            クラウド
            {isCorporate ? '会計・会計Plus' : '確定申告'}
            の利用開始と口座のデータ連携が完了していません。
            <br />
            データの取得には通常3時間ほどかかります。
          </Comment>
        )}
        <StyledButton>
          <Button
            size="large"
            onClick={approve}
            disabled={
              !confirmed ||
              !isAccountingStepCompleted ||
              !isAggregationStepCompleted ||
              !isOwnerActive ||
              loading
            }
          >
            同意して進む
          </Button>
        </StyledButton>
      </ButtonBox>
      <Storylane />
    </RegistrationStepLayout>
  );
};

const StepCardIcon = ({
  status,
  link,
  isDataConnectionStep,
  isOwnerActive,
}: {
  status: StepCardStatus;
  link: string;
  isDataConnectionStep?: boolean;
  isOwnerActive?: boolean;
}): JSX.Element => {
  if (isDataConnectionStep && !isOwnerActive) {
    return <StepIcon icon="edit" link={link} />;
  }
  if (status === 'completed') {
    return <StepIcon icon="checkmark" link={link} disabled />;
  }
  return <StepIcon icon="edit" link={link} />;
};
