import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import { IntegrationsContent } from './components/IntegrationsContent/IntegrationsContent';

export const SettingsIntegrationsIndex = (): JSX.Element => {
  return (
    <PageLayout title="連携">
      <Head title="連携" />
      <IntegrationsContent />
    </PageLayout>
  );
};
