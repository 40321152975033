import {
  Maybe,
  PostPayRequest,
  PostPaySettingDetailsFragment,
} from '@/graphql';
import { PostPayApplicationStatusWidget } from './PostPayApplicationStatusWidget';

type Props = {
  setting: Maybe<PostPaySettingDetailsFragment> | undefined;
  postpayRequest: Maybe<PostPayRequest> | undefined;
  isShowGuide: boolean;
};

export const PostPayWidget = ({
  setting,
  postpayRequest,
  isShowGuide,
}: Props): JSX.Element => {
  // 未申請
  if (!postpayRequest) return <></>;

  // あと払い利用開始済み
  if (setting || !postpayRequest.status) return <></>;

  // 申請後1時間経過した場合
  if (isShowGuide) return <></>;

  return (
    <PostPayApplicationStatusWidget
      status={postpayRequest.status}
      startDate={postpayRequest.startDate}
      creditLine={postpayRequest.temporaryCreditLine}
    />
  );
};
