import { Layout } from './Layout';
import { SettingsPostPayLimitEditContent } from './components/Content';

export const SettingsPostPayLimitEdit = (): JSX.Element => {
  return (
    <Layout>
      <SettingsPostPayLimitEditContent />
    </Layout>
  );
};
