import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Radio } from '@/components/new/Radio';
import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { CardTerminatedReasonEnum } from '@/graphql';
import { useCard } from '../../hooks';
import { FormValues } from '../schema';

const Title = styled(Text)`
  margin-right: 6px;
  font-weight: bold;
`;

const TextLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Application = (): JSX.Element => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();
  const { data, loading } = useCard();

  const isOtherSelected =
    watch('terminatedReason') === CardTerminatedReasonEnum.Other;

  if (loading) return <></>;
  const card = data?.currentOffice?.wallet?.card;

  return (
    <Table.Root>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeaderCell colSpan={2}>解約申込</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeaderCell width="168px" bold>
            <Title>カード情報</Title>
          </Table.RowHeaderCell>
          <Table.Cell>
            {card ? (
              <TextLayout>
                <Text>{card.name}</Text>
                <Text>**** **** **** {card.lastFour}</Text>
                <Text>
                  有効期限 : {card.expirationMonth.toString().padStart(2, '0')}/
                  {card.expirationYear}
                </Text>
              </TextLayout>
            ) : (
              <Text>---</Text>
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeaderCell width="168px" bold>
            <Title>解約理由</Title>
            <StatusLabel color="red">必須</StatusLabel>
          </Table.RowHeaderCell>
          <Table.Cell>
            <Wrapper>
              <RadioLayout>
                <Radio
                  {...register('terminatedReason')}
                  value={CardTerminatedReasonEnum.CardHolderRetirement}
                >
                  持ち主の退職
                </Radio>
                <Radio
                  {...register('terminatedReason')}
                  value={CardTerminatedReasonEnum.LossOrTheft}
                >
                  紛失・盗難
                </Radio>
                <Radio
                  {...register('terminatedReason')}
                  value={CardTerminatedReasonEnum.Other}
                >
                  その他
                </Radio>
              </RadioLayout>
              {errors.terminatedReason && (
                <FormError>{errors.terminatedReason.message}</FormError>
              )}
              {isOtherSelected && (
                <Wrapper>
                  <TextField
                    aria-label="otherReason"
                    {...register('otherTerminatedReason')}
                  />
                  {errors.otherTerminatedReason && (
                    <FormError>
                      {errors.otherTerminatedReason.message}
                    </FormError>
                  )}
                </Wrapper>
              )}
            </Wrapper>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
