import { PointBalance as PointBalanceUi } from '@/components/ui/PointBalance';
import { useApiError } from '@/hooks/useApiError';
import { useWalletPointBalanceQuery } from '@/graphql';

export const PointBalance = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const { data, loading } = useWalletPointBalanceQuery({
    onError: handleQueryError,
  });

  const balance = Number(data?.currentOffice.wallet?.point?.balance);

  return (
    <PointBalanceUi
      loading={loading}
      pointBalance={isNaN(balance) ? undefined : balance}
    />
  );
};
