import externalLink from '@images/icons/externalLink.svg';
import queryError from '@images/queryError.svg';
import styled from 'styled-components';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { Image } from '@/components/ui/Image';
import { useNotifications } from '../../hooks/useNotifications';
import { EnableSlackNotificationMessage } from '../EnableSlackNotificationMessage/EnableSlackNotificationMessage';
import { Form } from '../Form';

const NoContentLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const TablesLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LinkText = styled.span`
  display: inline-flex;
  align-items: center;
  line-height: 1;
  gap: 4px;
`;

const Box = styled.div`
  margin-bottom: 24px;
`;

export const NotificationsContent = (): JSX.Element => {
  const {
    loading: notificationLoading,
    error: notificationError,
    cardNotifications,
    walletNotifications,
    officeSlackLinked,
    officeMemberSlackLinked,
  } = useNotifications();

  const flagShipUrl = process.env.NEXT_PUBLIC_FLAGSHIP_URL ?? '';

  if (notificationLoading)
    return (
      <NoContentLayout>
        <CircularProgress size="xlarge" />
      </NoContentLayout>
    );

  if (notificationError)
    return (
      <NoContentLayout>
        <Image src={queryError} alt="query error" width={250} height={200} />
        <Text color="notes">読み込みエラー</Text>
      </NoContentLayout>
    );

  return (
    <>
      <Box>
        <Text>
          あなた宛に届く通知の設定をすることができます。
          <br />
          プッシュ通知を受け取るには、アプリをダウンロードした上で、OS側の設定でプッシュ通知を有効にしてください。
          <br />※
          ご利用やセキュリティに関する重要なお知らせは、オフにすることはできません。
        </Text>
      </Box>
      <Box>
        <Link href={flagShipUrl} target="_blank">
          <LinkText>
            新機能等のお知らせのメール配信設定
            <Image src={externalLink} width={24} height={24} />
          </LinkText>
        </Link>
      </Box>
      <GlobalMessage />
      {officeSlackLinked && (
        <EnableSlackNotificationMessage
          officeMemberSlackLinked={officeMemberSlackLinked}
        />
      )}

      <TablesLayout>
        {walletNotifications.length > 0 && (
          <Form
            items={walletNotifications}
            theadText="ウォレット・取引に関する通知"
            showSlackColumn={officeMemberSlackLinked}
          />
        )}
        {cardNotifications.length > 0 && (
          <Form
            items={cardNotifications}
            theadText="自分が利用者に指定されているカードに関する通知"
            showSlackColumn={officeMemberSlackLinked}
          />
        )}
      </TablesLayout>
    </>
  );
};
