import { FetchResult } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { setLoginCookie } from '@/lib/cookie';
import { useApiError } from '@/hooks/useApiError';
import {
  ActivateOfficeMemberMutation,
  useActivateOfficeMemberMutation,
} from '@/graphql';

type Props = {
  isAgree: boolean;
};

export const useRegistrationTerms = ({
  isAgree,
}: Props): {
  handleAgreeClick: () => Promise<FetchResult<ActivateOfficeMemberMutation>>;
} => {
  const router = useRouter();
  const { handleMutationError } = useApiError();
  const [activateOfficeMember] = useActivateOfficeMemberMutation({
    variables: { isAgree },
    onCompleted: (data) => {
      const officeId = data.activateOfficeMember?.officeMember.office.id;
      if (officeId) {
        setLoginCookie(officeId);
        router.push(`/login/redirect`).then(() => {
          router.reload();
        });
      }
    },
    onError: (error) => handleMutationError(error),
  });

  const handleAgreeClick = useCallback(() => activateOfficeMember(), [isAgree]);

  return { handleAgreeClick };
};
