import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Radio } from '@/components/new/Radio';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import { InputGroup, PrefectureInput } from '@/components/ui/inputs';
import { CorporateSearchData } from './types';

const RadioWrapper = styled.div`
  margin-bottom: 12px;
`;
const Box = styled.span`
  margin: 0 16px 0 4px;
`;
export const Conditions = (): JSX.Element => {
  const { register } = useFormContext<CorporateSearchData>();

  return (
    <RegistrationTable header="法人を検索">
      <RegistrationTableRow title="法人名">
        <RadioWrapper>
          <Radio
            {...register('searchMethod')}
            value="partial"
            defaultChecked={true}
          >
            <Box>
              <Text>部分一致検索</Text>
            </Box>
          </Radio>
          <Radio {...register('searchMethod')} value="prefix">
            <Box>
              <Text>前方一致検索</Text>
            </Box>
          </Radio>
        </RadioWrapper>
        <div>
          <TextField
            {...register('corporateName')}
            aria-label="corporateName"
          />
        </div>
      </RegistrationTableRow>
      <RegistrationTableRow title="所在地で絞り込み">
        <InputGroup>
          <PrefectureInput />
          <TextField
            {...register('cityName')}
            placeholder="市区町村（任意）"
            aria-label="cityName"
          />
        </InputGroup>
      </RegistrationTableRow>
    </RegistrationTable>
  );
};
