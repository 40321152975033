import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import {
  useUpdateCorporateIdentificationMutation,
  RegistrationCorporateApplicantDocument,
  IndustryEnum,
  RegistrationCorporateApplicantQuery,
} from '@/graphql';
import { Inputs } from './Inputs';
import { useRegistrationCorporateApplicantForm } from './hooks';
import { FormData, schema } from './types';
import { useRegistrationPageState } from '@/contexts/RegistrationProvider';
import { useGoToStepsOrConfirmation } from '@/hooks';

type Props = {
  data?: RegistrationCorporateApplicantQuery;
};

export const Form = ({ data }: Props): JSX.Element => {
  const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
  const { handleMutationError } = useApiError();
  const [save, { loading }] = useUpdateCorporateIdentificationMutation({
    refetchQueries: [{ query: RegistrationCorporateApplicantDocument }],
    onCompleted: () => goToStepsOrConfirmation(),
    onError: (error) => handleMutationError(error),
  });

  const { registrationPageState, setFieldsState } = useRegistrationPageState();
  const selectedCorporation = registrationPageState.selectedCorporation;

  const { defaultValues } = useRegistrationCorporateApplicantForm(
    data,
    selectedCorporation
  );

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    context: { confirmAge: true },
    defaultValues,
  });

  const onSubmit = ({
    isListed,
    industry,
    industryText,
    usagePurposes,
    ...corporateIdentification
  }: FormData) => {
    if (loading) return;

    save({
      variables: {
        ...corporateIdentification,
        isListed: isListed === 'true',
        industry,
        // 事業内容でその他を選択されている場合のみindustryTextをセットする
        industryText:
          industry === IndustryEnum.IndustriesUnableToClassify
            ? industryText
            : null,
        usagePurposes: usagePurposes.map((purpose) => ({
          purpose,
        })),
      },
    });
  };

  const onError = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    return () => {
      // unmount(ページ遷移)時に選択された法人情報をクリア
      setFieldsState({
        selectedCorporation: undefined,
      });
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <RegistrationAlert />

      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        <Inputs />
        <RegistrationButtons />
      </form>
    </FormProvider>
  );
};
