import { yup } from '@/lib/yup';
import { CardTypeCategory, XardCardRequestInput } from '@/graphql';

export const cardTypeSchema: yup.ObjectSchema<
  Pick<XardCardRequestInput, 'cardType'>
> = yup.object({
  cardType: yup
    .mixed<CardTypeCategory>()
    .required()
    .when('$hasInsufficientBalance', {
      is: true,
      then: (schema) =>
        schema.oneOf([CardTypeCategory.Virtual], '残高が足りません'),
      otherwise: (schema) => schema.oneOf(Object.values(CardTypeCategory)),
    }),
});
