import { Props as RegistrationStepCardProps } from '@/components/ui/RegistrationStepCard/RegistrationStepCard';
import {
  CorporateRejectedReason,
  CorporateStep,
  FirstCardRequestStepState,
  OpeningStepStatus,
  RegistrationCorporateStepQuery,
} from '@/graphql';

type RegistrationState = {
  corporateStep?: {
    identification?: OpeningStepStatus;
    firstCard?: FirstCardRequestStepState;
    beneficialOwner?: OpeningStepStatus;
    accountManager?: OpeningStepStatus;
    appearanceAndDocument?: OpeningStepStatus;
    corporateRegistration?: OpeningStepStatus;
  };
  corporateRejectedReason?: {
    identification: CorporateRejectedReason['corporateIdentificationRejectedReasons'];
    beneficialOwner: CorporateRejectedReason['beneficialOwnerRejectedReasons'];
    accountManager: CorporateRejectedReason['accountManagerRejectedReasons'];
    appearanceAndDocument: CorporateRejectedReason['appearanceAndDocumentRejectedReasons'];
    corporateRegistration: CorporateRejectedReason['corporateRegistrationRejectedReasons'];
    other: CorporateRejectedReason['otherRejectedReasons'];
    reapplicationRequestMessage: CorporateStep['reapplicationRequestMessage'];
  };
};

const toCardStatus = (
  stepStatus?: OpeningStepStatus | FirstCardRequestStepState
): RegistrationStepCardProps['status'] => {
  if (!stepStatus) return 'waiting';

  switch (stepStatus) {
    case OpeningStepStatus.Completed:
      return 'completed';
    case OpeningStepStatus.Incompleted:
      return 'incomplete';
    case OpeningStepStatus.Unnecessary:
      return 'unnecessary';
    default:
      return 'waiting';
  }
};

const getCardStatus = (
  identificationStatus: RegistrationStepCardProps['status'],
  stepStatus?: OpeningStepStatus | FirstCardRequestStepState
): RegistrationStepCardProps['status'] => {
  if (
    identificationStatus === 'completed' ||
    identificationStatus === 'incomplete'
  ) {
    // 法人情報の入力が完了していないと、他のstepの入力はさせない
    return toCardStatus(stepStatus);
  }

  return 'disabled';
};

const getCorporateConfirmationStatus = (
  steps: RegistrationState['corporateStep']
): RegistrationStepCardProps['status'] => {
  if (!steps) return 'disabled';

  const completed = Object.entries(steps).every(([stepName, status]) => {
    if (stepName === 'firstCard') {
      return status === FirstCardRequestStepState.Completed;
    }

    if (stepName === 'beneficialOwner') {
      return (
        status === OpeningStepStatus.Completed ||
        status === OpeningStepStatus.Unnecessary
      );
    }

    return status === OpeningStepStatus.Completed;
  });

  return completed ? 'waiting' : 'disabled';
};

export const useCorporateSteps = (
  data: RegistrationCorporateStepQuery | undefined
): {
  stepStatuses: typeof stepStatuses;
} => {
  let step: RegistrationState['corporateStep'];
  if (data?.currentOffice.identificationVerificationRequest?.corporateStep) {
    const {
      firstCardRequestStepState: firstCard,
      identificationVerificationRequest: {
        corporateStep: {
          corporateIdentificationState: identification,
          beneficialOwnerState: beneficialOwner,
          accountManagerState: accountManager,
          appearanceAndDocumentState: appearanceAndDocument,
          corporateRegistrationState: corporateRegistration,
        },
      },
    } = data.currentOffice;

    step = {
      identification,
      firstCard,
      beneficialOwner,
      accountManager,
      appearanceAndDocument,
      corporateRegistration,
    };
  }

  const identificationStepStatus = toCardStatus(step?.identification);
  const accountManagerStepStatus = getCardStatus(
    identificationStepStatus,
    step?.accountManager
  );
  const stepStatuses = {
    identification: identificationStepStatus,
    firstCard: getCardStatus(identificationStepStatus, step?.firstCard),
    beneficialOwner: getCardStatus(
      identificationStepStatus,
      step?.beneficialOwner
    ),
    accountManager: accountManagerStepStatus,
    appearanceAndDocument:
      accountManagerStepStatus === 'completed' ||
      accountManagerStepStatus === 'incomplete'
        ? getCardStatus(identificationStepStatus, step?.appearanceAndDocument)
        : 'disabled',
    corporateRegistration: getCardStatus(
      identificationStepStatus,
      step?.corporateRegistration
    ),
    confirmation: getCorporateConfirmationStatus(step),
  };

  return { stepStatuses };
};

export const useCorporateRejectReasons = (
  data: RegistrationCorporateStepQuery | undefined
): {
  rejectedReasons: string[];
  rejectedComment?: string | null;
} => {
  let rejectedReasonCollection: RegistrationState['corporateRejectedReason'];
  if (data?.currentOffice.identificationVerificationRequest?.corporateStep) {
    const {
      identificationVerificationRequest: {
        corporateStep: { reapplicationRequestMessage, rejectedReason },
      },
    } = data.currentOffice;

    rejectedReasonCollection = {
      identification: rejectedReason?.corporateIdentificationRejectedReasons,
      beneficialOwner: rejectedReason?.beneficialOwnerRejectedReasons,
      accountManager: rejectedReason?.accountManagerRejectedReasons,
      appearanceAndDocument:
        rejectedReason?.appearanceAndDocumentRejectedReasons,
      corporateRegistration:
        rejectedReason?.corporateRegistrationRejectedReasons,
      reapplicationRequestMessage,
      other: rejectedReason?.otherRejectedReasons,
    };
  }

  if (!rejectedReasonCollection) return { rejectedReasons: [] };

  const { reapplicationRequestMessage, ...collection } =
    rejectedReasonCollection;
  return {
    rejectedReasons: Object.keys(collection).reduce<string[]>(
      (rejectedReasons, stepName) => {
        // REVIEW: Remove `rejectedReasonCollection!` after fixing the type
        const reasons =
          rejectedReasonCollection![
            stepName as keyof NonNullable<
              RegistrationState['corporateRejectedReason']
            >
          ];
        return reasons ? rejectedReasons.concat(reasons) : rejectedReasons;
      },
      [] as string[]
    ),
    rejectedComment: reapplicationRequestMessage,
  };
};
